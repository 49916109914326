import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CurrentUserContext, LoadingContext } from '../App';
import isAdmin from '../hooks/isAdmin';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { failed } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
import { UserDataContext, UserListContext } from '../routes/Main';
import Pills from './Pills';
import isMobile from '../hooks/isMobile';
import { catchedTrigger } from '../utils/novu';
import { IUserData, TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';

const EditEnvironment = (props: {
    tenant: string,
    fullId: string,
    idToChange: number | string,
    environment: string
}) => {
    const admin = isAdmin();

    const superAdmin = isSuperAdmin();

    const developer = isDeveloper();

    const { userData } = useContext(UserDataContext);
    const { setLoading } = useContext(LoadingContext);
    const { fetchTickets } = useContext(DataContext);
    const { fetchTicketsHomePage } = useContext(TicketListContext);
    const { currentUser } = useContext(CurrentUserContext);

    const [open, setOpen] = useState(false);
    const [commentOpen, setCommentOpen] = useState(false);
    const [environment, setEnvironment] = useState('');

    const handleEnvironmentOpen = () => {
        setOpen(true);
    };

    const handleEnvironmentClose = () => {
        setOpen(false);
    };

    const handleEnvironmentComment = (pill: {
        name: string
    }) => {
        setCommentOpen(true);
        setEnvironment(pill.name);
    };

    const handleCommentClose = () => {
        setCommentOpen(false);
    };

    const environmentPills = [
        {
            name: 'SANDBOX',
            hexCode: '#FFFFFF',
        },
        {
            name: 'UAT',
            hexCode: '#FFFFFF',
        },
        {
            name: 'PRODUCTION',
            hexCode: '#FFFFFF',
        },
    ];

    const [environmentToChange, setEnvironmentToChange] = useState<number | string>();

    const handleEnvironmentToChange = (idToChange: number | string) => {
        setEnvironmentToChange(idToChange);
    };

    const [relatedUsers, setRelatedUsers] = useState<any>();

    const { userList } = useContext(UserListContext)

    useEffect(() => {
        const users = userList?.data?.users?.users?.filter((user: IUserData) => user.main_tenant === props.tenant || user.roles[0].name === 'sa' || user.roles[0].name === 'ad')
        const notifUsers = users.map((user: IUserData) => {
            return {
                subscriberId: JSON.stringify(user.id),
                email: user.email
            }
        })
        setRelatedUsers(notifUsers);
    }, [userList, props.tenant])

    const [comment, setComment] = useState('');

    const handleEnvironmentChange = async () => {
        setLoading(true);
        const ticketInfo = {
            environment: environment.toLowerCase(),
        };
        const messageInfo = {
            message: comment,
            sender: userData !== undefined && userData.data.name,
            ticket: environmentToChange,
            admin: (admin || superAdmin || developer) && true,
            changed_to: environment
        };

        await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${environmentToChange}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: ticketInfo }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    await catchedTrigger('status-change', {
                        // to: {
                        //   subscriberId: '63d7751916e379fe65f29506'
                        // },
                        to: relatedUsers,
                        payload: {
                            ticket: props.fullId
                        }
                    })
                }
                recordLog(`${environment}env`, comment, 'ticket', props.fullId, currentUser?.id);
            })
            .catch(() => failed());

        await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: messageInfo }),
        })
            .then(() => {
                fetchTicketsHomePage();
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                // failed();
            });

        // const addTicketResponse = await changeEnvironment.json()
        //
        // const addChatResponse = await addComment.json()

        handleCommentClose();
        setComment('');
        fetchTickets();
        // console.log(addTicketResponse);

        // console.log(addChatResponse);
    };

    const mobile = isMobile();

    return (
        <>
            {props.environment !== '-' &&
                <Button
                    onClick={() => {
                        handleEnvironmentOpen();
                        handleEnvironmentToChange(props.idToChange);
                    }}
                    sx={{ p: '0px', b: '0px' }}
                >
                    <Pills pill={`${props.environment}env`} />
                </Button>
            }

            <Dialog open={open} onClose={handleEnvironmentClose} maxWidth='xl'>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='15px'>
                        <Box display='flex' justifyContent='space-between'>
                            <Typography fontWeight='bold'>Change environment to:</Typography>
                            <CloseIcon onClick={handleEnvironmentClose} cursor='pointer' />
                        </Box>
                        <Stack direction='row' justifyContent='space-between' gap='10px'>
                            {environmentPills.map((pill) => {
                                return (
                                    <Button
                                        key={pill.name}
                                        onClick={() => {
                                            handleEnvironmentComment(pill);
                                            handleEnvironmentClose();
                                        }}
                                        sx={{ p: '0px', b: '0px' }}
                                    >
                                        <Pills pill={`${pill.name}env`} />
                                    </Button>
                                );
                            })}
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={commentOpen} onClose={handleCommentClose} fullWidth>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='15px'>
                        <Box display='flex' justifyContent='space-between'>
                            <Box display='flex' gap='10px'>
                                <Typography fontWeight='bold'>Comment:</Typography>
                                <Pills pill={`${environment}env`} />
                            </Box>
                            <CloseIcon onClick={handleCommentClose} cursor='pointer' />
                        </Box>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' sx={{ mt: '-68px' }}>
                                        <SendIcon onClick={handleEnvironmentChange} sx={{ cursor: 'pointer' }} />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (!mobile) {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        handleEnvironmentChange();
                                        e.preventDefault();
                                    }
                                }
                            }}
                            value={comment}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EditEnvironment;
