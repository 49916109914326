/* eslint-disable @typescript-eslint/no-unused-vars */

import { Dialog } from '@mui/material';
import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CallbackHandler from '../components/CallbackHandler';
import LoginGuard from '../components/Guard/LoginGuard';
import useLoginExpiryEffect from '../hooks/useLoginExpiry';
import CreateTicketPage from '../pages/CreateTicketPage';
import ErrorPage from '../pages/ErrorPage';
import HomePage from '../pages/HomePage';
import NotificationsPage from '../pages/NotificationsPage';
import TicketsPage from '../pages/TicketsPage';
import AttendancePage from '../pages/AttendancePage';
import { CurrentUserContext } from '../App';
import MeetingPage from '../pages/MeetingPage';
import ChatRoom from '../chat-modules/ChatRoom';
import { useDispatch } from 'react-redux';
import { SocketContext } from '../utils/SocketProvider';
import { connectWebSocket } from '../store/websocket/websocketActions';
import { AppDispatch } from '../store/store';
export const UserDataContext = createContext<any>({});

export const UserListContext = createContext<any>({});

export const AllTenantsContext = createContext<any>({});

export const PageContext = createContext<any>({});

export const PageSizeContext = createContext<any>({});

const Main = () => {
  const { setCurrentUser } = useContext(CurrentUserContext);
  
  const dispatch : AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  const [login, setLogin] = useState<boolean>(true);
  const [userData, setUserData] = useState<any>();
  const [userList, setUserList] = useState<any>();
  const [allTenants, setAllTenants] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);

  const access_token = localStorage.getItem('access_token');

  const [user, setUser] = useState();

  useLoginExpiryEffect();
  useEffect(() => {
    if (access_token == null || access_token == undefined) {
      setLogin(false);
      return;
    }

    axios
      .get('https://sos.sis.scieverinc.com/api/user', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((user) => {
        setLogin(true);
        setLoading(false);
        setUserData(user);
      })
      .catch(() => {
        setLogin(false);
        window.location.href = `${process.env.REACT_APP_sisUrl}&error=Session expired. Please login again.`;
      });
  }, [access_token]);

  useEffect(() => {
    if (access_token == null || access_token == undefined) {
      return;
    }

    axios
      .get('https://sos.sis.scieverinc.com/api/admin/tenants/all', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((user) => {
        setAllTenants(user);
      });
  }, [access_token]);

  useEffect(() => {
    axios
      .get('https://sos.sis.scieverinc.com/api/admin/users', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((users) => {
        setUserList(users);
      });
  }, [access_token]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_strapiUrl}/attendance-users?pagination[pageSize]=99`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_token}`,
        },
      })
      .then((res) => {
        const filter = res?.data?.data?.filter(
          (user: { attributes: { sis_id: string } }) =>
            user?.attributes?.sis_id == userData?.data?.id,
        );
        setUser(filter[0]);
        return null;
      });
  }, [userData]);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  useEffect(() => {
    if (socket) {
      dispatch(connectWebSocket(socket));
    }
  }, [dispatch, socket]);

  const attendanceUserOnly =
    userData?.data?.roles[0]?.name !== 'sa' &&
    userData?.data?.organizations?.length === 1 &&
    userData?.data?.organizations[0]?.alias === 'sciever'
      ? true
      : false;

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      <UserListContext.Provider value={{ userList, setUserList }}>
        <AllTenantsContext.Provider value={{ allTenants, setAllTenants }}>
          <PageContext.Provider value={{ page, setPage }}>
            <PageSizeContext.Provider value={{ pageSize, setPageSize }}>
              <Routes>
                {attendanceUserOnly ? (
                  <>
                    <Route path='/' element={<Navigate to='/chatroom' />} />
                    <Route
                      path='/overview'
                      element={
                        <LoginGuard>
                          <HomePage />
                        </LoginGuard>
                      }
                    />
                    <Route
                      path='/attendance'
                      element={
                        <LoginGuard>
                          <AttendancePage user={user} />
                        </LoginGuard>
                      }
                    />
                    <Route path='/callback' element={<CallbackHandler />} />
                    <Route path='*' element={<ErrorPage />} />
                  </>
                ) : (
                  <>
                    <Route path='/' element={<Navigate to='/chatroom' />} />
                    <Route
                      path='/overview'
                      element={
                        <LoginGuard>
                          <HomePage />
                        </LoginGuard>
                      }
                    />
                    <Route
                      path='/tickets'
                      element={
                        <LoginGuard>
                          <TicketsPage />
                        </LoginGuard>
                      }
                    />
                    <Route
                      path='/chatroom'
                      element={
                        <LoginGuard>
                          <ChatRoom />
                        </LoginGuard>
                      }
                    />
                    <Route
                      path='/create_ticket'
                      element={
                        <LoginGuard>
                          <CreateTicketPage />
                        </LoginGuard>
                      }
                    />
                    <Route
                      path='/notifications'
                      element={
                        <LoginGuard>
                          <NotificationsPage />
                        </LoginGuard>
                      }
                    />
                    <Route
                      path='/attendance'
                      element={
                        <LoginGuard>
                          <AttendancePage user={user} />
                        </LoginGuard>
                      }
                    />
                    <Route
                      path='/meeting'
                      element={
                        <LoginGuard>
                          <MeetingPage />
                        </LoginGuard>
                      }
                    />
                    {/* <Route path='/login' element={access_token == null || access_token == undefined ? <Redirect /> : <HomePage />} /> */}
                    <Route path='/callback' element={<CallbackHandler />} />
                    <Route path='*' element={<ErrorPage />} />
                  </>
                )}
              </Routes>

              <Dialog sx={{ backgroundColor: 'white' }} open={loading} onClose={() => null}>
                {/* <CircularProgress color='inherit' /> */}
              </Dialog>
            </PageSizeContext.Provider>
          </PageContext.Provider>
        </AllTenantsContext.Provider>
      </UserListContext.Provider>
    </UserDataContext.Provider>
  );
};
export default Main;
