import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  IconButton,
  Box,
  Typography,
  Input,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

interface CreateGroupDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateGroupDialog: React.FC<CreateGroupDialogProps> = ({ open, onClose }) => {
  const [groupName, setGroupName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [groupAvatar, setGroupAvatar] = useState<string | null>(null);

  const suggestedUsers = [
    { name: 'Ankit Dangol', initial: 'A' },
    { name: 'Nitesh Shrestha', initial: 'N' },
    { name: 'Sandeep Bagale', initial: 'S' },
    { name: 'Rahul Sharma', initial: 'R' },
    { name: 'Binod Thapa', initial: 'B' },
    { name: 'Chandan Gupta', initial: 'C' },
    { name: 'Deepak Paudel', initial: 'D' },
    { name: 'Emma Watson', initial: 'E' },
    { name: 'Frank Sinatra', initial: 'F' },
    { name: 'George Clooney', initial: 'G' },
    { name: 'Helen Mirren', initial: 'H' },
  ];

  const handleUserToggle = (name: string) => {
    setSelectedUsers((prev) =>
      prev.includes(name) ? prev.filter((user) => user !== name) : [...prev, name],
    );
  };

  const handleRemoveUser = (name: string) => {
    setSelectedUsers((prev) => prev.filter((user) => user !== name));
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setGroupAvatar(e.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant='h6' component='span' sx={{ fontWeight: 'bold' }}>
          Create Group
        </Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
          <IconButton
            component='label' // This allows for a click on the avatar to open the file input
            sx={{ p: 0 }} // Remove padding so only the avatar is clickable
          >
            <Avatar sx={{ width: 80, height: 80, mb: 1 }} src={groupAvatar || undefined}>
              {!groupAvatar && 'G'} {/* Default text or icon */}
            </Avatar>
            <Input
              type='file'
              onChange={handleAvatarChange}
              sx={{ display: 'none' }} // Hide the file input
              inputProps={{ accept: 'image/*' }} // Only accept image files
            />
          </IconButton>
          <TextField
            placeholder='Group Name'
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            variant='standard'
            sx={{ width: '30%' }}
            inputProps={{
              sx: {
                textAlign: 'center', // Centers the placeholder text
              },
            }}
          />
        </Box>

        <TextField
          fullWidth
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          sx={{ mb: 1 }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', mb: 2 }}>
          {selectedUsers.map((user) => {
            const userObj = suggestedUsers.find((u) => u.name === user);
            return (
              <Box key={user} sx={{ position: 'relative', margin: 0.5 }}>
                <Avatar>{userObj?.initial}</Avatar>
                <IconButton
                  size='small'
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '2px',
                  }}
                  onClick={() => handleRemoveUser(user)}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            );
          })}
        </Box>

        <Typography variant='subtitle1' sx={{ mb: 1 }}>
          Suggested
        </Typography>

        <List sx={{ maxHeight: 200, overflow: 'auto' }}>
          {suggestedUsers.map((user) => (
            <ListItem key={user.name} dense>
              <ListItemAvatar>
                <Avatar>{user.initial}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.name} />
              <Checkbox
                edge='end'
                checked={selectedUsers.includes(user.name)}
                onChange={() => handleUserToggle(user.name)}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={onClose}
          disabled={!groupName || selectedUsers.length === 0}
          sx={{
            bgcolor: groupName ? 'black' : 'grey.500',
            color: 'white',
            '&:hover': {
              bgcolor: groupName ? 'black' : 'grey.600',
            },
            width: 'auto',
            px: 4,
          }}
        >
          Create Group
          <IconButton>
            <GroupAddIcon
              sx={{ color: groupName && selectedUsers.length > 0 ? 'white' : 'grey.500' }}
            />
          </IconButton>
        </Button>
      </Box>
    </Dialog>
  );
};

export default CreateGroupDialog;
