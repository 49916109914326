import { Box, Typography } from '@mui/material';
import moment from 'moment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TicketDetails from '../TicketDetails';
import { useState } from 'react';
import { useNotifications } from '@novu/notification-center';

const NotificationList = (props: any) => {

    const { markNotificationAsRead } = useNotifications();

    const [open, setOpen] = useState(false);
    const ticketId = props.notification.payload.ticket;

    const handleNotificationClick = (id: string) => {
        markNotificationAsRead(id);
        setOpen(true);
    };

    return (
        <>
            <Box display='flex' flexDirection='column' borderTop='1px solid lightgray' py='15px' onClick={() => handleNotificationClick(props.notification._id)}>
                <Box display='flex' gap='5px' justifyContent='space-between' alignItems='center' sx={{ cursor: 'pointer' }}>
                    <Typography sx={{ wordBreak: 'break-word' }}>{props.notification.content}</Typography>
                    <Box display='flex'>
                        {props.notification.read != true ?
                            <FiberManualRecordIcon sx={{ width: '7px', color: '#d40015' }} />
                            : ''
                        }
                    </Box>
                </Box>
                <Box display='flex' gap='5px' justifyContent='space-between' alignItems='center' sx={{ cursor: 'pointer' }}>
                    <Typography fontSize='12px'>{new Date(props.notification.createdAt).toLocaleDateString('en-GB')}</Typography>
                    <Typography fontSize='12px'>{moment(props.notification.createdAt).format("h:mm a")}</Typography>
                </Box>
            </Box>

            {open &&
                <TicketDetails open={open} setOpen={setOpen} ticketId={ticketId} />
            }
        </>
    )
}

export default NotificationList
