import { useContext, useEffect, useState } from 'react'
import { AttendanceBlock, CombinedReportContext } from '../../layout/Desktop/DesktopAttendanceLayout'
import Switch from '@mui/material/Switch';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import axios from 'axios';
// import Info from './Info';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { failed, success } from '../../hooks/useToast';
import AttendanceReport from './AttendanceReport';
import { CurrentUserContext } from '../../App';
import { recordLog } from '../../services/recordLog';
import { UserDataContext } from '../../routes/Main';
import { ITicketData } from '../../pages/HomePage';
import Pills from '../Pills';
import ErrorIcon from '@mui/icons-material/Error';
import { removeLeadingZerosRegex } from './MonthlyAttendanceReport';

export const formattedTime = (time: any) => {
    const hours = time?.slice(0, 2);
    const minutes = time?.slice(3, 5);
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = (hours % 12) || 12;
    return `${formattedHours}:${minutes} ${period}`;
}

export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const getCurrentTime = () => {
    const today = new Date();
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
};

const CheckInOut = () => {

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const { userData } = useContext(UserDataContext);

    const { currentUser } = useContext(CurrentUserContext);

    const { combinedReport } = useContext(CombinedReportContext);

    const [checkInsToday, setCheckInsToday] = useState<any>();

    const [checkInId, setCheckInId] = useState(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.id);

    const [checkIn, setCheckIn] = useState(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.in_time);
    const [checkOut, setCheckOut] = useState(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.out_time);
    const [checkedIn, setCheckedIn] = useState(checkIn ? true : false);

    const [lunchOut, setLunchOut] = useState(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.lunch_out);
    const [lunchIn, setLunchIn] = useState(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.lunch_in);
    const [lunch, setLunch] = useState(lunchOut ? true : false);

    const [alert, setAlert] = useState(false);

    const getCheckInsToday = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/check-ins?pagination[pageSize]=99&filters[date][$eq]=${getCurrentDate()}&populate=*`, {
            headers: headers
        })
            .then(res => setCheckInsToday(res?.data))
        // .catch(err => console.log(err))
    }

    useEffect(() => {
        getCheckInsToday()
    }, [currentUser])

    useEffect(() => {
        setCheckInId(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.id)
    }, [checkInsToday, currentUser, checkedIn])

    useEffect(() => {
        setCheckIn(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.in_time)
    }, [checkInsToday, currentUser, checkedIn])

    useEffect(() => {
        setCheckOut(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.out_time)
    }, [checkInsToday, currentUser, checkedIn])

    useEffect(() => {
        setCheckedIn((checkIn && !checkOut) ? true : false)
    }, [checkIn, checkOut])

    useEffect(() => {
        setLunchOut(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.lunch_out)
    }, [checkInsToday, currentUser, lunch])

    useEffect(() => {
        setLunchIn(checkInsToday?.data?.filter((data: any) => data?.attributes?.attendance_user?.data?.attributes?.sis_id === currentUser?.attributes?.sis_id)[0]?.attributes?.lunch_in)
    }, [checkInsToday, currentUser, lunch])

    useEffect(() => {
        setLunch((lunchOut && !lunchIn) ? true : false)
    }, [lunchOut, lunchIn])

    const [wipAlert, setWipAlert] = useState(false);

    const wipCheck = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=99&filters[status][$eq]=in progress`, {
            headers: headers
        })
            .then(res => {
                if (res?.data?.data?.filter((ticket: ITicketData) => ticket?.attributes?.assignees?.includes(userData?.data?.name))?.length > 0) {
                    setCheckInConfirmatrion(true)
                } else {
                    setWipAlert(true)
                }
            })
            .catch(() => failed())
    }

    const [dueDateAlert, setDueDateAlert] = useState(false);

    const dueDateCheck = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=99&filters[status][$eq]=in progress`, {
            headers: headers
        })
            .then(res => {
                if (res?.data?.data?.filter((ticket: ITicketData) => ticket?.attributes?.assignees?.includes(userData?.data?.name) && ticket?.attributes?.due_date === getCurrentDate())?.length === 0) {
                    setCheckInConfirmatrion(true)
                } else {
                    setDueDateAlert(true)
                }
            })
            .catch(() => failed())
    }

    const [currentBsDate, setCurrentBsDate] = useState<string>();

    const getBsDate = (adDate: string) => {
        const year = adDate.split('-')[0]
        const month = adDate.split('-')[1]
        axios.get(`https://calendar.scieverinc.com/calendar/ad/${year}/${removeLeadingZerosRegex(month)}/`)
            .then(res => {
                setCurrentBsDate(res.data.filter((date: { adDate: string }) => date.adDate === `${year}-${removeLeadingZerosRegex(month)}-${new Date().getDate()}`)[0]?.bsDate);
            })
            .catch(() => failed())
    }

    useEffect(() => {
        getBsDate(getCurrentDate())
    }, [])

    const handleCheckIn = () => {
        const data = {
            data: {
                attendance_user: JSON.parse(currentUser?.id),
                date: getCurrentDate(),
                bs_date: currentBsDate,
                in_time: getCurrentTime()
            }
        }

        axios.post(`${process.env.REACT_APP_strapiUrl}/check-ins`, data, {
            headers: headers
        })
            .then(() => {
                recordLog(`Attendance`, 'Checked In', 'attendance', '-', currentUser?.id);
                success();
                getCheckInsToday();
            })
            .catch(() => failed())
    }

    const handleCheckOut = () => {
        const data = {
            data: {
                out_time: getCurrentTime(),
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/check-ins/${checkInId}`, data, {
            headers: headers
        })
            .then(() => {
                recordLog(`Attendance`, 'Checked Out', 'attendance', '-', currentUser?.id);
                success();
                getCheckInsToday();
            })
            .catch(() => failed())
    }

    const handleLunchOut = () => {
        const data = {
            data: {
                lunch_out: getCurrentTime()
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/check-ins/${checkInId}`, data, {
            headers: headers
        })
            .then(() => {
                recordLog(`Attendance`, 'Out For Lunch', 'attendance', '-', currentUser?.id);
                success();
                getCheckInsToday();
            })
            .catch(() => failed())
    }

    const handleLunchIn = () => {
        const data = {
            data: {
                lunch_in: getCurrentTime()
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/check-ins/${checkInId}`, data, {
            headers: headers
        })
            .then(() => {
                recordLog(`Attendance`, 'Back From Lunch', 'attendance', '-', currentUser?.id);
                success();
                getCheckInsToday();
            })
            .catch(() => failed())
    }

    const [reportOpen, setReportOpen] = useState(false)

    const [checkInConfirmation, setCheckInConfirmatrion] = useState(false)
    const [lunchConfirmation, setLunchConfirmatrion] = useState(false)

    return (
        <>
            <AttendanceBlock sx={{ border: 'none', p: '0px', cursor: 'default' }}>
                <Box display='flex' flexDirection='column' gap='20px' height='100%' width='100%'>
                    <AttendanceBlock sx={{ height: '50%' }}>
                        {/* <Info section='Attendance' /> */}
                        {combinedReport ?
                            <Box display='flex' gap='5px' ml='-3px' alignItems='center' sx={{ cursor: 'pointer' }} onClick={() => setReportOpen(true)}>
                                <DomainVerificationIcon sx={{ width: '25px', height: '25px' }} />
                                <b>Attendance</b>
                            </Box>
                            :
                            <Box display='flex' gap='2px' ml='-5px' alignItems='center'
                                onClick={() => {
                                    if (checkedIn) {
                                        if (userData?.data?.roles[0]?.name === 'dev') {
                                            dueDateCheck()
                                        } else {
                                            setCheckInConfirmatrion(true)
                                        }
                                    } else {
                                        if (userData?.data?.roles[0]?.name === 'dev') {
                                            wipCheck()
                                        } else {
                                            setCheckInConfirmatrion(true)
                                        }
                                    }
                                }}
                                sx={{ cursor: 'pointer' }}>
                                <Switch checked={checkedIn} color='success' size='small' />
                                <b>Attendance</b>
                            </Box>
                        }
                        {combinedReport ?
                            <>
                                <Box display='flex' flexDirection='column' gap='10px' mt='10px' width='100%' justifyContent='space-between' sx={{ overflowY: 'scroll' }}>
                                    {checkInsToday?.data?.map((checkIn: { attributes: { attendance_user: any, in_time: string, out_time: string } }) => {
                                        return (
                                            <Box key={checkIn?.attributes?.attendance_user?.id} display='flex' alignItems='center' justifyContent='space-between'>
                                                <Typography flex={2}>
                                                    {checkIn?.attributes?.attendance_user?.data?.attributes?.name?.split(' ')[0]}
                                                </Typography>
                                                <Typography flex={3}>
                                                    {formattedTime(checkIn?.attributes?.in_time)} - {checkIn?.attributes?.out_time ? formattedTime(checkIn?.attributes?.out_time) : ''}
                                                </Typography>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </>
                            :
                            <Box display='flex' mt='10px' width='100%' justifyContent='space-between'>
                                <Box display='flex' flexDirection='column' gap='5px'>
                                    <Typography fontSize='15px'>Check-in:</Typography>
                                    <Typography fontSize='15px'>Check-out:</Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' gap='5px'>
                                    <Typography fontSize='15px' alignSelf='end'>{checkIn ? formattedTime(checkIn) : 'N/A'}</Typography>
                                    <Typography fontSize='15px' alignSelf='end'>{checkOut ? formattedTime(checkOut) : 'N/A'}</Typography>
                                </Box>
                            </Box>
                        }
                    </AttendanceBlock>

                    <AttendanceBlock sx={{ height: '50%' }}>
                        {/* <Info section='Lunch' /> */}
                        {combinedReport ?
                            <Box display='flex' gap='5px' ml='-3px' alignItems='center' onClick={() => setReportOpen(true)} sx={{ cursor: 'pointer' }}>
                                <RestaurantIcon sx={{ width: '25px', height: '25px' }} />
                                <b>Lunch</b>
                            </Box>
                            :
                            <Box display='flex' gap='2px' ml='-5px' alignItems='center'
                                onClick={() => {
                                    setLunchConfirmatrion(true)
                                }}
                                sx={{ cursor: 'pointer' }}>
                                <Switch checked={lunch} color='success' size='small' />
                                <b>Lunch</b>
                            </Box>
                        }
                        {combinedReport ?
                            <Box display='flex' flexDirection='column' gap='10px' mt='10px' width='100%' justifyContent='space-between' sx={{ overflowY: 'scroll' }}>
                                {checkInsToday?.data?.map((checkIn: { attributes: { attendance_user: any, lunch_out: string, lunch_in: string } }) => {
                                    if (checkIn?.attributes?.lunch_out !== null) {
                                        return (
                                            <Box key={checkIn?.attributes?.attendance_user?.id} display='flex' alignItems='center' justifyContent='space-between'>
                                                <Typography flex={2}>
                                                    {checkIn?.attributes?.attendance_user?.data?.attributes?.name?.split(' ')[0]}
                                                </Typography>
                                                <Typography flex={3}>
                                                    {formattedTime(checkIn?.attributes?.lunch_out)} - {checkIn?.attributes?.lunch_in ? formattedTime(checkIn?.attributes?.lunch_in) : ''}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                })}
                            </Box>
                            :
                            <Box display='flex' mt='10px' width='100%' justifyContent='space-between'>
                                <Box display='flex' flexDirection='column' gap='5px'>
                                    <Typography fontSize='15px'>Check-out:</Typography>
                                    <Typography fontSize='15px'>Check-in:</Typography>
                                </Box>

                                <Box display='flex' flexDirection='column' gap='5px'>
                                    <Typography fontSize='15px' alignSelf='end'>{lunchOut ? formattedTime(lunchOut) : 'N/A'}</Typography>
                                    <Typography fontSize='15px' alignSelf='end'>{lunchIn ? formattedTime(lunchIn) : 'N/A'}</Typography>
                                </Box>
                            </Box>
                        }
                    </AttendanceBlock>
                </Box >
            </AttendanceBlock >

            <Dialog open={alert} onClose={() => setAlert(false)}>
                <DialogContent>
                    <Typography display='flex' gap='5px'>
                        <ErrorIcon /> Already checked in today!
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog open={wipAlert} onClose={() => setWipAlert(false)}>
                <DialogContent>
                    <Typography display='flex' gap='5px'>
                        <ErrorIcon /> Move a ticket to <Pills pill='in progress' /> before checking in.
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog open={dueDateAlert} onClose={() => setDueDateAlert(false)} maxWidth='lg'>
                <DialogContent>
                    <Typography display='flex' gap='5px'>
                        <ErrorIcon /> Ticket(s) in <Pills pill='in progress' /> is due today. Change the due date or the status before checking out.
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog open={reportOpen} onClose={() => setReportOpen(false)} fullWidth maxWidth='lg'>
                <DialogContent>
                    <AttendanceReport />
                </DialogContent>
            </Dialog>

            <Dialog open={checkInConfirmation} onClose={() => setCheckInConfirmatrion(false)}>
                <DialogContent>
                    <Box display='flex' flexDirection='column' alignItems='center' gap='15px'>
                        {checkedIn ?
                            <Typography>Are you sure you want to check out?</Typography>
                            :
                            <Typography>Are you sure you want to check in?</Typography>
                        }
                        <Box display='flex' gap='10px'>
                            <Button size='small' variant='contained' color='info' onClick={() => {
                                setCheckInConfirmatrion(false);
                                checkedIn ? setCheckedIn(false) : setCheckedIn(true);
                                !checkIn ? handleCheckIn() : (checkIn && !checkOut) ? handleCheckOut() : (setAlert(true), setCheckedIn(false));
                            }}>Yes</Button>
                            <Button size='small' variant='outlined' onClick={() => setCheckInConfirmatrion(false)}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={lunchConfirmation} onClose={() => setLunchConfirmatrion(false)}>
                <DialogContent>
                    <Box display='flex' flexDirection='column' alignItems='center' gap='15px'>
                        {lunch ?
                            <Typography>Back from lunch?</Typography>
                            :
                            <Typography>Out for lunch?</Typography>
                        }
                        <Box display='flex' gap='10px'>
                            <Button size='small' variant='contained' color='info' onClick={() => {
                                setLunchConfirmatrion(false);
                                lunch ? setLunch(false) : setLunch(true);
                                !lunchOut ? handleLunchOut() : (lunchOut && !lunchIn) ? handleLunchIn() : (setAlert(true), setLunch(false));
                            }}>Yes</Button>
                            <Button size='small' variant='outlined' onClick={() => setLunchConfirmatrion(false)}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CheckInOut
