import { createContext, useContext, useEffect, useState } from 'react'
import Sidebar from '../../components/Desktop/Sidebar'
import { Box, FormControl, ImageList, ImageListItem, MenuItem, Select, Typography, styled } from '@mui/material'
import CheckInOut from '../../components/Attendance/CheckInOut'
import Holidays from '../../components/Attendance/Holidays'
import Leave from '../../components/Attendance/Leave'
import Report from '../../components/Attendance/Report'
import isSuperAdmin from '../../hooks/isSuperAdmin'
import isAdmin from '../../hooks/isAdmin'
import { AttendanceUsersContext } from '../../pages/AttendancePage'
import Details from '../../components/Attendance/Details'
import { CurrentUserContext, LoadingContext } from '../../App'
import Policy from '../../components/Attendance/Policy'
import logo from '../../assets/images/sciever_logo_png.png';

export const AttendanceBlock = styled('div')({
    height: 'calc(100vh - 100px)',
    padding: '15px',
    flex: '1',
    border: '1px solid lightgray',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px',
    position: 'relative'
})

export const CombinedReportContext = createContext<any>({});

const DesktopAttendanceLayout = () => {

    const superAdmin = isSuperAdmin();
    const admin = isAdmin();

    const [combinedReport, setCombinedReport] = useState(false);

    const { setLoading } = useContext(LoadingContext);

    const { attendanceUsers } = useContext(AttendanceUsersContext);

    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

    const [currentUserId, setCurrentUserId] = useState<number>(currentUser?.id)

    useEffect(() => {
        currentUser ? setLoading(false) : setLoading(true)
    }, [currentUser])

    useEffect(() => {
        currentUserId === 0 ? setCombinedReport(true) : setCombinedReport(false)
        currentUserId !== 0 && setCurrentUser(attendanceUsers?.filter((user: any) => user?.id === currentUserId)[0])
    }, [currentUserId])

    const sortedUsers = attendanceUsers?.slice()?.sort((a: { attributes: { name: string } }, b: { attributes: { name: string } }) => a.attributes.name.localeCompare(b.attributes.name));

    useEffect(() => {
        setCurrentUserId(currentUser?.id)
    }, [currentUser])

    return (
        <>
            <CombinedReportContext.Provider value={{ combinedReport, setCombinedReport }}>
                {/* <DesktopHeader /> */}
                <Sidebar />
                <Box
                    bgcolor='white'
                    position='fixed'
                    left='85px'
                    right='0px'
                    top='0px'
                    bottom='-25px'
                    p='14px 20px'
                    overflow='hidden'
                // sx={{ borderTopLeftRadius: '10px' }}f
                >
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box display='flex' alignItems='center' gap='10px'>
                            <ImageList
                                sx={{
                                    width: '45px',
                                    height: '45px',
                                    overflow: 'hidden',
                                    m: '0px'
                                }}
                                cols={1}
                            >
                                <ImageListItem>
                                    <img src={logo} alt='Sciever Inc. Logo' style={{ width: '45px', height: '45px' }} />
                                </ImageListItem>
                            </ImageList>

                            <Typography variant='h6' fontWeight='bold'>
                                Employee Management
                            </Typography>

                            <Policy />
                        </Box>

                        {(superAdmin || admin) &&
                            // <PersonIcon onClick={() => setUsersOpen(true)} sx={{ cursor: 'pointer' }} />
                            <FormControl sx={{ m: 1 }} size='small'>
                                <Select
                                    value={currentUserId !== undefined ? currentUserId : ''}
                                    sx={{ height: '30px', pb: '1px' }}
                                    onChange={(e: any) => setCurrentUserId(e.target.value)}
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {sortedUsers
                                        ?.map((user: { attributes: { name: string }, id: number }) => {
                                            return <MenuItem value={user?.id} key={user?.id}>{user?.attributes?.name?.split(' ')[0]}</MenuItem>
                                        })}
                                </Select>
                            </FormControl>
                        }
                    </Box>

                    <Box display='flex' width='100%' mt='15px' gap='15px' justifyContent='space-between'>
                        <Details />
                        <CheckInOut />
                        <AttendanceBlock sx={{ border: 'none', p: '0px', cursor: 'default' }}>
                            <Box display='flex' flexDirection='column' gap='10px' height='100%' width='100%'>
                                <Leave />
                                <Holidays />
                            </Box>
                        </AttendanceBlock>
                        {/* <AttendanceBlock sx={{ border: 'none', p: '0px', cursor: 'default' }}> */}
                        {/* <Box display='flex' flexDirection='column' gap='15px' height='100%' width='100%'> */}
                        {/* <Hours /> */}
                        {/* <Report /> */}
                        {/* </Box> */}
                        {/* </AttendanceBlock> */}
                        <Report />
                    </Box>
                </Box>
            </CombinedReportContext.Provider>
        </>
    )
}

export default DesktopAttendanceLayout
