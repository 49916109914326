import { createContext, useContext, useEffect, useState } from 'react'
import isMobile from '../hooks/isMobile'
import MobileAttendanceLayout from '../layout/Mobile/MobileAttendanceLayout';
import DesktopAttendanceLayout from '../layout/Desktop/DesktopAttendanceLayout';
import { UserListContext } from '../routes/Main';
import axios from 'axios';
import { failed } from '../hooks/useToast';
import { CurrentUserContext } from '../App';
import { Navigate } from 'react-router-dom';
import isAttendanceUser from '../hooks/isAttendanceUser';

export const AttendanceUsersContext = createContext<any>({});

const AttendancePage = (props: {
    user: any
}) => {

    const mobile = isMobile();

    const { userList } = useContext(UserListContext);

    const { setCurrentUser } = useContext(CurrentUserContext);

    useEffect(() => {
        setCurrentUser(props?.user)
    }, [props?.user])

    const [sisUsers, setSisUsers] = useState<any>();

    const [attendanceUsers, setAttendanceUsers] = useState<any>();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users?pagination[pageSize]=100`, {
            headers: headers
        })
            .then(res => {
                setAttendanceUsers(res?.data?.data)
            })
            .catch(() => failed())
    }, [])

    useEffect(() => {
        setSisUsers(userList?.data?.users?.users?.filter((user: { organizations: [{ alias: string }] }) => user?.organizations?.filter((organization: { alias: string }) => organization?.alias === 'sciever')?.length > 0));
    }, [userList])

    useEffect(() => {
        sisUsers?.map((sisUser: any) => {
            if (attendanceUsers?.filter((user: any) => user?.attributes?.sis_id == sisUser?.id)?.length === 0) {
                const data = {
                    data: {
                        sis_id: JSON.stringify(sisUser?.id),
                        name: sisUser?.name,
                        contact: sisUser?.phone_no,
                        official_email: sisUser?.email
                    }
                }

                axios.post(`${process.env.REACT_APP_strapiUrl}/attendance-users`, data, {
                    headers: headers
                })
                    .catch(() => failed())
            }
        })
    }, [sisUsers, attendanceUsers])

    const attendanceUser = isAttendanceUser();

    return (
        <>
            {!attendanceUser ?
                <Navigate to='/overview' />
                :
                <AttendanceUsersContext.Provider value={{ attendanceUsers, setAttendanceUsers }}>
                    {mobile ? <MobileAttendanceLayout /> : <DesktopAttendanceLayout />}
                </AttendanceUsersContext.Provider>
            }
        </>
    )
}

export default AttendancePage
