import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useState, useContext } from 'react';
import { Dialog, Box, Typography, DialogContent, InputAdornment, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { CurrentUserContext, LoadingContext, RefreshContext } from '../../App';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import isAdmin from '../../hooks/isAdmin';
import { UserDataContext } from '../../routes/Main';
import { DataContext } from '../../pages/TicketsPage';
import { TicketListContext } from '../../pages/HomePage';
import isDeveloper from '../../hooks/isDeveloper';
import { recordLog } from '../../services/recordLog';

const DueDatePicker = (props: {
    selectedRowId: string
}) => {

    const admin = isAdmin();

    const superAdmin = isSuperAdmin();

    const developer = isDeveloper();

    const { refresh, setRefresh } = useContext(RefreshContext);

    const { userData } = useContext(UserDataContext);

    const { setLoading } = useContext(LoadingContext);

    const { fetchTickets } = useContext(DataContext);

    const { fetchTicketsHomePage } = useContext(TicketListContext);

    const { currentUser } = useContext(CurrentUserContext);

    const toggleRefresh = () => {
        refresh == true ? setRefresh(false) : setRefresh(true)
    }

    const id = props.selectedRowId != null ? props.selectedRowId.slice(4) : 'undefined';
    const selectedId = parseInt(id);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [commentOpen, setCommentOpen] = useState(false);

    const [comment, setComment] = useState('');

    const handleCommentClose = () => {
        setCommentOpen(false);
    };

    const [date, setDate] = useState<any>();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const handleDate = () => {
        setLoading(true);

        const dt = new Date(date);

        const year = dt.toLocaleString("default", { year: "numeric" });
        const month = dt.toLocaleString("default", { month: "2-digit" });
        const day = dt.toLocaleString("default", { day: "2-digit" });

        const formattedDate = year + "-" + month + "-" + day;

        const dueDate = {
            data: {
                'due_date': formattedDate
            }
        }

        const messageInfo = {
            data: {
                message: `${formattedDate}`,
                sender: userData !== undefined && userData.data.name,
                ticket: selectedId,
                admin: (admin || superAdmin || developer) && true,
                changed_to: 'Due Date'
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/tickets/${selectedId}`, dueDate, {
            headers: headers
        }).then(() => {
            recordLog(`Due Date`, formattedDate, 'ticket', props.selectedRowId, currentUser?.id);
            handleClose();
            axios.post(`${process.env.REACT_APP_strapiUrl}/chats`, messageInfo, {
                headers: headers
            }).then(() => {
                handleCommentClose();
                toggleRefresh();
                setComment('');
                fetchTicketsHomePage();
                setLoading(false);
            })
            fetchTickets();
        }).catch(() => {
            // failed();
            setLoading(false);
        })
    }

    return (
        <>
            {(superAdmin || admin || developer)
                ? <CalendarMonthIcon onClick={handleClickOpen} cursor='pointer' />
                : '-'
            }

            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <Box display='flex' flexDirection='column' gap='10px' p='15px'>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Typography>Enter Date:</Typography>
                        <CloseIcon onClick={handleClose} cursor='pointer' />
                    </Box>

                    <Box display='flex' alignItems='center' justifyContent='space-between' gap='5px'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={(x) => setDate(x)} disableOpenPicker format='DD/MM/YYYY' slotProps={{ textField: { size: 'small' } }} />
                        </LocalizationProvider>

                        <SendIcon cursor='pointer' onClick={() => handleDate()} />
                    </Box>
                </Box>
            </Dialog >

            <Dialog open={commentOpen} onClose={handleCommentClose} fullWidth>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='15px'>
                        <Box display='flex' justifyContent='space-between'>
                            <Typography fontWeight='bold'>Comment:</Typography>
                            <CloseIcon onClick={handleCommentClose} cursor='pointer' />
                        </Box>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            InputProps={{
                                endAdornment: <InputAdornment position='end' sx={{ mt: '-68px' }}><SendIcon onClick={handleDate} sx={{ cursor: 'pointer' }} /></InputAdornment>,
                            }}
                            onChange={e => setComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleDate();
                                    e.preventDefault();
                                }
                            }}
                            value={comment}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DueDatePicker;
