import EditIcon from '@mui/icons-material/Edit';
import { Box, Dialog, DialogContent, InputAdornment, TextField, Typography } from '@mui/material';
import { useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import isMobile from '../hooks/isMobile';
import { CurrentUserContext, LoadingContext } from '../App';
import { failed } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
import { UserDataContext } from '../routes/Main';
import isAdmin from '../hooks/isAdmin';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';

const EditDescription = (props:
    {
        ticket_id: string,
        id: string | number,
        current: string
    }
) => {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setDescription(props.current);
    }

    const [description, setDescription] = useState(props.current);

    const mobile = isMobile();

    const { setLoading } = useContext(LoadingContext);

    const { fetchTickets } = useContext(DataContext);

    const { userData } = useContext(UserDataContext);

    const { currentUser } = useContext(CurrentUserContext);

    const { fetchTicketsHomePage } = useContext(TicketListContext);

    const admin = isAdmin();
    const superAdmin = isSuperAdmin();
    const developer = isDeveloper();

    const handleEdit = async () => {
        setLoading(true);

        const ticketInfo = {
            description: description,
        };

        await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${props.id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: ticketInfo }),
        })
            .then(() => {
                setOpen(false);
                setLoading(false);
                recordLog(`Description`, description, 'ticket', props.ticket_id, currentUser?.id);
            })
            .catch(() => {
                failed();
                setLoading(false);
            });

        const messageInfo = {
            message: description,
            sender: userData !== undefined && userData.data.name,
            ticket: props.id,
            admin: (admin || superAdmin || developer) && true,
            changed_to: 'Description'
        };

        await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: messageInfo }),
        })
            .then(() => {
                setLoading(false);
                fetchTicketsHomePage();
            })
            .catch(() => {
                // failed();
                setLoading(false);
            });


        fetchTickets();

    }

    return (
        <>
            <EditIcon onClick={() => setOpen(true)} sx={{ height: '16px', color: 'gray', mb: '-2px', cursor: 'pointer' }} />

            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='15px'>
                        <Box display='flex' justifyContent='space-between'>
                            <Typography fontWeight='bold'>Description:</Typography>
                            <CloseIcon onClick={handleClose} cursor='pointer' />
                        </Box>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' sx={{ mt: '-68px' }}>
                                        <SendIcon sx={{ cursor: 'pointer' }} onClick={handleEdit} />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setDescription(e.target.value)}
                            onKeyDown={(e) => {
                                if (!mobile) {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        handleEdit();
                                        e.preventDefault();
                                    }
                                }
                            }}
                            value={description}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EditDescription;
