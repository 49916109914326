import { useContext, useEffect, useState } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import axios from 'axios';
import { failed } from '../../hooks/useToast';
import { CurrentUserContext } from '../../App';

export const extractTime = (start: string | undefined | null, end: string | undefined | null) => {
    const startTime = start;
    const endTime = end;

    const startTimeObj: any = new Date(`2000-01-01T${startTime}Z`);
    const endTimeObj: any = new Date(`2000-01-01T${endTime}Z`);

    const timeDiff = endTimeObj - startTimeObj;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`
};

export const getCurrentTime = () => {
    const currentTime = new Date();

    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
    const milliseconds = currentTime.getMilliseconds().toString().padStart(3, '0');

    const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}`;

    return formattedTime
}

const Hours = () => {

    const { currentUser } = useContext(CurrentUserContext);

    const [open, setOpen] = useState(false);

    const getWeekDates = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();

        const sunday = new Date(today);
        sunday.setDate(today.getDate() - dayOfWeek);

        const weekDates = [];

        for (let i = 0; i < 7; i++) {
            const date = new Date(sunday);
            date.setDate(sunday.getDate() + i);
            // Format the date as "YYYY-MM-DD"
            const formattedDate = date.toISOString().split('T')[0];
            weekDates.push(formattedDate);
        }

        return weekDates;
    }

    const weekDates = getWeekDates();

    const [checkIns, setCheckIns] = useState<any>();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const getCheckIns = () => {
        currentUser !== undefined &&
            axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users/${currentUser?.id}?populate=*`, {
                headers: headers
            })
                .then(res => setCheckIns(res?.data?.data?.attributes?.check_ins?.data))
                .catch(() => failed())
    }

    useEffect(() => {
        getCheckIns();
    }, [currentUser])

    const [todayCheckIn, setTodayCheckIn] = useState<[{ attributes: { in_time: string, out_time: string | null } }]>()
    const [weekCheckIns, setWeekCheckIns] = useState<[{ attributes: { in_time: string, out_time: string | null } }]>()
    const [monthlyCheckIns, setMonthlyCheckIns] = useState<[{ attributes: { in_time: string, out_time: string | null } }]>()

    useEffect(() => {
        setTodayCheckIn(checkIns?.filter((checkIn: { attributes: { date: string } }) => checkIn?.attributes?.date === new Date().toISOString().split('T')[0]))
        setWeekCheckIns(checkIns?.filter((checkIn: { attributes: { date: string } }) => weekDates?.includes(checkIn?.attributes?.date)))
        setMonthlyCheckIns(checkIns?.filter((checkIn: { attributes: { date: string } }) => checkIn?.attributes?.date?.substring(5, 7) === new Date().toISOString().split('T')[0]?.substring(5, 7)))
    }, [checkIns])

    const [dailyHours, setDailyHours] = useState<string>();
    const [weeklyHours, setWeeklyHours] = useState<string>();
    const [monthlyHours, setMonthlyHours] = useState<string>();

    const totalTimeCalculator = (timeArray: string[]) => {
        const calculateTotalTime = (timeArray: string[]) => {
            let totalHours = 0;
            let totalMinutes = 0;

            timeArray?.forEach((timeStr: any) => {
                const [hoursStr, minutesStr] = timeStr.split('h ');
                const hours = parseInt(hoursStr);
                const minutes = parseInt(minutesStr?.replace('m', ''));

                totalHours += hours;
                totalMinutes += minutes;
            });

            totalHours += Math.floor(totalMinutes / 60);
            totalMinutes %= 60;

            return { totalHours, totalMinutes };
        };

        const { totalHours, totalMinutes } = calculateTotalTime(timeArray)

        return `${totalHours}h ${totalMinutes}m`
    }

    const [timeRefresh, setTimeRefresh] = useState(false)

    setTimeout(() => {
        timeRefresh ? setTimeRefresh(false) : setTimeRefresh(true)
    }, 30000);

    useEffect(() => {
        setDailyHours(
            extractTime(
                todayCheckIn !== undefined && todayCheckIn?.length > 0 ?
                    todayCheckIn[0]?.attributes?.in_time
                    : '00:00:00.000',
                todayCheckIn !== undefined && todayCheckIn?.length > 0 ?
                    (todayCheckIn[0]?.attributes?.out_time !== null ?
                        todayCheckIn[0]?.attributes?.out_time
                        : getCurrentTime())
                    : '00:00:00.000')
        )
    }, [todayCheckIn, timeRefresh])

    useEffect(() => {
        const allCheckIns = weekCheckIns?.map(checkIn => [checkIn?.attributes?.in_time, checkIn?.attributes?.out_time])
        const timeArray = allCheckIns?.map(checkIn => extractTime(checkIn[0], (checkIn[1] !== null ? checkIn[1] : getCurrentTime())));
        setWeeklyHours(totalTimeCalculator(timeArray !== undefined ? timeArray : ['0h', '0m']));
    }, [weekCheckIns, timeRefresh])

    useEffect(() => {
        const allCheckIns = monthlyCheckIns?.map(checkIn => [checkIn?.attributes?.in_time, checkIn?.attributes?.out_time])
        const timeArray = allCheckIns?.map(checkIn => extractTime(checkIn[0], (checkIn[1] !== null ? checkIn[1] : getCurrentTime())));
        setMonthlyHours(totalTimeCalculator(timeArray !== undefined ? timeArray : ['0h', '0m']));
    }, [monthlyCheckIns, timeRefresh])

    return (
        <>
            {/* <AttendanceBlock>
                <Info section='Hours' />
                <Box display='flex' flexDirection='column' gap='5px' alignItems='center' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                    <AccessTimeIcon sx={{ width: '35px', height: '35px' }} />
                    <b>Hours</b>
                </Box> */}
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                <Box display='flex' flexDirection='column' gap='5px' mt='10px'>
                    <Typography fontSize='15px'>Today:</Typography>
                    <Typography fontSize='15px'>This week:</Typography>
                    <Typography fontSize='15px'>This month:</Typography>
                </Box>

                <Box display='flex' flexDirection='column' gap='5px' alignItems='flex-end' mt='10px'>
                    <Typography fontSize='15px'>{dailyHours}</Typography>
                    <Typography fontSize='15px'>{weeklyHours}</Typography>
                    <Typography fontSize='15px'>{monthlyHours}</Typography>
                </Box>
            </Box>
            {/* </AttendanceBlock> */}

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='20px'>
                        <Box display='flex' alignItems='center' gap='8px'>
                            <AccessTimeIcon />
                            <Typography variant='h6' fontWeight='bold'>Hours</Typography>
                        </Box>
                        <DialogActions>
                            {/* <CloseIcon onClick={() => setOpen(false)} cursor='pointer' /> */}
                        </DialogActions>
                    </Box>
                    <Box display='flex' gap='100px'>
                        <Box display='flex' flexDirection='column' gap='15px'>
                            <Typography>Today:</Typography>
                            <Typography>This week:</Typography>
                            <Typography>This month:</Typography>
                        </Box>
                        <Box alignItems='flex-end' display='flex' flexDirection='column' gap='15px'>
                            <Typography>{dailyHours}</Typography>
                            <Typography>{weeklyHours}</Typography>
                            <Typography>{monthlyHours}</Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Hours
