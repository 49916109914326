// Function to tokenize a sentence
const tokenize = (sentence: string): string[] => {
    return sentence.toLowerCase().split(/\W+/).filter(Boolean);
};

// Function to calculate similarity between two strings
const calculateSimilarity = (str1: string, str2: string): number => {
    const tokens1 = tokenize(str1);
    const tokens2 = tokenize(str2);

    const union: string[] = [];
    const intersection: string[] = [];

    for (const token of tokens1) {
        union.push(token);
        if (tokens2.includes(token) && !intersection.includes(token)) {
            intersection.push(token);
        }
    }

    for (const token of tokens2) {
        union.push(token);
    }

    return intersection.length / union.length;
};

export const getRelatedTickets = (inputDescription: string, descriptions: any): string[] => {

    const similarDescs = descriptions?.filter((desc: any) => calculateSimilarity(inputDescription, desc?.attributes?.description) > 0.1);

    return similarDescs;
}
