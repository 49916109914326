import SendIcon from '@mui/icons-material/Send';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { CurrentUserContext, LoadingContext } from '../App';
import isAdmin from '../hooks/isAdmin';
import isMobile from '../hooks/isMobile';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { IChatData } from '../pages/HomePage';
import { UserDataContext } from '../routes/Main';
import { addMessageService } from '../services/ticketService';
import MessageBox from './MessageBox';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';

interface ITicketForum {
  data: any;
  selectedRowId: string;
}

const TicketForum = (props: ITicketForum) => {

  const admin = isAdmin();
  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const [messages, setMessages] = useState<IChatData[]>([]);
  const [message, setMessage] = useState('');

  const { data } = props;
  const { userData } = useContext(UserDataContext);
  const { setLoading } = useContext(LoadingContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [files, setFiles] = useState<any>([]);

  const createMessage = async () => {
    setLoading(true);

    const formData = new FormData()

    Array.from(files).forEach((file: any) => {
      formData.append('files', file)
      formData.append('ref', 'chat');
      formData.append('field', "attachments");
    })

    const ticket = data?.id;

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    axios.post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
      headers: headers
    })
      .then(async (response) => {
        const attachments: any = []
        response.data.map((imageId: { id: number }) => {
          attachments.push(imageId.id)
        })
        const messageInfo = {
          message: message,
          sender: userData !== undefined && userData.data.name,
          ticket: ticket,
          admin: admin || superAdmin || developer,
          attachments: attachments,
          changed_to: 'Comment'
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false))
        if (response2.data.data) {
          setMessages([...messages, response2.data.data]);
          setMessage('');
          setLoading(false);
          setFiles([]);
          recordLog(`Comment`, message, 'ticket', props.selectedRowId, currentUser?.id);
        }
      }).catch(async () => {
        const messageInfo = {
          message: message,
          sender: userData !== undefined && userData.data.name,
          ticket: ticket,
          admin: admin || superAdmin || developer,
          changed_to: 'Comment'
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false))
        if (response2.data.data) {
          setMessages([...messages, response2.data.data]);
          setMessage('');
          setLoading(false);
          setFiles([]);
          recordLog(`Comment`, message, 'ticket', props.selectedRowId, currentUser?.id);
        }
      })

    // const response = await addMessageService({ data: messageInfo }).catch(setLoading(false))

    // if (response.data.data) {
    //   setMessages([...messages, response.data.data]);
    //   setMessage('');
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    setMessages(data?.attributes?.chats?.data ?? []);
  }, [data]);

  const mobile = isMobile();

  const uploadInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {!mobile ? (
        <Box
          height='500px'
          width='700px'
          border='solid'
          borderColor='lightgray'
          borderRadius='5px'
          position='relative'
          sx={{ borderWidth: '1px', overflowY: 'scroll' }}
        >
          <Box mb='15px' position='sticky' top='0px' bgcolor='white' zIndex='1' p='15px'>
            <TextField
              id='outlined-basic'
              placeholder='Reply...'
              variant='outlined'
              fullWidth
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ display: 'flex', gap: '10px' }}>
                    <input
                      ref={uploadInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => setFiles(e.target.files)}
                      multiple
                    // accept='image/*'
                    />
                    <Box display='flex'>
                      <AttachFileIcon onClick={() => uploadInputRef.current && uploadInputRef.current.click()} sx={{ cursor: 'pointer' }} />
                      {files?.length !== undefined && files?.length > 0 && <Box>{files?.length}</Box>}
                    </Box>
                    <SendIcon onClick={createMessage} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  createMessage();
                  e.preventDefault();
                }
              }}
              value={message}
            />
          </Box>

          <Box px='15px' pb='15px' mt='-20px'>
            {messages?.length > 0 &&
              messages
                ?.slice(0)
                ?.reverse()
                .map((message) => {
                  return <MessageBox key={message.id} message={message} />
                }
                )}
          </Box>
        </Box>
      ) : (
        <Box
          height='500px'
          border='solid'
          borderColor='lightgray'
          borderRadius='5px'
          position='relative'
          sx={{ borderWidth: '1px', overflowY: 'scroll' }}
        >
          <Box position='sticky' top='0px' mb='15px' zIndex='1' bgcolor='white' p='15px'>
            <TextField
              id='outlined-basic'
              placeholder='Reply...'
              variant='outlined'
              fullWidth
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ display: 'flex', gap: '10px' }}>
                    <input
                      ref={uploadInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => setFiles(e.target.files)}
                      multiple
                    // accept='image/*'
                    />
                    <Box display='flex'>
                      <AttachFileIcon onClick={() => uploadInputRef.current && uploadInputRef.current.click()} sx={{ cursor: 'pointer' }} />
                      {files?.length !== undefined && files?.length > 0 && <Box>{files?.length}</Box>}
                    </Box>
                    <SendIcon onClick={createMessage} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setMessage(e.target.value)}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter' && !e.shiftKey) {
              //     e.preventDefault();
              //     createMessage();
              //   }
              // }}
              value={message}
            />
          </Box>

          <Box p='15px' mt='-40px'>
            {messages?.length > 0 &&
              messages
                ?.slice(0)
                ?.reverse()
                .map((message) => <MessageBox key={message.id} message={message} />)}
          </Box>
        </Box>
      )}
    </>
  );
};

export default TicketForum;
