import { useState } from 'react';
import ForumIcon from '@mui/icons-material/Forum';
import TicketForum from '../TicketForum';
import { Box, Typography, Dialog } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewAttachments from './ViewAttachments';
import DueDatePicker from './DueDatePicker';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import EditType from '../EditType';
import EditPriority from '../EditPriority';
import EditStatus from '../EditStatus';
import EditDueDate from '../EditDueDate';
import DeleteTicket from '../DeleteTicket';
import AssignDeveloper from '../AssignDeveloper';
import EditDescription from '../EditDescription';
import EditEnvironment from '../EditEnvironment';
import Pills from '../Pills';
import EditMerged from '../EditMerged';
import EditBranch from '../EditBranch';
import Estimate from '../Estimate';

const DesktopTicketForum = (props: {
    selectedRowId: string,
    data: any,
    field: string | undefined,
}) => {
    const data = props.data;

    const superAdmin = isSuperAdmin();

    interface ISelectedTicket {
        attributes: {
            ticket_id: string,
            type: string,
            priority: string,
            status: string,
            due_date: string,
            createdAt: string,
            creator: string,
            description: string,
            url: string,
            estimate: string,
            main_tenant: string,
            assignees: string[],
            merged_ticket: string,
            branch: string
        }
    }

    const getSelectedTicket = (ticket: ISelectedTicket) => {
        return ticket.attributes.ticket_id == props.selectedRowId;
    }

    const selectedTicket = data?.length > 0 ? data.filter(getSelectedTicket) : 'undefined';

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const getTicketId = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.ticket_id
    }
    const getType = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.type
    }
    const getPriority = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.priority
    }
    const getStatus = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.status
    }
    const getDueDate = (selectedTicket: ISelectedTicket) => {
        return (
            // selectedTicket.attributes.due_date != null ? new Date(selectedTicket.attributes.due_date).toLocaleDateString('en-GB') : ''
            selectedTicket.attributes.due_date
        )
    }
    const getCreatedOn = (selectedTicket: ISelectedTicket) => {
        return new Date(selectedTicket.attributes.createdAt).toLocaleDateString('en-GB');
    }
    const getEstimate = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.estimate
    }
    const getDescription = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.description
    }
    const getMergedTicket = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.merged_ticket
    }
    const getUrl = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.url;
    }
    const getTenant = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.main_tenant;
    }
    const getAssignees = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.assignees;
    }
    const getBranch = (selectedTicket: ISelectedTicket) => {
        return selectedTicket.attributes.branch;
    }
    const getImages = (selectedTicket: ISelectedTicket) => {
        return `${process.env.REACT_APP_strapiImage}${selectedTicket.attributes.url}`;
    }

    const ticketId = data?.length > 0 ? selectedTicket.map(getTicketId) : 'undefined';
    const type = data?.length > 0 ? selectedTicket.map(getType) : 'undefined';
    const priority = data?.length > 0 ? selectedTicket.map(getPriority) : 'undefined';
    const status = data?.length > 0 ? selectedTicket.map(getStatus) : 'undefined';
    const dueDate = data?.length > 0 ? selectedTicket.map(getDueDate) : 'undefined';
    const createdOn = data?.length > 0 ? selectedTicket.map(getCreatedOn) : 'undefined';
    const estimate = data?.length > 0 ? selectedTicket.map(getEstimate) : 'undefined';
    const description = data?.length > 0 ? selectedTicket.map(getDescription) : 'undefined';
    const merged_ticket = data?.length > 0 ? selectedTicket.map(getMergedTicket) : 'undefined';
    const tenant = data?.length > 0 ? selectedTicket.map(getTenant) : 'undefined';
    const assignees = data?.length > 0 ? selectedTicket.map(getAssignees) : 'undefined';
    const branch = data?.length > 0 ? selectedTicket.map(getBranch) : 'undefined';
    const images = selectedTicket[0]?.attributes?.attachment?.data?.length > 0 ? selectedTicket[0].attributes.attachment.data.map(getImages) : ''
    const url = selectedTicket[0]?.attributes?.attachment?.data?.length > 0 ? selectedTicket[0].attributes.attachment.data.map(getUrl) : 'undefined'

    const id = ticketId[0].slice(4)
    const idToChange = parseInt(id);

    return (
        <>
            {props.field === 'description'
                ?
                <Box onClick={handleClickOpen} sx={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden' }}>{description[0]}</Box>
                : props.field === 'id'
                    ?
                    <Box onClick={handleClickOpen} sx={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden' }}>{ticketId[0]}</Box>
                    :
                    <ForumIcon onClick={handleClickOpen} cursor='pointer' />
            }
            <Dialog open={open} onClose={handleClose} maxWidth='lg'>
                <Box display='flex' gap='10px' p='15px'>
                    <Box display='flex' flexDirection='column' justifyContent='space-between' gap='15px' m='20px 5px 20px 5px'>
                        <ArrowBackIcon onClick={handleClose} cursor='pointer' />
                        <DeleteTicket tenant={tenant[0]} data={data} selectedRowId={ticketId[0]} />
                    </Box>

                    <Box display='flex' flexDirection='column' justifyContent='space-between' width='300px' p='15px' border='solid' borderColor='lightgray' borderRadius='5px' gap='15px' sx={{ borderWidth: '1px' }}>
                        <Box height='250px' sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                            <Box display='flex' flexDirection='column' gap='25px'>
                                <Typography fontWeight='bold' whiteSpace='pre-wrap'>
                                    <i>{ticketId[0]}</i>     {description[0]}   {superAdmin && <EditDescription ticket_id={ticketId[0]} id={idToChange} current={description[0]} />}
                                </Typography>

                                {merged_ticket[0] &&
                                    <Typography fontWeight='bold' whiteSpace='pre-wrap'>
                                        <Box display='inline-block'><Pills pill='merged' /></Box>   {merged_ticket[0]}   {superAdmin && <EditMerged ticket_id={ticketId[0]} id={idToChange} current={merged_ticket[0]} />}
                                    </Typography>
                                }
                            </Box>
                            {/* <Typography>{creator[0]}</Typography> */}
                        </Box>
                        <Box display='flex' flexDirection='column' gap='10px'>
                            <Box display='flex' justifyContent='space-between' alignItems='end'>
                                <Box display='flex' flexDirection='column' gap='7px' width='100%'>

                                    {/* <Box display='flex' gap='25px'>
                                    <Typography width='85px'>Type</Typography>
                                    <EditType idToChange={idToChange} type={type[0]} />
                                </Box>
                                <Box display='flex' gap='25px'>
                                    <Typography width='85px'>Priority</Typography>
                                    <EditPriority idToChange={idToChange} priority={priority[0]} />
                                </Box>


                                <Box display='flex' gap='25px'>
                                    <Typography width='85px'>Status</Typography>
                                    <EditStatus idToChange={idToChange} status={status[0]} />
                                </Box> */}

                                    <EditBranch type={type[0]} fullId={ticketId[0]} id={idToChange} branch={branch[0]} tenant={tenant[0]} />

                                    <Box display='flex' gap='25px'>
                                        <Typography width='85px'>Due Date</Typography>
                                        {dueDate[0] !== null ? <EditDueDate defaultDate={dueDate[0]} selectedRowId={ticketId[0]} /> : <DueDatePicker selectedRowId={selectedTicket[0].attributes.ticket_id} />}
                                    </Box>

                                    <Box display='flex' gap='25px'>
                                        <Typography width='85px'>Estimate</Typography>
                                        <Estimate estimate={estimate[0]} id={idToChange} />
                                    </Box>

                                    <Box display='flex' gap='25px'>
                                        <Typography width='85px'>Created On</Typography>
                                        <Typography>{createdOn[0]}</Typography>
                                    </Box>

                                    {/* {admin || superAdmin
                                            ? <> */}
                                    {/* <Box display='flex' gap='25px'>
                                                    <Typography width='85px'>Assignee</Typography>
                                                    <AssignDeveloper idToChange={idToChange} assignees={selectedTicket[0].attributes.assignees} />
                                                </Box> */}

                                    <Box display='flex' justifyContent='space-between' alignItems='end'>
                                        <Box flex='1' display='flex' justifyContent='start'>
                                            <AssignDeveloper newTicket={false} setAssignees={undefined} idToChange={idToChange} assignees={selectedTicket[0]?.attributes?.assignees} main_tenant={selectedTicket[0]?.attributes?.main_tenant} />
                                        </Box>
                                        <Box display='flex' flex='1' justifyContent='center'>
                                            <EditEnvironment environment={selectedTicket[0]?.attributes?.environment} tenant={tenant[0]} fullId={ticketId[0]} idToChange={idToChange} />
                                        </Box>
                                        <Box display='flex' flex='1' justifyContent='end'>
                                            {url != 'undefined' ?
                                                <ViewAttachments url={url} images={images} name={data?.attributes?.ticket_id} />
                                                :
                                                ''
                                            }
                                            {/* <Attachments url={url} images={images} data={data?.attributes} /> */}
                                        </Box>
                                    </Box>

                                    {/* <Box display='flex' gap='25px'>
                                            <Box display='flex' flexDirection='column'>
                                                <Typography width='85px'>Estimate</Typography>
                                                <Typography mt='-5px' fontSize='12px'>(w/d/h/m)</Typography>
                                            </Box>
                                            {estimate[0] !== null ? <Typography>{estimate[0]}</Typography> : <EstimatePicker ticketId={selectedTicket[0].attributes.ticket_id} />}
                                        </Box> */}
                                    {/* </>
                                            : ''
                                        } */}
                                </Box>
                            </Box>

                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Box flex='1' display='flex' alignItems='start'>
                                    <EditType tenant={tenant[0]} fullId={ticketId[0]} idToChange={idToChange} type={type[0]} />
                                </Box>
                                <Box flex='1' display='flex' justifyContent='center'>
                                    <EditPriority tenant={tenant[0]} fullId={ticketId[0]} idToChange={idToChange} priority={priority[0]} />
                                </Box>
                                <Box flex='1' display='flex' justifyContent='end'>
                                    <EditStatus tenant={tenant[0]} fullId={ticketId[0]} assignees={assignees[0]} dueDate={dueDate[0]} branch={branch[0]} idToChange={idToChange} type={type[0]} status={status[0]} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <TicketForum selectedRowId={props.selectedRowId} data={selectedTicket[0]} />
                </Box>
            </Dialog >

            {/* <Dialog open={imageOpen} onClose={handleImageClose} maxWidth='md'>
                <DialogContent sx={{ padding: '0px' }}>
                    <img src={`http://localhost:1337${imageToOpen}`} key={imageToOpen} alt='' width='100%' height='100%' />
                </DialogContent>
            </Dialog> */}

        </>
    )
}

export default DesktopTicketForum
