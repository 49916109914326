import { Box } from "@mui/material"
import Sidebar from "../../components/Desktop/Sidebar"
import NewMeeting from "../../components/Meeting/NewMeeting"
import ScieverCalendar from "../../utils/ScieverCalendar"
import { useState } from "react"

const DesktopMeetingLayout = () => {

    const [englishDate, setEnglishDate] = useState<string>();
    const [nepaliDate, setNepaliDate] = useState<string>();

    return (
        <>
            {/* <DesktopHeader /> */}
            <Sidebar />
            <Box
                bgcolor='white'
                position='fixed'
                left='85px'
                right='0px'
                top='0px'
                bottom='0px'
                p='15px 20px'
                overflow='hidden'
                // sx={{ borderTopLeftRadius: '10px' }}

            >
                {/* <Typography variant='h6' fontWeight='bold'>
                    Meeting Minute
                </Typography> */}

                <Box display='flex' gap='15px' height='100%'>

                    <Box flex='1' height='100%'>
                        <ScieverCalendar height='100%' setEnglishDate={setEnglishDate} setNepaliDate={setNepaliDate} calendarId='ef914192d710e6f6194a4fabb872464fad034f52bb80bf60a80ecd4f259f1f25@group.calendar.google.com' />
                    </Box>

                    {(englishDate && nepaliDate) &&
                        <Box flex='1' height='100%'>
                            <NewMeeting englishDate={englishDate} nepaliDate={nepaliDate} />
                        </Box>
                    }

                </Box>
            </Box>
        </>
    )
}

export default DesktopMeetingLayout
