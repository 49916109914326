import { createAsyncThunk } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';
import { setRooms, setLoading, setError } from './roomSlice';

interface FetchRoomsByUserArgs {
  socket: Socket;
  userId: number;
}

export const fetchRoomsByUser = createAsyncThunk(
  'room/fetchRoomsByUser',
  async ({ socket, userId }: FetchRoomsByUserArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      if (socket) {
        console.log('UserID', userId);
        socket.emit('getRoomByUser', { userId: userId, page: 1 });

        // Consider using `on` if you expect multiple responses or switch back to `once` if it's one-time
        socket.on('getRoomByUsers', (response: any) => {
          if (response.error) {
            dispatch(setError(response.error));
          } else {
            const { data, total, page, limit } = response;
            dispatch(setRooms({ rooms: data, total, page, limit }));
          }
          dispatch(setLoading(false));
        });
      }
    } catch (error) {
      console.error('Error in fetching rooms by userId:', error);
      dispatch(setError('Failed to fetch rooms'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);
