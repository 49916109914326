import Redirect from '../../routes/Redirect';

const LoginGuard = ({ children }: { children: React.ReactNode }) => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    return <Redirect />;
  }
  return <>{children}</>;
};
export default LoginGuard;
