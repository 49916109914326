import React from 'react'
import BottomNavbar from '../../components/Mobile/BottomNavbar'
import MobileHeader from '../../components/Mobile/MobileHeader'

function MobileAttendanceLayout() {
    return (
        <div>
            <MobileHeader />
            <BottomNavbar />
        </div>
    )
}

export default MobileAttendanceLayout
