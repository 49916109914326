import { Novu } from '@novu/node';
import { tryCatchWrapperAsync } from './exception_wrapper';

export const novu = new Novu(process.env.REACT_APP_novuApiKey !== undefined ? process.env.REACT_APP_novuApiKey : '', {
    backendUrl: process.env.REACT_APP_novuBackendUrl
});

export const catchedTrigger = tryCatchWrapperAsync(novu, novu.trigger);
export const catchedSubscriber = tryCatchWrapperAsync(
    novu.subscribers,
    novu.subscribers.identify,
);
