import { useContext, useState } from 'react';
import { AttendanceUsersContext } from '../../pages/AttendancePage';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditUser from './EditUser';

const Users = () => {

    const { attendanceUsers } = useContext(AttendanceUsersContext);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const columns: GridColDef[] = [
        {
            field: 'sn',
            renderHeader: () => {
                return <Typography fontWeight='bold'>SN</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            editable: false,
            renderCell: (params) => page * pageSize + params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Name</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.name
        },
        {
            field: 'email',
            renderHeader: () => {
                return <Typography fontWeight='bold'>E-mail</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            sortable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.official_email
        },
        {
            field: 'title',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Title</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            renderCell: (rowData) => {
                if (rowData?.row?.attributes?.title === null) {
                    return '-'
                } else {
                    return rowData?.row?.attributes?.title
                }
            }
        },
        {
            field: 'position',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Position</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            renderCell: (rowData) => {
                if (rowData?.row?.attributes?.position === null) {
                    return '-'
                } else {
                    return rowData?.row?.attributes?.position
                }
            }
        },
        {
            field: 'contact',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Contact No.</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            sortable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.contact
        },
        {
            field: 'actions',
            renderHeader: () => {
                return (
                    <Typography fontWeight='bold'>Edit</Typography>
                );
            },
            headerAlign: 'center',
            align: 'center',
            flex: 2,
            editable: false,
            sortable: false,
            renderCell: (row) => {
                return <EditUser id={row?.row?.id} table={true} />
            },
        },
    ];

    return (
        <>
            <Box sx={{ bgcolor: 'white', height: '100%' }}>
                <DataGrid
                    rows={attendanceUsers}
                    columns={columns}
                    rowHeight={50}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                    pageSizeOptions={[25, 50, 75, 100]}
                    disableColumnMenu
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '0.4em',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
                        {
                            borderColor: 'darkgray',
                            borderWidth: '1px',
                        },
                    }}
                    getRowId={(row) => {
                        return row?.id
                    }}
                    disableRowSelectionOnClick
                    onPaginationModelChange={(row) => {
                        setPage(row.page);
                        setPageSize(row.pageSize);
                    }}
                />
            </Box>
        </>
    )
}

export default Users
