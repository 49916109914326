import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import { AttendanceBlock, CombinedReportContext } from '../../layout/Desktop/DesktopAttendanceLayout'
import { useContext, useState } from 'react'
import EditUser from './EditUser'
import { AttendanceUsersContext } from '../../pages/AttendancePage'
// import Info from './Info'
import Users from './Users'
import PersonIcon from '@mui/icons-material/Person'
import { CurrentUserContext } from '../../App'


const Details = () => {

    const { currentUser } = useContext(CurrentUserContext);

    const { attendanceUsers } = useContext(AttendanceUsersContext);

    const { combinedReport } = useContext(CombinedReportContext);

    const [open, setOpen] = useState(false)

    const sortedUsers = attendanceUsers?.slice()?.sort((a: { attributes: { name: string } }, b: { attributes: { name: string } }) => a.attributes.name.localeCompare(b.attributes.name));

    if (combinedReport) {
        return (
            <>
                <AttendanceBlock>
                    {/* <Info section='Details' /> */}

                    <Box display='flex' alignItems='center' gap='5px' ml='-3px' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                        <PersonIcon sx={{ width: '25px', height: '25px' }} />
                        <b>Employee Details</b>
                    </Box>

                    <Box display='flex' flexDirection='column' gap='10px' mt='10px' width='100%' justifyContent='space-between' sx={{ overflowY: 'scroll' }}>
                        {sortedUsers
                            ?.map((user: { attributes: { name: string, contact: string }, id: number }) => {
                                return (
                                    <Box key={user?.attributes?.name} display='flex' alignItems='center' justifyContent='space-between'>
                                        <Typography flex={2}>
                                            {user?.attributes?.name?.split(' ')[0]}
                                        </Typography>
                                        <Typography flex={2}>
                                            {user?.attributes?.contact?.split(' ')[1]?.replace('-', '')}
                                        </Typography>
                                        <Box flex={1} textAlign='end' mr='5px'>
                                            <EditUser table={true} id={user?.id} />
                                        </Box>
                                    </Box>
                                )
                            })}
                    </Box>
                </AttendanceBlock>

                <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
                    <DialogContent sx={{ height: '550px' }}>
                        <Users />
                    </DialogContent>
                </Dialog>
            </>
        )
    } else {
        return (
            <>
                <AttendanceBlock>
                    {/* <Info section='Details' /> */}

                    <EditUser id={currentUser?.id} table={false} />

                    <Box display='flex' width='100%' justifyContent='space-between' gap='5px'>
                        <Box display='flex' flexDirection='column' gap='5px' alignSelf='flex-start' mt='10px'>
                            <Typography fontSize='15px'>Name:</Typography>
                            <Typography fontSize='15px'>E-mail:</Typography>
                            <Typography fontSize='15px' sx={{ whiteSpace: 'nowrap' }}>Contact No.:</Typography>
                            <Typography fontSize='15px'>Address:</Typography>
                        </Box>

                        <Box display='flex' whiteSpace='nowrap' flexDirection='column' gap='5px' alignSelf='flex-start' mt='10px'>
                            <Typography fontSize='15px'>{currentUser?.attributes?.name ? currentUser?.attributes?.name : '-'}</Typography>
                            <Typography fontSize='15px'>{currentUser?.attributes?.official_email ? currentUser?.attributes?.official_email : '-'}</Typography>
                            <Typography fontSize='15px'>{currentUser?.attributes?.contact ? currentUser?.attributes?.contact : '-'}</Typography>
                            <Typography fontSize='15px'>{currentUser?.attributes?.address ? currentUser?.attributes?.address : '-'}</Typography>
                        </Box>
                    </Box>
                </AttendanceBlock>
            </>
        )
    }
}

export default Details
