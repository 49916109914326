import { Box, Toolbar, ImageList, ImageListItem, Typography, useTheme, FormControl, Select, MenuItem } from '@mui/material';
import logo from '../../assets/images/sciever_logo_png.png';
import isAdmin from '../../hooks/isAdmin';
import { useContext } from 'react';
import { AllTenantsContext, UserDataContext } from '../../routes/Main';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { RefreshContext, TenantContext } from '../../App';
import isDeveloper from '../../hooks/isDeveloper';
import { useSearchParams } from 'react-router-dom';

const MobileHeader = () => {

    const { userData } = useContext(UserDataContext);

    const { tenant, setTenant } = useContext(TenantContext);

    const { allTenants } = useContext(AllTenantsContext);

    const { refresh, setRefresh } = useContext(RefreshContext);

    const toggleRefresh = () => {
        refresh ? setRefresh(false) : setRefresh(true)
    }

    const admin = isAdmin();

    const superAdmin = isSuperAdmin();

    const developer = isDeveloper();

    const theme = useTheme();

    const [searchParams, setQueryParams] = useSearchParams();

    return (
        <>
            <Box role="heading" position='fixed' zIndex='2' display='flex' alignItems='center' justifyContent='space-between' pr='16px' sx={{ backgroundColor: theme.palette.background.default, width: '100%', height: '81px', top: '0px', left: '0px' }}>
                <Toolbar sx={{ position: 'sticky' }}>
                    <ImageList
                        sx={{
                            width: '50px',
                            height: '50px',
                            minWidth: '50px',
                            marginRight: 2,
                            overflow: 'hidden',
                        }}
                        cols={1}
                    >
                        <ImageListItem>
                            <img src={logo} alt='Sciever Inc. Logo' />
                        </ImageListItem>
                    </ImageList>

                    <Typography
                        variant='h6'
                        noWrap
                        component='div'
                        sx={{ color: 'black', fontWeight: 'bold' }}
                    >
                        Hello, {userData !== undefined && userData.data.name.split(' ')[0]}!
                    </Typography>
                </Toolbar>

                {(superAdmin || admin || developer) &&
                    <Box>
                        <FormControl sx={{ m: 0.3, minWidth: '70px' }} >
                            <Select
                                value={tenant}
                                onChange={event => {
                                    setTenant(event.target.value);
                                    toggleRefresh();
                                    const queryParams = Object.fromEntries(searchParams);
                                    const newParams = { ...queryParams }
                                    delete newParams.assignees
                                    setQueryParams(newParams)
                                }}
                                sx={{ height: '25px', fontSize: '13px' }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {developer ? userData?.data?.organizations?.filter((tenant: any) => tenant?.sts_link === 1)?.map((tenant: any) => {
                                    return (
                                        <MenuItem value={tenant?.alias} key={tenant?.alias}>
                                            {tenant?.ticket?.toUpperCase()}
                                        </MenuItem>
                                    )
                                })
                                    :
                                    allTenants.data.tenants.filter((tenant: any) => tenant.organization.sts_link === 1).map((tenant: { organization: { alias: string, ticket_id: string }, id: string }) => {
                                        return <MenuItem value={tenant.organization.alias} key={tenant.id}>{tenant.organization.ticket_id.slice(0, 3).toUpperCase()}</MenuItem>
                                    })}
                            </Select>
                        </FormControl>
                    </Box>
                }
            </Box>
        </>
    )
}

export default MobileHeader
