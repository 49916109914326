import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  FormControl,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isMobile from '../hooks/isMobile';
import { UserDataContext, UserListContext } from '../routes/Main';
import { IUserData } from '../pages/HomePage';
import isCustomer from '../hooks/isCustomer';
import { TenantContext } from '../App';
import isDeveloper from '../hooks/isDeveloper';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CreateTicket from './CreateTicket';
import SelectTenant from './SelectTenant';
import Bookmarks from './Bookmarks';
import logo from '../assets/images/sciever_logo_png.png';

const Filter = () => {

  const customer = isCustomer();
  const { userList } = useContext(UserListContext);
  const { userData } = useContext(UserDataContext);
  const { tenant } = useContext(TenantContext);
  const [searchParams, setQueryParams] = useSearchParams();
  const [filterAssignee, setFilterAssignee] = useState('');
  const [filterPriority, setFilterPriority] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const developer = isDeveloper();
  const navigate = useNavigate();

  const sortAssignee = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    setQueryParams({
      ...queryParams,
      assignees: event.target.value as string,
    });
  };

  const sortType = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    setQueryParams({
      ...queryParams,
      type: event.target.value as string,
    });
  };

  const sortPriority = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    setQueryParams({
      ...queryParams,
      priority: event.target.value as string,
    });
  };

  const sortStatus = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    setQueryParams({
      ...queryParams,
      status: event.target.value as string,
    });
  };

  const clearFilter = () => {
    setQueryParams({});
  };

  const mobile = isMobile();

  useEffect(() => {
    setFilterPriority(searchParams.get('priority') ?? '');
    setFilterType(searchParams.get('type') ?? '');
    setFilterStatus(searchParams.get('status') ?? '');
    setFilterAssignee(searchParams.get('assignees') ?? '');
  }, [searchParams]);

  return (
    <>
      {mobile ? (
        <>
          <Box
            display='flex'
            gap='10px'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
          >
            <Box display='flex'>
              <FormControl
                size='small'
                sx={{
                  ml: '-10px',
                  mr: '-20px'
                }}
              >
                <InputLabel>Assign.</InputLabel>
                <Select
                  label='Assignee'
                  value={filterAssignee}
                  onChange={sortAssignee}
                  sx={{
                    width: '95px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                >
                  {!customer ?
                    (
                      tenant !== 'all'
                        ?
                        (
                          userList?.data?.users?.users?.filter((user: IUserData) => (user?.roles[0]?.name === 'dev' && user?.organizations?.filter((organization: { alias: string }) => organization?.alias === tenant)?.length > 0) || (user?.roles[0]?.name === 'cus' && user?.organizations[0]?.alias === tenant)).map((user: IUserData) => {
                            return (
                              <MenuItem key={user.name} value={user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name}>{user.name}</MenuItem>
                            )
                          })
                        )
                        :
                        (
                          developer
                            ?
                            (
                              userList?.data?.users?.users?.filter((user: IUserData) => (user?.roles[0]?.name === 'dev') || (user?.roles[0]?.name === 'cus') && userData?.data?.organizations?.filter((organization: { alias: string }) => organization?.alias === user?.organizations[0]?.alias)?.length > 0).map((user: IUserData) => {
                                return (
                                  <MenuItem key={user.name} value={user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name}>{user.name}</MenuItem>
                                )
                              })
                            )
                            :
                            (
                              userList?.data?.users?.users?.filter((user: IUserData) => user?.roles[0]?.name === 'dev' || user?.roles[0]?.name === 'cus').map((user: IUserData) => {
                                return (
                                  <MenuItem key={user.name} value={user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name}>{user.name}</MenuItem>
                                )
                              })
                            )
                        )
                    )
                    :
                    (
                      userList?.data?.users?.users?.filter((user: IUserData) => user?.roles[0]?.name === 'cus' && user?.organizations[0]?.alias === userData?.data?.organizations[0]?.alias).map((user: IUserData) => {
                        return (
                          <MenuItem key={user.name} value={`customer${user.name}`}>{user.name}</MenuItem>
                        )
                      })
                    )
                  }
                </Select>
              </FormControl>

              <FormControl
                size='small'
                sx={{
                  mr: '-20px'
                }}
              >
                <InputLabel>Type</InputLabel>
                <Select
                  label='Type'
                  value={filterType}
                  onChange={sortType}
                  sx={{
                    width: '90px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value='bug'>Bug</MenuItem>
                  <MenuItem value='task'>Task</MenuItem>
                  <MenuItem value='feature'>Feature</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                size='small'
                sx={{
                  mr: '-20px',
                  // '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  //     borderWidth: '0px'
                  // },
                  // '& .css-4qc58x-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  //     borderWidth: '0px'
                  // },
                  // '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
                  //     mt: '2px'
                  // }
                }}
              >
                <InputLabel>Prior.</InputLabel>
                <Select
                  label='Priority'
                  value={filterPriority}
                  onChange={sortPriority}
                  sx={{
                    width: '90px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value='highest'>HIGHEST</MenuItem>
                  <MenuItem value='high'>HIGH</MenuItem>
                  <MenuItem value='medium'>MEDIUM</MenuItem>
                  <MenuItem value='low'>LOW</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                size='small'
                sx={{
                  mr: '-20px',
                  // '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  //     borderWidth: '0px'
                  // },
                  // '& .css-1kwd89o-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  //     borderWidth: '0px'
                  // },
                  // '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
                  //     mt: '2px'
                  // }
                }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  label='Status'
                  value={filterStatus}
                  onChange={sortStatus}
                  sx={{
                    width: '90px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value='backlog'>BACKLOG</MenuItem>
                  <MenuItem value='on hold'>ON HOLD</MenuItem>
                  <MenuItem value='todo'>TODO</MenuItem>
                  <MenuItem value='in progress'>IN PROGRESS</MenuItem>
                  <MenuItem value='pr'>PR</MenuItem>
                  <MenuItem value='pr done' disabled>PR DONE</MenuItem>
                  <MenuItem value='done'>DONE</MenuItem>
                  <MenuItem value='redo'>REDO</MenuItem>
                  <MenuItem value='uat'>UAT</MenuItem>
                  <MenuItem value='production'>PRODUCTION</MenuItem>
                  <MenuItem value='closed'>CLOSED</MenuItem>
                  <MenuItem value='canceled'>CANCELED</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Button
              variant='contained'
              color='error'
              onClick={clearFilter}
              sx={{ minWidth: '22px', height: '22px', p: '0px' }}
            >
              <ClearIcon sx={{ width: '20px', height: '20px' }} />
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mt='-5px'
            pb='7px'
            sx={{ borderBottom: 'solid', borderColor: 'darkgray', borderWidth: '1px' }}
          >
            <Box display='flex' alignItems='center' gap='10px' mb='5px'>
              <ImageList
                sx={{
                  width: '45px',
                  height: '45px',
                  overflow: 'hidden',
                  m: '0px'
                }}
                cols={1}
              >
                <ImageListItem>
                  <img src={logo} alt='Sciever Inc. Logo' style={{ width: '45px', height: '45px' }} />
                </ImageListItem>
              </ImageList>

              <CreateTicket />

              <ToggleButtonGroup
                orientation="horizontal"
                value='list'
                size='small'
                sx={{ height: '35px' }}
              >
                <ToggleButton value="module" onClick={() => navigate('/overview')}>
                  <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="list">
                  <ViewListIcon />
                </ToggleButton>
              </ToggleButtonGroup>

              <SelectTenant />

              <Typography variant='h6' fontWeight='bold'>
                Tickets
              </Typography>

              <Bookmarks />
            </Box>
            <Box
              display='flex'
              gap='10px'
              justifyContent='end'
              alignItems='end'
              mt='-15px'
              mb='10px'
            >
              <FormControl size='small' variant='standard'>
                <InputLabel>Assignee</InputLabel>
                <Select
                  label='Assignee'
                  value={filterAssignee}
                  onChange={sortAssignee}
                  sx={{
                    width: '110px',
                  }}
                >
                  {!customer ?
                    (
                      tenant !== 'all'
                        ?
                        (
                          userList?.data?.users?.users?.filter((user: IUserData) => ((user?.roles[0]?.name === 'dev' || user?.roles[0]?.name === 'int') && user?.organizations?.filter((organization: { alias: string }) => organization?.alias === tenant)?.length > 0) || (user?.roles[0]?.name === 'cus' && user?.organizations[0]?.alias === tenant)).map((user: IUserData) => {
                            return (
                              <MenuItem key={user.name} value={user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name}>{user.name}</MenuItem>
                            )
                          })
                        )
                        :
                        (
                          developer
                            ?
                            (
                              userList?.data?.users?.users?.filter((user: IUserData) => (user?.roles[0]?.name === 'dev') || (user?.roles[0]?.name === 'int') || (user?.roles[0]?.name === 'cus') && userData?.data?.organizations?.filter((organization: { alias: string }) => organization?.alias === user?.organizations[0]?.alias)?.length > 0).map((user: IUserData) => {
                                return (
                                  <MenuItem key={user.name} value={user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name}>{user.name}</MenuItem>
                                )
                              })
                            )
                            :
                            (
                              userList?.data?.users?.users?.filter((user: IUserData) => user?.roles[0]?.name === 'dev' || user?.roles[0]?.name === 'int' || user?.roles[0]?.name === 'cus').map((user: IUserData) => {
                                return (
                                  <MenuItem key={user.name} value={user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name}>{user.name}</MenuItem>
                                )
                              })
                            )
                        )
                    )
                    :
                    (
                      userList?.data?.users?.users?.filter((user: IUserData) => user?.roles[0]?.name === 'cus' && user?.organizations[0]?.alias === userData?.data?.organizations[0]?.alias).map((user: IUserData) => {
                        return (
                          <MenuItem key={user.name} value={`customer${user.name}`}>{user.name}</MenuItem>
                        )
                      })
                    )
                  }
                </Select>
              </FormControl>

              <FormControl size='small' variant='standard'>
                <InputLabel>Type</InputLabel>
                <Select
                  label='Type'
                  value={filterType}
                  onChange={sortType}
                  sx={{
                    width: '110px',
                  }}
                >
                  <MenuItem value='bug'>BUG</MenuItem>
                  <MenuItem value='task'>TASK</MenuItem>
                  <MenuItem value='feature'>FEATURE</MenuItem>
                </Select>
              </FormControl>

              <FormControl size='small' variant='standard'>
                <InputLabel>Priority</InputLabel>
                <Select
                  label='Priority'
                  value={filterPriority}
                  onChange={sortPriority}
                  sx={{ width: '110px' }}
                >
                  <MenuItem value='highest'>HIGHEST</MenuItem>
                  <MenuItem value='high'>HIGH</MenuItem>
                  <MenuItem value='medium'>MEDIUM</MenuItem>
                  <MenuItem value='low'>LOW</MenuItem>
                </Select>
              </FormControl>

              <FormControl size='small' variant='standard'>
                <InputLabel>Status</InputLabel>
                <Select
                  label='Status'
                  value={filterStatus}
                  onChange={sortStatus}
                  sx={{ width: '110px' }}
                >
                  <MenuItem value='backlog'>BACKLOG</MenuItem>
                  <MenuItem value='on hold'>ON HOLD</MenuItem>
                  <MenuItem value='todo'>TODO</MenuItem>
                  <MenuItem value='in progress'>IN PROGRESS</MenuItem>
                  <MenuItem value='pr'>PR</MenuItem>
                  <MenuItem value='pr done' disabled>PR DONE</MenuItem>
                  <MenuItem value='done'>DONE</MenuItem>
                  <MenuItem value='redo'>REDO</MenuItem>
                  <MenuItem value='uat'>UAT</MenuItem>
                  <MenuItem value='production'>PRODUCTION</MenuItem>
                  <MenuItem value='closed'>CLOSED</MenuItem>
                  <MenuItem value='canceled'>CANCELED</MenuItem>
                </Select>
              </FormControl>

              <Button variant='contained' size='small' color='error' onClick={clearFilter}>
                Clear
              </Button>
            </Box>
          </Box>
        </>
      )
      }
    </>
  );
};

export default Filter;
