import { Box, Button, FormControl, MenuItem, Select, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import AssignDeveloper from "../AssignDeveloper";
import { TenantContext } from "../../App";
import axios from "axios";
import { failed, success } from "../../hooks/useToast";
import { gapi } from 'gapi-script'

const NewMeeting = (
    props: {
        englishDate: string,
        nepaliDate: string
    }
) => {

    const { tenant } = useContext(TenantContext);

    const [title, setTitle] = useState<string>();
    const [attendees, setAttendees] = useState<string[] | null>(null);
    const [actionItems, setActionItems] = useState<string>();
    const [decisions, setDecisions] = useState<string>();
    const [rollingItems, setRollingItems] = useState<string>();

    const [idToEdit, setIdToEdit] = useState<number>(0);

    // const CLIENT_ID = process.env.REACT_APP_calendarClientId
    const CLIENT_ID = '1028944999419-hvdpe0haq6u5uuh4d8a8kes02voltq1l.apps.googleusercontent.com'
    // const API_KEY = process.env.REACT_APP_eventApiKey
    const API_KEY = 'AIzaSyD6YhqpL9Kw-7S73gyYaul_IURq0cNZVos'
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    const SCOPES = "https://www.googleapis.com/auth/calendar.events"

    const addCalendarEvent = (startTime: string, title: string | undefined) => {

        gapi.load('client:auth2', () => {
            gapi.auth2.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })

            gapi.client.load('calendar', 'v3')
            const timeZone = "Asia/Kathmandu";
            const duration = '24:00:00';

            const startDate = new Date(startTime);
            const msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
            const endDate = new Date(startDate.getTime() + msDuration);
            const isoStartDate = new Date(startDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000 + 20700000).toISOString().split(".")[0];
            const isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset()) * 60 * 1000).toISOString().split(".")[0];

            gapi.auth2.getAuthInstance().signIn()
                .then(() => {
                    const event = {
                        'summary': title,
                        'start': {
                            'dateTime': isoStartDate,
                            'timeZone': timeZone
                        },
                        'end': {
                            'dateTime': isoEndDate,
                            'timeZone': timeZone
                        },
                        'recurrence': [
                            'RRULE:FREQ=DAILY;COUNT=1'
                        ]
                    }

                    const request = gapi.client.calendar.events.insert({
                        'calendarId': process.env.REACT_APP_calendarId,
                        'resource': event,
                    })

                    request.execute((event: any) => {
                        // console.log(event)
                        window.open(event.htmlLink)
                    })
                })
        })
    }

    const [allMeetings, setAllMeetings] = useState([]);

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const getAllMeetings = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/meetings?filters[date][$eq]=${props.englishDate}&populate=*`, {
            headers: headers
        })
            .then(res => setAllMeetings(res.data.data))
            .catch(() => failed())
    }

    const getMeeting = () => {
        if (idToEdit !== 0) {
            axios.get(`${process.env.REACT_APP_strapiUrl}/meetings/${idToEdit}?populate=*`, {
                headers: headers
            })
                .then(res => {
                    setTitle(res.data.data.attributes.title);
                    setAttendees(res.data.data.attributes.attendees);
                    setActionItems(res.data.data.attributes.action_items);
                    setDecisions(res.data.data.attributes.decisions);
                    setRollingItems(res.data.data.attributes.rolling_items);
                })
                .catch(() => failed())
        } else {
            setTitle(undefined);
            setAttendees(null);
            setActionItems(undefined);
            setDecisions(undefined);
            setRollingItems(undefined);
        }
    }

    useEffect(() => {
        getAllMeetings()
    }, [props.englishDate, props.nepaliDate, tenant])

    useEffect(() => {
        getMeeting()
    }, [idToEdit])

    useEffect(() => {
        setIdToEdit(0);
        setTitle(undefined);
        setAttendees(null);
        setActionItems(undefined);
        setDecisions(undefined);
        setRollingItems(undefined);
    }, [props?.nepaliDate, props?.nepaliDate, tenant])

    const createNewMeeting = () => {
        const data = {
            data: {
                tenant: tenant,
                date: props.englishDate,
                title: title,
                attendees: attendees,
                decisions: decisions,
                action_items: actionItems,
                rolling_items: rollingItems,
            }
        }
        axios.post(`${process.env.REACT_APP_strapiUrl}/meetings`, data, {
            headers: headers
        })
            .then(() => {
                success();
                setIdToEdit(0);
                setTitle(undefined);
                setAttendees(null);
                setActionItems(undefined);
                setDecisions(undefined);
                setRollingItems(undefined);
                addCalendarEvent(new Date(props.englishDate).toISOString(), title)
                getAllMeetings();
            })
            .catch(() => {
                failed();
            })
    }

    const editMeeting = () => {
        const data = {
            data: {
                tenant: tenant,
                date: props.englishDate,
                title: title,
                attendees: attendees,
                decisions: decisions,
                action_items: actionItems,
                rolling_items: rollingItems,
            }
        }
        axios.put(`${process.env.REACT_APP_strapiUrl}/meetings/${idToEdit}`, data, {
            headers: headers
        })
            .then(() => {
                success();
                getAllMeetings();
            })
            .catch(() => {
                failed();
            })
    }

    return (
        <Box
            height='100%'
            border='1px dashed gray'
            borderRadius='10px'
            borderColor='gray'
            display='flex'
            flexDirection='column'
            padding='15px'
            gap='10px'
        >
            <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
                <Box display='flex' alignItems='center' gap='10px'>
                    <Box>{props.englishDate} / {props.nepaliDate}</Box>
                    {allMeetings?.length > 0 &&
                        <FormControl size='small'>
                            <Select
                                value={idToEdit}
                                onChange={e => setIdToEdit(e.target.value as number)}
                                sx={{ height: '30px', pb: '1px' }}
                            >
                                <MenuItem value={0}>New</MenuItem>
                                {allMeetings?.map((meeting: { attributes: { title: string }, id: number }) => {
                                    return <MenuItem key={meeting.attributes.title} value={meeting.id}>{meeting.attributes.title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Button onClick={() => idToEdit === 0 ? createNewMeeting() : editMeeting()} variant="contained" color='info' size='small' sx={{ width: '50px', textTransform: 'none' }}>Save</Button>
            </Box>
            <Box display='flex' gap='10px' mt='5px' alignItems='center' width='100%'>
                Title:
                <TextField
                    size='small'
                    value={title || ''}
                    onChange={e => setTitle(e.target.value)}
                    fullWidth
                />
            </Box>
            <Box display='flex' gap='10px' mt='5px'>
                Attendees:
                <AssignDeveloper idToChange='' assignees={attendees} setAssignees={setAttendees} main_tenant={tenant} newTicket={true} attendees={true} />
            </Box>
            <Box display='flex'>
                <Box flex='1' display='flex' flexDirection='column' gap='5px' p='5px' alignItems='center'>
                    <Box>Action Items</Box>
                    <Box>
                        <TextField
                            multiline
                            value={actionItems || ''}
                            inputProps={{ style: { height: 'calc(100vh - 350px)' } }}
                            onChange={e => setActionItems(e.target.value)}
                            sx={{
                                border: 'dashed',
                                borderWidth: '1px',
                                borderRadius: '10px',
                                borderColor: 'gray',
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Box flex='1' display='flex' flexDirection='column' gap='5px' p='5px' alignItems='center'>
                    <Box>Decisions</Box>
                    <Box>
                        <TextField
                            multiline
                            inputProps={{ style: { height: 'calc(100vh - 350px)' } }}
                            value={decisions || ''}
                            onChange={e => setDecisions(e.target.value)}
                            sx={{
                                border: 'dashed',
                                borderWidth: '1px',
                                borderRadius: '10px',
                                borderColor: 'gray',
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Box flex='1' display='flex' flexDirection='column' gap='5px' p='5px' alignItems='center'>
                    <Box>Rolling Items</Box>
                    <Box>
                        <TextField
                            multiline
                            inputProps={{ style: { height: 'calc(100vh - 350px)' } }}
                            value={rollingItems || ''}
                            onChange={e => setRollingItems(e.target.value)}
                            sx={{
                                border: 'dashed',
                                borderWidth: '1px',
                                borderRadius: '10px',
                                borderColor: 'gray',
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default NewMeeting
