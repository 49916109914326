import DesktopMeetingLayout from "../layout/Desktop/DesktopMeetingLayout"

const MeetingPage = () => {
    return (
        <>
            <DesktopMeetingLayout />
        </>
    )
}

export default MeetingPage
