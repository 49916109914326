import ListItemButton from '@mui/material/ListItemButton';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { NavLink, useLocation } from 'react-router-dom';
import { useState, useContext } from 'react';
import { Box, Popover, Button, Dialog, Typography } from "@mui/material";
import { CurrentUserContext } from '../../App';
import axios from 'axios';
import DesktopNotifications from './DesktopNotifications';
import { UserDataContext } from '../../routes/Main';
import isAdmin from '../../hooks/isAdmin';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import isDeveloper from '../../hooks/isDeveloper';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import isAttendanceUser from '../../hooks/isAttendanceUser';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import ForumIcon from '@mui/icons-material/Forum';
import PersonIcon from '@mui/icons-material/Person';

const Sidebar = () => {

  const location = useLocation();

  const admin = isAdmin();

  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const attendanceUser = isAttendanceUser();

  const { userData } = useContext(UserDataContext);

  const { setCurrentUser } = useContext(CurrentUserContext);

  const [accountAnchorEl, setAccountAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleAccountClick = (event: any) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountPopoverClose = () => {
    setAccountAnchorEl(null);
  };

  const accountOpen = Boolean(accountAnchorEl);

  const [logoutOpen, setLogoutOpen] = useState(false);

  const handleLogoutClose = () => {
    setLogoutOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    window.location.href = process.env.REACT_APP_sisUrl !== undefined ? process.env.REACT_APP_sisUrl : '';
  };

  const getCurrentAttendanceUser = () => {
    axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users?pagination[pageSize]=99`, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_token}`,
      }
    })
      .then(res => {
        const filter = res?.data?.data?.filter((user: { attributes: { sis_id: string } }) => user?.attributes?.sis_id == userData?.data?.id);
        setCurrentUser(filter[0]);
        return null;
      })
  }

  const attendanceUserOnly = (userData?.data?.roles[0]?.name !== 'sa' && userData?.data?.organizations?.length === 1 && userData?.data?.organizations[0]?.alias === 'sciever') ? true : false;

  return (
    <>
      <Box display='flex' flexDirection='column' alignItems='center' width='85px' mt='8px'>
        {attendanceUserOnly ?
          <>
            {attendanceUser &&
              <NavLink id='attendance' onClick={() => getCurrentAttendanceUser()} to={'/attendance'}>
                {/* <ListItem disablePadding sx={{ width: '85px' }}> */}
                <ListItemButton sx={{ width: '85px' }} selected={(location.pathname === '/attendance' || location.pathname === '/attendance-users') ? true : false}>
                  <CalendarMonthIcon sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black' }} />
                </ListItemButton>
                {/* </ListItem> */}
              </NavLink>
            }
          </>
          :
          <>
            {attendanceUser &&
              <NavLink id='attendance' onClick={() => getCurrentAttendanceUser()} to={'/attendance'}>
                {/* <ListItem disablePadding sx={{ width: '85px' }}> */}
                <ListItemButton sx={{ width: '85px' }} selected={(location.pathname === '/attendance' || location.pathname === '/attendance-users') ? true : false}>
                  <GroupsIcon sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black' }} />
                </ListItemButton>
                {/* </ListItem> */}
              </NavLink>
            }

            <NavLink id='home_page' to='/overview' onClick={() => {
              sessionStorage.setItem('filter', location.search)
              getCurrentAttendanceUser();
            }}>
              <ListItemButton sx={{
                margin: 0,
                width: '85px'
              }}

                selected={(location.pathname === '/overview' || location.pathname === '/tickets') ? true : false}>
                <ConfirmationNumberIcon sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black' }} />
              </ListItemButton>
            </NavLink>

            {/* <NavLink id='tickets_page' onClick={() => getCurrentAttendanceUser()} to={`/tickets${sessionStorage.getItem('filter') !== null ? sessionStorage.getItem('filter') : ''}`}>
              <ListItemButton sx={{ width: '85px' }} selected={location.pathname === '/tickets' ? true : false}>
                <ConfirmationNumberIcon sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black' }} />
              </ListItemButton>
            </NavLink> */}

            {/* <NavLink id='create_ticket' onClick={() => getCurrentAttendanceUser()} to={location.pathname + location.search}>
              <ListItemButton sx={{ width: '85px' }} onClick={handleClickOpen}>
                <AddCircleIcon sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black' }} />
              </ListItemButton>
            </NavLink> */}

            {(admin || superAdmin || developer) &&
              <NavLink id='meeting' onClick={() => getCurrentAttendanceUser()} to={'/meeting'}>
                <ListItemButton sx={{ width: '85px' }} selected={(location.pathname === '/meeting') ? true : false}>
                  <CalendarMonthIcon sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black' }} />
                </ListItemButton>
              </NavLink>
            }

            <NavLink id='chatroom' onClick={() => getCurrentAttendanceUser()} to={'/chatroom'}>
              <ListItemButton sx={{ width: '85px' }} selected={(location.pathname === '/chatroom') ? true : false}>
                <ForumIcon sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black' }} />
              </ListItemButton>
            </NavLink>

            <DesktopNotifications />

            <Button onClick={handleAccountClick} sx={accountOpen ? { width: '85px', height: '60px', borderRadius: '0px', backgroundColor: 'rgba(31, 28, 46, 0.08)' } : { width: '85px', height: '60px', borderRadius: '0px' }}>
              <AccountBoxIcon sx={{ width: '35px', height: '35px', mx: 'auto', color: 'black' }} />
            </Button>
          </>
        }

      </Box>

      <Popover
        open={accountOpen}
        anchorEl={accountAnchorEl}
        onClose={handleAccountPopoverClose}
        anchorOrigin={{
          vertical: 30,
          horizontal: 70,
        }}
      >
        <Box display='flex' flexDirection='column' p='5px 0px'>
          <Button sx={{ display: 'flex', gap: '5px', p: '5px 10px', cursor: 'not-allowed', justifyContent: 'start' }}><PersonIcon />Account</Button>
          <Button sx={{ display: 'flex', gap: '5px', p: '5px 10px', justifyContent: 'start' }} onClick={() => setLogoutOpen(true)}><LogoutIcon sx={{ width: '22px', ml: '2px' }} />Logout</Button>
        </Box>
      </Popover>

      <Dialog open={logoutOpen} onClose={handleLogoutClose} maxWidth='sm'>
        <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
          <Box display='flex' gap='3px' pr='2px'>
            <LogoutIcon />
            <Typography>Are you sure?</Typography>
          </Box>
          <Box display='flex' gap='10px'>
            <Button size='small' variant='contained' color='error' onClick={handleLogout}>
              Yes
            </Button>
            <Button size='small' variant='outlined' onClick={handleLogoutClose}>
              No
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Sidebar;
