import React, { useState, useContext } from 'react';
import Badge from '@mui/material/Badge';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Avatar,
  Box,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import { ChatRoomUserDataContext } from '../../App';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CustomTabPanel } from '../../components/Attendance/Report';
import ListItemButton from '@mui/material/ListItemButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import isMobile from '../../hooks/isMobile';
import { UserData } from '../../store/constants/constants';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}));

const Setting = () => {
  const [isMuted, setIsMuted] = useState(false);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  const mobileView = isMobile();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { chatRoomUserData } = useContext(ChatRoomUserDataContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        sx={{
          color: 'black',
          width: mobileView ? '20px' : '30px',
          height: mobileView ? '20px' : '30px',
        }}
        onClick={handleClickOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm' // Set a maxWidth for better centering
        sx={{
          '& .MuiDialog-paper': {
            margin: 'auto',
            height: '85vh', // Adjust the height to avoid overflow
            width: '470px',
          },
        }}
      >
        <DialogTitle>
          <IconButton sx={{ position: 'absolute', top: '0', right: '0' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Box display='flex' gap='20px'>
            <Box display='flex' alignItems='center' gap='5px'>
              <StyledBadge
                sx={{
                  '& .MuiBadge-badge': {
                    color: '#44b700',
                    backgroundColor: '#44b700',
                    width: '10px',
                    height: '10px',
                  },
                }}
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant='dot'
              >
                <Avatar
                  alt='John'
                  src='hgjsd.jpg'
                  sx={{
                    width: '50px',
                    height: '50px',
                  }}
                />
              </StyledBadge>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='h5' fontWeight='bolder'>
                John Doe
              </Typography>
              <Typography variant='body1'>Active Now</Typography>
              {/* <EditIcon /> */}
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab
                label='Media'
                sx={{
                  width: '33%',
                  color: value === 0 ? 'black' : 'lightgray',
                  marginRight: '10px',
                }}
                disableRipple
              />
              <Tab
                label='Files'
                sx={{
                  width: '33%',
                  color: value === 1 ? 'black' : 'lightgray',
                  marginRight: '10px',
                }}
                disableRipple
              />
              <Tab
                label='Links'
                sx={{ width: '33%', color: value === 2 ? 'black' : 'lightgray' }}
                disableRipple
              />
            </Tabs>
          </Box>
        </DialogTitle>

        <DialogContent>
          {/* media */}
          <CustomTabPanel value={value} index={0}>
            <Box
              display='grid'
              gridTemplateColumns='repeat(3, 1fr)'
              gap='10px'
              sx={{ overflow: 'hidden' }}
            >
              {chatRoomUserData?.map((user: UserData) => {
                return <img key={user.userId} src={user.profilePictureURL} />;
              })}
            </Box>
          </CustomTabPanel>

          {/* files */}

          <CustomTabPanel value={value} index={1}>
            <ListItem
              button
              sx={{
                '&:hover': {
                  backgroundColor: 'grey.300', // Darker shade from theme palette
                },
              }}
            >
              <ListItemIcon>
                <DescriptionIcon sx={{ fontSize: 40 }} />
              </ListItemIcon>
              <ListItemText primary='Intern Propsal Docs' secondary='145 KB' />
            </ListItem>
          </CustomTabPanel>

          {/* links */}
          <CustomTabPanel value={value} index={2}>
            <ListItem
              sx={{
                '&:hover': {
                  backgroundColor: 'grey.300', // Darker shade from theme palette
                },
              }}
            >
              <ListItemIcon>
                <DescriptionIcon sx={{ fontSize: 40 }} />
              </ListItemIcon>
              <ListItemText primary='Urls' secondary='145 KB' />
            </ListItem>
          </CustomTabPanel>
        </DialogContent>
        <List>
          <ListItemButton onClick={handleMuteToggle} sx={{ px: 3 }}>
            <ListItemText primary={isMuted ? 'Unmute Notification' : 'Mute Notification'} />
            {isMuted ? <NotificationsOffIcon /> : <NotificationsIcon />}
          </ListItemButton>
        </List>
      </Dialog>
    </>
  );
};

export default Setting;
