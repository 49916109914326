import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useState, useContext, useEffect } from 'react';
import { TextField, Box, Select, MenuItem, SelectChangeEvent, Dialog, DialogActions, DialogContent, InputLabel, FormControl, Button, Typography, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CurrentUserContext, TenantContext } from '../App';
import axios from 'axios';
import { AllTenantsContext, UserDataContext, UserListContext } from '../routes/Main';
import isAdmin from '../hooks/isAdmin';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { success, failed } from '../hooks/useToast';
import { catchedTrigger } from '../utils/novu';
import { DataContext } from '../pages/TicketsPage';
import { IUserData, TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import AssignDeveloper from './AssignDeveloper';
import { recordLog } from '../services/recordLog';
import { getRelatedTickets } from '../services/relatedTickets';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const CreateTicket = () => {

    const admin = isAdmin();

    const superAdmin = isSuperAdmin();

    const developer = isDeveloper();

    const { userData } = useContext(UserDataContext);

    const { currentUser } = useContext(CurrentUserContext);

    const { tenant, setTenant } = useContext(TenantContext);

    const { allTenants } = useContext(AllTenantsContext);

    const { fetchTickets } = useContext(DataContext);

    const { fetchTicketsHomePage } = useContext(TicketListContext);

    const [open, setOpen] = useState(false);

    const [type, setType] = useState('task');
    const [priority, setPriority] = useState('high');
    const [environment, setEnvironment] = useState('Sandbox');
    const [description, setDescription] = useState('');
    const [assignees, setAssignees] = useState<string[] | null>(null);
    const [files, setFiles] = useState<any>([])

    const [ticketId, setTicketId] = useState('')

    const [createTicketTenant, setCreateTicketTenant] = useState<string>();

    useEffect(() => {
        (admin || superAdmin || developer)
            ?
            setCreateTicketTenant(tenant === 'all' ? null : tenant)
            :
            setCreateTicketTenant(userData?.data?.organizations[0]?.alias)
    }, [tenant, userData, admin, superAdmin, developer])

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };

    const handlePriorityChange = (event: SelectChangeEvent) => {
        setPriority(event.target.value as string);
    };

    const handleEnvironmentChange = (event: SelectChangeEvent) => {
        setEnvironment(event.target.value as string);
    };

    useEffect(() => {
        if (superAdmin || admin || developer) {
            setTicketId(allTenants?.data?.tenants?.filter((tenant1: { id: string }) => tenant1?.id === createTicketTenant)[0]?.organization?.ticket_id);
        } else {
            setTicketId(allTenants?.data?.tenants?.filter((tenant1: { id: string }) => tenant1?.id === userData?.data?.organizations[0]?.alias)[0]?.organization?.ticket_id);
        }
    }, [allTenants, tenant, createTicketTenant])

    const handleClose = () => {
        setOpen(false);
        setMergeOpen(false);
        setDescription('');
        setPriority('high');
        setType('task');
        setFiles('');
        setEnvironment('Sandbox');
        setAssignees(null);
    };

    const { userList } = useContext(UserListContext);

    const [relatedUsers, setRelatedUsers] = useState<any>();

    useEffect(() => {
        const users = userList?.data?.users?.users?.filter((user: IUserData) => user.main_tenant === ((admin || superAdmin || developer) ? createTicketTenant : userData !== undefined && userData.data.organizations[0]?.alias) || user.roles[0].name === 'sa' || user.roles[0].name === 'ad')
        const notifUsers = users?.map((user: IUserData) => {
            return {
                subscriberId: JSON.stringify(user.id),
                email: user.email
            }
        })
        setRelatedUsers(notifUsers);
    }, [userList, admin, superAdmin, developer, createTicketTenant, userData])

    const [mergeOpen, setMergeOpen] = useState(false);

    const [relatedDescriptions, setRelatedDescriptions] = useState<any>([]);

    const relatedTicketsCheck = () => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

        axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=999&filters[main_tenant][$eq]=${(admin || superAdmin || developer) ? createTicketTenant : userData !== undefined && userData.data.organizations[0]?.alias}`, {
            headers: headers
        })
            .then(res => {
                const descriptions = res?.data?.data?.filter((ticket: any) => (ticket?.attributes?.status === 'backlog' || ticket?.attributes?.status === 'todo' || ticket?.attributes?.status === 'on hold' || ticket?.attributes?.status === 'redo' || ticket?.attributes?.status === 'in progress'))
                const related = getRelatedTickets(description, descriptions);
                if (related?.length > 0) {
                    setMergeOpen(true);
                    setRelatedDescriptions(related);
                } else {
                    createTicket();
                }
            })
            .catch(() => failed())
    }

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const createTicket = async () => {

        const formData = new FormData()

        Array.from(files).forEach((file: any) => {
            formData.append('files', file)
            formData.append('ref', 'ticket');
            formData.append('field', "attachment");
        })

        axios.post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
            headers: headers
        })
            .then((response) => {
                const attachments: any = []
                response.data.map((imageId: { id: number }) => {
                    attachments.push(imageId.id)
                })
                const ticketInfo = {
                    data: {
                        description: description,
                        type: type,
                        priority: priority,
                        status: priority === 'highest' ? 'todo' : 'backlog',
                        environment: environment,
                        main_tenant: (admin || superAdmin || developer) ? createTicketTenant : userData !== undefined && userData.data.organizations[0]?.alias,
                        attachment: attachments,
                        assignees: assignees
                    }
                };
                createTicketService(ticketInfo);
            }).catch(() => {
                const ticketInfo = {
                    data: {
                        description: description,
                        type: type,
                        priority: priority,
                        environment: environment,
                        main_tenant: (admin || superAdmin || developer) ? createTicketTenant : userData !== undefined && userData.data.organizations[0]?.alias,
                        status: priority === 'highest' ? 'todo' : 'backlog',
                        assignees: assignees
                    }
                };
                createTicketService(ticketInfo);
            })

        handleClose();
    }

    const createTicketService = async (ticketInfo: {
        data: {
            description: string,
            type: string,
            priority: string,
            environment: string,
            main_tenant: string,
            status: string,
            assignees: string[] | null
        }
    }) => {

        axios.post(`${process.env.REACT_APP_strapiUrl}/tickets`, ticketInfo, {
            headers: headers
        })
            .then(response => {
                // if (response.status === 200) {
                //   await catchedTrigger('create-ticket', {
                //     // to: {
                //     //   subscriberId: '63d7751916e379fe65f29506'
                //     // },
                //     to: relatedUsers,
                //     payload: {
                //       desc: response?.data?.data?.attributes?.description
                //     }
                //   })
                // }

                // const idInfo = {
                //   ticket_id: `${createTicketTenant?.slice(0, 3).toUpperCase()}-` + response.data.data.id
                // }

                const idInfo = {
                    ticket_id: `${ticketId?.slice(0, 3).toUpperCase()}-` + response.data.data.id
                }

                const message = {
                    message: response.data.data.attributes.description,
                    sender: userData !== undefined && userData.data.name,
                    ticket: response.data.data.id,
                    admin: (admin || superAdmin || developer) && true,
                    changed_to: 'Description'
                }

                putId(response.data.data.id, response.data.data.attributes.description, idInfo);
                firstMessage(message);
            }).catch(() => {
                failed();
            })
    }

    const firstMessage = async (message: {
        message: string,
        sender: string,
        ticket: number,
        admin: boolean
    }) => {
        await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: message })
        })
        // const addMessage = await add.json()
        // console.log(addMessage);
    }

    const putId = async (id: number, description: string, idInfo: object) => {
        axios.put(`${process.env.REACT_APP_strapiUrl}/tickets/${id}`, { data: idInfo }, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(async (response) => {
            recordLog(`Create`, description, 'ticket', response?.data?.data?.attributes?.ticket_id, currentUser?.id)
            if (response.status === 200) {
                await catchedTrigger('create-ticket', {
                    // to: {
                    //   subscriberId: '63d7751916e379fe65f29506'
                    // },
                    to: relatedUsers,
                    payload: {
                        ticket: response?.data?.data?.attributes?.ticket_id
                    }
                })
            }
            success();
            location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
            (admin || superAdmin || developer) && setTenant(createTicketTenant);
        }).catch(() => {
            failed();
        })
    }

    const mergeTicket = (idToMerge: number) => {
        const data = {
            data: {
                merged_ticket: description
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/tickets/${idToMerge}`, data, {
            headers: headers
        })
            .then(() => {
                success();
                handleClose();
                location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
            })
            .catch(() => {
                failed();
                handleClose();
            })
    }

    return (
        <>
            <AddCircleIcon onClick={() => setOpen(true)} sx={{ width: '35px', height: '35px', mx: 'auto', my: '5px', color: 'black', cursor: 'pointer' }} />

            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogContent>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='15px'>
                        <Box display='flex' alignItems='center' gap='8px'>
                            <ConfirmationNumberIcon />
                            <Typography variant='h6' fontWeight='bold'>Create Ticket</Typography>
                            {(superAdmin || admin || developer) &&
                                <Box>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel>Select*</InputLabel>
                                        <Select
                                            label='Select*'
                                            value={createTicketTenant}
                                            onChange={event => {
                                                setCreateTicketTenant(event.target.value);
                                            }}
                                        >
                                            {developer ? userData?.data?.organizations?.filter((tenant: any) => tenant?.sts_link === 1)?.map((tenant: any) => {
                                                return (
                                                    <MenuItem value={tenant?.alias} key={tenant?.alias}>
                                                        {tenant?.ticket_id?.toUpperCase()}
                                                    </MenuItem>
                                                )
                                            })
                                                :
                                                allTenants?.data?.tenants.filter((tenant: any) => tenant.organization.sts_link === 1).map((tenant: { organization: { alias: string, ticket_id: string }, id: string }) => {
                                                    return <MenuItem value={tenant?.organization?.alias} key={tenant.id}>{tenant?.organization?.ticket_id?.toUpperCase()}</MenuItem>
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                        </Box>
                        <DialogActions>
                            <CloseIcon onClick={handleClose} cursor='pointer' />
                        </DialogActions>
                    </Box>
                    <Box display='flex' flexDirection='column' gap='10px'>
                        <TextField
                            id="outlined-multiline-static"
                            label="Description*"
                            multiline
                            fullWidth
                            rows={8}
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                        />

                        <Box display='flex' gap='10px'>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Type*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Type*"
                                    onChange={handleTypeChange}
                                >
                                    <MenuItem value='bug'>Bug</MenuItem>
                                    <MenuItem value='task'>Task</MenuItem>
                                    <MenuItem value='feature'>Feature</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Priority*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={priority}
                                    label="Priority*"
                                    onChange={handlePriorityChange}
                                >
                                    <MenuItem value='highest'>Highest</MenuItem>
                                    <MenuItem value='high'>High</MenuItem>
                                    <MenuItem value='medium'>Medium</MenuItem>
                                    <MenuItem value='low'>Low</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Box display='flex' gap='10px' alignItems='center'>
                            <Box width='calc(50% - 5px)'>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Environment*</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={environment}
                                        label="Environment*"
                                        onChange={handleEnvironmentChange}
                                    >
                                        <MenuItem value='Sandbox'>Sandbox</MenuItem>
                                        <MenuItem value='UAT'>UAT</MenuItem>
                                        <MenuItem value='Production'>Production</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box ml='5px'>
                                <AssignDeveloper idToChange='' assignees={assignees} setAssignees={setAssignees} main_tenant={createTicketTenant !== undefined ? createTicketTenant : ''} newTicket={true} />
                            </Box>
                        </Box>

                        <input
                            type="file"
                            onChange={(e) => setFiles(e.target.files)}
                            multiple
                        // accept='image/*'
                        />

                        <Button onClick={() => relatedTicketsCheck()} variant="contained" color='info' size='small' sx={{ width: '90px', mx: 'auto', mt: '5px', textTransform: 'none' }}>Create</Button>
                    </Box>
                </DialogContent>
            </Dialog >

            <Dialog maxWidth='md' fullWidth open={mergeOpen} onClose={() => setMergeOpen(false)}>
                <DialogTitle>
                    Related Ticket(s)
                </DialogTitle>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='10px'>
                        {relatedDescriptions?.map((ticket: any) => {
                            return (
                                <Box key={ticket?.id} display='flex' justifyContent='space-between'>
                                    <Typography width='80px'>{ticket?.attributes?.ticket_id}</Typography>
                                    <Typography overflow='hidden' flex='1' mr='10px'>{ticket?.attributes?.description}</Typography>
                                    <Typography onClick={() => mergeTicket(ticket?.id)} color='blue' sx={{ cursor: 'pointer' }}>Merge</Typography>
                                </Box>
                            )
                        })}
                        <Button onClick={() => createTicket()} variant="contained" color='info' size='small' sx={{ width: '150px', mx: 'auto', mt: '5px', textTransform: 'none' }}>Create New Ticket</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateTicket
