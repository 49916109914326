import DesktopHomePageLayout from '../../layout/Desktop/DesktopHomePageLayout';
import DesktopMainLayout from '../../layout/Desktop/DesktopMainLayout';

const DesktopHomePage = () => {
  return (
    <DesktopMainLayout>
      <DesktopHomePageLayout />
    </DesktopMainLayout>
  );
};

export default DesktopHomePage;
