import { useEffect } from 'react';

const expireLoginTimeout = () => {
  const exp = localStorage.getItem('exp');

  if (!exp) {
    return;
  }

  const expiry = +exp * 1_000 - new Date().getTime();

  return setTimeout(() => {
    if (+exp * 1_000 < new Date().getTime()) {
      localStorage.removeItem('access_token');
      window.location.href = `${process.env.REACT_APP_sisUrl}&error=Session expired. Please login again.`;
    }
  }, expiry);
};

const useLoginExpiryEffect = () => {
  const token = localStorage.getItem('access_token');
  useEffect(() => {
    if (token) {
      const timer = expireLoginTimeout();
      return timer && (() => clearTimeout(timer));
    }
    return;
  });
};

export default useLoginExpiryEffect;
