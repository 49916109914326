import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

export const githubActions = async (setGithubRes: Dispatch<SetStateAction<string | undefined>>, repo_name: string, base_branch: string, head_branch: string) => {

    const data = {
        owner: 'sciever-inc',
        repo: repo_name,
        title: 'CI/CD',
        body: 'CI/CD through SOS.',
        head: head_branch,
        base: base_branch
    }

    const config = {
        headers: {
            Accept: 'application/vnd.github+json',
            Authorization: `Bearer ${process.env.REACT_APP_githubToken}`,
            'X-GitHub-Api-Version': '2022-11-28'
        }
    };

    axios.post(
        `https://api.github.com/repos/sciever-inc/${repo_name}/pulls`, data, config
    )
        .then(res => window.open(res.data.html_url, "_blank"))
        .catch(err =>
            setGithubRes(err.response.data.errors[0].message ? err.response.data.errors[0].message : err.response.data.message))
}
