import { Backdrop, Box, CircularProgress, Dialog, Typography } from "@mui/material"
import DeleteTicket from "./DeleteTicket"
import EditType from "./EditType"
import EditPriority from "./EditPriority"
import EditStatus from "./EditStatus"
import TicketForum from "./TicketForum"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import axios from "axios"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import isSuperAdmin from "../hooks/isSuperAdmin"
import EditDescription from "./EditDescription"
import EditDueDate from "./EditDueDate"
import DueDatePicker from "./Desktop/DueDatePicker"
import AssignDeveloper from "./AssignDeveloper"
import ViewAttachments from "./Desktop/ViewAttachments"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EditEnvironment from "./EditEnvironment"
import Pills from "./Pills"
import EditMerged from "./EditMerged"
import EditBranch from "./EditBranch"
import Estimate from "./Estimate"

const TicketDetails = (props: {
    open: boolean,
    ticketId: string,
    setOpen: Dispatch<SetStateAction<boolean>>
}) => {

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<any>('');

    const superAdmin = isSuperAdmin();

    const id = props?.ticketId?.slice(4);

    const headers = {
        'Authorization': `Bearer ${process.env.REACT_APP_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    useEffect(() => {
        if (id) {
            setLoading(true);
            axios.get(`${process.env.REACT_APP_strapiUrl}/tickets/${id}?populate=*`, { headers })
                .then(res => {
                    setData(res.data.data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    setData(undefined);
                })
        }
        else {
            setData(undefined);
        }
    }, [id, props.ticketId])

    const getUrl = (selectedTicket: any) => {
        return selectedTicket.attributes.url;
    }

    const getImages = (selectedTicket: any) => {
        return `${process.env.REACT_APP_strapiImage}${selectedTicket.attributes.url}`;
    }

    const images = data !== undefined && data?.attributes?.attachment?.data?.length > 0 ? data?.attributes?.attachment?.data?.map(getImages) : ''
    const url = data !== undefined && data?.attributes?.attachment?.data?.length > 0 ? data?.attributes?.attachment?.data?.map(getUrl) : ''

    return (
        <>
            {data === undefined ?
                <Dialog open={props.open} onClose={() => props.setOpen(false)}>
                    <Box padding='15px' display='flex' gap='5px' alignItems='center'>
                        <ErrorOutlineIcon color='error' />
                        Ticket is not available!
                    </Box>
                </Dialog>
                : data === '' ?
                    ''
                    :
                    <Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth='lg'>
                        <Box display='flex' gap='10px' p='15px'>
                            <Box display='flex' flexDirection='column' justifyContent='space-between' gap='15px' m='20px 5px 20px 5px'>
                                <ArrowBackIcon onClick={() => props.setOpen(false)} cursor='pointer' />
                                <DeleteTicket tenant={data !== undefined ? data?.attributes?.main_tenant : ''} data={data !== undefined ? data : undefined} selectedRowId={data !== undefined ? data?.attributes?.ticket_id : ''} />
                            </Box>

                            <Box display='flex' flexDirection='column' justifyContent='space-between' width='300px' p='15px' border='solid' borderColor='lightgray' borderRadius='5px' gap='15px' sx={{ borderWidth: '1px' }}>
                                <Box height='250px' sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                                    <Box display='flex' flexDirection='column' gap='25px'>
                                        <Typography fontWeight='bold' whiteSpace='pre-wrap'>
                                            <i>{data?.attributes?.ticket_id}</i>     {data?.attributes?.description}   {superAdmin && <EditDescription ticket_id={props?.ticketId} id={data !== undefined ? data?.id : ''} current={data !== undefined ? data?.attributes?.description : ''} />}
                                        </Typography>

                                        {data?.attributes?.merged_ticket &&
                                            <Typography fontWeight='bold' whiteSpace='pre-wrap'>
                                                <Box display='inline-block'><Pills pill='merged' /></Box>   {data?.attributes?.merged_ticket}   {superAdmin && <EditMerged ticket_id={props?.ticketId} id={data !== undefined ? data?.id : ''} current={data !== undefined ? data?.attributes?.merged_ticket : ''} />}
                                            </Typography>
                                        }
                                    </Box>
                                </Box>
                                <Box display='flex' flexDirection='column' gap='10px'>
                                    <Box display='flex' justifyContent='space-between' alignItems='end'>
                                        <Box display='flex' flexDirection='column' gap='7px' width='100%'>
                                            <EditBranch fullId={data !== undefined ? data?.attributes?.ticket_id : ''} type={data !== undefined ? data?.attributes?.type : ''} id={data !== undefined ? data?.id : ''} branch={data !== undefined ? data?.attributes?.branch : ''} tenant={data !== undefined ? data?.attributes?.main_tenant : ''} />

                                            <Box display='flex' gap='25px'>
                                                <Typography width='85px'>Due Date</Typography>
                                                {data?.attributes?.due_date !== null ? <EditDueDate defaultDate={data !== undefined ? data?.attributes?.due_date : ''} selectedRowId={data !== undefined ? data?.attributes?.ticket_id : ''} /> : <DueDatePicker selectedRowId={data?.attributes?.ticket_id} />}
                                            </Box>

                                            <Box display='flex' gap='25px'>
                                                <Typography width='85px'>Estimate</Typography>
                                                <Estimate estimate={data !== undefined ? data?.attributes?.estimate : ''} id={data !== undefined ? data?.id : ''} />
                                            </Box>

                                            <Box display='flex' gap='25px'>
                                                <Typography width='85px'>Created On</Typography>
                                                <Typography>{new Date(data !== undefined ? data?.attributes?.createdAt : '').toLocaleDateString('en-GB')}</Typography>
                                            </Box>

                                            <Box display='flex' justifyContent='space-between' alignItems='end'>
                                                <Box flex='1' display='flex' justifyContent='start'>
                                                    <AssignDeveloper newTicket={false} setAssignees={undefined} idToChange={data !== undefined ? data?.id : ''} assignees={data?.attributes?.assignees} main_tenant={data?.attributes?.main_tenant} />
                                                </Box>
                                                <Box flex='1' display='flex' justifyContent='center'>
                                                    <EditEnvironment environment={data?.attributes?.environment ? data?.attributes?.environment.toUpperCase() : '-'} tenant={data !== undefined ? data?.attributes?.main_tenant : ''} fullId={data !== undefined ? data?.attributes?.ticket_id : ''} idToChange={data !== undefined ? (data?.id) : ''} />
                                                </Box>
                                                {/* <Typography width='85px'>{data?.attributes?.environment ? data?.attributes?.environment.toUpperCase() : '-'}</Typography> */}
                                                <Box flex='1' display='flex' justifyContent='end'>
                                                    {url !== '' ?
                                                        <ViewAttachments url={url} images={images} name={data?.attributes?.ticket_id} />
                                                        :
                                                        ''
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                                        <Box flex='1' display='flex' justifyContent='start'>
                                            <EditType tenant={data !== undefined ? data?.attributes?.main_tenant : ''} fullId={data !== undefined ? data?.attributes?.ticket_id : ''} idToChange={data !== undefined ? (data?.id) : ''} type={data !== undefined ? data?.attributes?.type : ''} />
                                        </Box>
                                        <Box flex='1' display='flex' justifyContent='center'>
                                            <EditPriority tenant={data !== undefined ? data?.attributes?.main_tenant : ''} fullId={data !== undefined ? data?.attributes?.ticket_id : ''} idToChange={data !== undefined ? (data?.id) : ''} priority={data !== undefined ? data?.attributes?.priority : ''} />
                                        </Box>
                                        <Box flex='1' display='flex' justifyContent='end'>
                                            <EditStatus tenant={data !== undefined ? data?.attributes?.main_tenant : ''} type={data !== undefined ? data?.attributes?.type : ''} branch={data !== undefined ? data?.attributes?.branch : ''} fullId={data !== undefined ? data?.attributes?.ticket_id : ''} assignees={data !== undefined ? data?.attributes?.assignees : ''} dueDate={data !== undefined ? data?.attributes?.due_date : ''} idToChange={data !== undefined ? (data?.id) : ''} status={data !== undefined ? data?.attributes?.status : ''} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <TicketForum selectedRowId={data !== undefined ? data?.attributes?.ticket_id : ''} data={data} />
                        </Box>
                    </Dialog >
            }

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                }}
                open={loading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </>
    )
}

export default TicketDetails
