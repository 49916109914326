import { Box, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TenantContext, TicketsContext } from '../../App';
import isMobile from '../../hooks/isMobile';
import { TicketListContext } from '../../pages/HomePage';
import axios from 'axios';
import { UserDataContext } from '../../routes/Main';
import isDeveloper from '../../hooks/isDeveloper';
import isSuperAdmin from '../../hooks/isSuperAdmin';

const StatusBlock = () => {

  const { tickets } = useContext(TicketsContext);

  const { userData } = useContext(UserDataContext);

  const { tenant } = useContext(TenantContext);

  const developer = isDeveloper();

  const superAdmin = isSuperAdmin();

  const navigate = useNavigate();

  const handleClick = (status: string) => {
    navigate(`/tickets?status=${status}`);
  };

  const { ticketList } = useContext(TicketListContext);

  const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

  const getCount = (status: string) => {
    return axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?filters[status][$eq]=${status}&filters[main_tenant][$eq]=${userData?.data?.organizations[0]?.alias}`, {
      headers: headers
    })
      .then(res => {
        return res.data.meta.pagination.total
      })
  }

  const getTenantSpecificCount = (status: string, mainTenant: string | [{ alias: string }]) => {
    if (mainTenant) {
      if (typeof (mainTenant) === 'object') {
        return axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=0&pagination[pageSize]=0&filters[status][$eq]=${status}${mainTenant.map(value => `&filters[main_tenant][$eq]=${value?.alias}`)}`.replace(/,/g, ''), {
          headers: headers
        })
          .then(res => {
            return res.data.meta.pagination.total
          })
      } else {
        return axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=0&pagination[pageSize]=0&filters[status][$eq]=${status}&filters[main_tenant][$eq]=${mainTenant}`, {
          headers: headers
        })
          .then(res => {
            return res.data.meta.pagination.total
          })
      }
    } else {
      return axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=0&pagination[pageSize]=0&filters[status][$eq]=${status}`, {
        headers: headers
      })
        .then(res => {
          return res.data.meta.pagination.total
        })
    }
  }

  const [backlogCount, setBacklogCount] = useState(0)
  const [onHoldCount, setOnHoldCount] = useState(0)
  const [todoCount, setTodoCount] = useState(0)
  const [redoCount, setRedoCount] = useState(0)
  const [inProgressCount, setInProgressCount] = useState(0)
  const [prCount, setPrCount] = useState(0)
  const [prDoneCount, setPrDoneCount] = useState(0)
  const [doneCount, setDoneCount] = useState(0)
  const [uatCount, setUatCount] = useState(0)
  const [productionCount, setProductionCount] = useState(0)
  const [closedCount, setClosedCount] = useState(0)
  const [canceledCount, setCanceledCount] = useState(0)

  const getFinalCount = (status: string, finalState: Dispatch<SetStateAction<number>>) => {
    if (tenant && userData) {
      developer ?
        getTenantSpecificCount(status, tenant === 'all' ? userData?.data?.organizations : tenant)
          .then(count => finalState(count)) :
        superAdmin ?
          getTenantSpecificCount(status, tenant === 'all' ? null : tenant)
            .then(count => finalState(count)) :
          getCount(status)
            .then(count => finalState(count))
    }
  }

  const blockColor = useMemo(() => {
    return [
      {
        name: 'backlog',
        hexCode: '#515B52',
        count: backlogCount,
        dispatch: setBacklogCount
      },
      {
        name: 'on hold',
        hexCode: '#747c75',
        count: onHoldCount,
        dispatch: setOnHoldCount
      },
      {
        name: 'todo',
        hexCode: '#93A795',
        count: todoCount,
        dispatch: setTodoCount
      },
      {
        name: 'redo',
        hexCode: '#E30000',
        count: redoCount,
        dispatch: setRedoCount
      },
      {
        name: 'in progress',
        hexCode: '#FF7A00',
        count: inProgressCount,
        dispatch: setInProgressCount
      },
      {
        name: 'pr',
        hexCode: '#1468B6',
        count: prCount,
        dispatch: setPrCount
      },
      {
        name: 'pr done',
        hexCode: '#0085FF',
        count: prDoneCount,
        dispatch: setPrDoneCount
      },
      {
        name: 'done',
        hexCode: '#0ED9CD',
        count: doneCount,
        dispatch: setDoneCount
      },
      {
        name: 'uat',
        hexCode: '#00E309',
        count: uatCount,
        dispatch: setUatCount
      },
      {
        name: 'production',
        hexCode: '#03C03C',
        count: productionCount,
        dispatch: setProductionCount
      },
      {
        name: 'closed',
        hexCode: '#00800D',
        count: closedCount,
        dispatch: setClosedCount
      },
      {
        name: 'canceled',
        hexCode: '#000000',
        count: canceledCount,
        dispatch: setCanceledCount
      },
    ];
  }, [ticketList, tickets, userData, tenant, developer, superAdmin]);

  useEffect(() => {
    if (tenant && userData) {
      blockColor?.map(color => {
        getFinalCount(color?.name, color?.dispatch)
      })
    }
  }, [tenant, userData])

  const mobile = isMobile();

  return (
    <>
      {!mobile ? (
        <Stack direction='row' mt='15px' justifyContent='space-between' gap='10px'>
          {blockColor?.map((blockColor) => {
            return (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                pb='5px'
                onClick={() =>
                  blockColor.name !== 'pr done'
                    ? handleClick(blockColor.name)
                    : ''
                }
                sx={{
                  width: '100%',
                  height: '65px',
                  borderRadius: '10px',
                  bgcolor: blockColor?.hexCode,
                  cursor:
                    blockColor.name !== 'pr done'
                      ? 'pointer'
                      : 'not-allowed',
                }}
                key={blockColor.name}
              >
                <Typography fontWeight='bold' color='white' fontSize='24px'>
                  {blockColor.count}
                </Typography>
                <Typography color='white' fontSize='12px'>
                  {blockColor.name.toUpperCase()}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      ) : (
        <Box display='flex' overflow='scroll' sx={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
          },
        }}>
          <Box display='flex' gap='10px' mt='126px' >
            {blockColor?.map((blockColor) => {
              return (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  width='70px'
                  height='50px'
                  pb='5px'
                  sx={{
                    borderRadius: '10px',
                    bgcolor: `${blockColor?.hexCode || '#00800D'}`,
                  }}
                  key={blockColor.name}
                  onClick={() =>
                    blockColor.name !== 'pr done'
                      ? handleClick(blockColor.name)
                      : ''
                  }
                >
                  <Typography fontWeight='bold' color='white' fontSize='20px'>
                    {blockColor.count}
                  </Typography>
                  <Typography fontWeight='bold' color='white' fontSize='10px'>
                    {blockColor.name.toUpperCase()}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default StatusBlock;
