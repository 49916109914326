import { Box, ImageList, ImageListItem, Typography} from "@mui/material";
import logo from '../../assets/images/sciever_logo.png'
// import ForumIcon from '@mui/icons-material/Forum';
import isMobile from "../../hooks/isMobile";
const ChatHead = () => {
    const mobileView = isMobile();
  return (
    <>
    <Box display='flex' flexDirection='row' alignItems='center' gap='20px'
                            width=  '100%' padding={mobileView? '10px ' :'0'}>
                            <ImageList
                                sx={{
                                    width: '45px',
                                    height: '45px',
                                    overflow: 'hidden',
                                    m: '0px'
                                }}
                                cols={1}
                            >
                                <ImageListItem>
                                    <img src={logo} alt='Sciever Inc. Logo' style={{ width: '45px', height: '45px' }} />
                                </ImageListItem>
                            </ImageList>

                            <Typography variant='h6' fontWeight='bold'>
                                Chat Room
                            </Typography>
                            
                            {/* <ForumIcon sx={{ width: '25px', height: '25px', mt: '8px', color: 'black' }} /> */}
                        </Box>
    </>
  )
}

export default ChatHead