import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import { useState, useContext, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import { success } from '../hooks/useToast';
import { catchedTrigger } from '../utils/novu';
import { DataContext } from '../pages/TicketsPage';
import { ITicketData, IUserData, TicketListContext } from '../pages/HomePage';
import { UserListContext } from '../routes/Main';
import { recordLog } from '../services/recordLog';
import { CurrentUserContext } from '../App';

const DeleteTicket = (props:
    {
        selectedRowId: string,
        data: ITicketData | undefined,
        tenant: string
    }
) => {

    const { fetchTickets } = useContext(DataContext);

    const { fetchTicketsHomePage } = useContext(TicketListContext);

    const { currentUser } = useContext(CurrentUserContext);

    const id = props.selectedRowId != null ? props.selectedRowId.slice(4) : 'undefined';

    const idToDelete = parseInt(id);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [relatedUsers, setRelatedUsers] = useState<any>();

    const { userList } = useContext(UserListContext)

    useEffect(() => {
        const users = userList?.data?.users?.users?.filter((user: IUserData) => user.main_tenant === props.tenant || user.roles[0].name === 'sa' || user.roles[0].name === 'ad')
        const notifUsers = users.map((user: IUserData) => {
            return {
                subscriberId: JSON.stringify(user.id),
                email: user.email
            }
        })
        setRelatedUsers(notifUsers);
    }, [userList, props.tenant])

    const deleteTicket = async () => {
        const headers = {
            'Authorization': `Bearer ${process.env.REACT_APP_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        axios.delete(`${process.env.REACT_APP_strapiUrl}/tickets/${idToDelete}`, { headers })
            .then(async (response) => {
                recordLog(`Delete`, 'Ticket Deleted', 'ticket', props?.selectedRowId, currentUser?.id);
                if (response.status === 200) {
                    await catchedTrigger('delete-ticket', {
                        // to: {
                        //     subscriberId: '63d7751916e379fe65f29506'
                        // },
                        to: relatedUsers,
                        payload: {
                            ticket: props.selectedRowId
                        }
                    })
                }
                // toggleRefresh();
                fetchTicketsHomePage();
                success();
            })
        // .catch(() => {
        //     // toggleRefresh();
        //     failed();
        // })

        fetchTickets();
        handleClose();
    }

    return (
        <>
            <DeleteIcon onClick={handleClickOpen} cursor='pointer' />
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
                    <Box display='flex' gap='3px' pr='2px'>
                        <DeleteIcon />
                        <Typography>Are you sure?</Typography>
                    </Box>
                    <Box display='flex' gap='10px'>
                        <Button size='small' variant='contained' color='error' onClick={deleteTicket}>Yes</Button>
                        <Button size='small' variant='outlined' onClick={handleClose}>No</Button>
                    </Box>
                </Box>
            </Dialog >
        </>
    )
}

export default DeleteTicket
