import MobileNotificationsLayout from '../layout/Mobile/MobileNotificationsLayout'

const NotificationsPage = () => {
    return (
        <>
            <MobileNotificationsLayout />
        </>
    )
}

export default NotificationsPage
