import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const ScieverCalendar = (
    props: {
        height: string,
        setEnglishDate?: Dispatch<SetStateAction<string | undefined>>,
        setNepaliDate?: Dispatch<SetStateAction<string | undefined>>,
        calendarId: string
    }
) => {
    const [englishDate, setEnglishDate] = useState('')
    const [nepaliDate, setNepaliDate] = useState('')

    useEffect(() => {
        const handleMessage = (ev: MessageEvent<{ type: string, english: string, nepali: string }>) => {
            if (typeof ev.data !== 'object') return
            if (!ev.data.type) return
            if (ev.data.type !== 'button-click') return
            if (!ev.data.english) return

            setEnglishDate(ev.data.english)
            setNepaliDate(ev.data.nepali)
        }

        window.addEventListener('message', handleMessage)

        return () => window.removeEventListener('message', handleMessage)
    }, [])

    useEffect(() => {
        (englishDate && props?.setEnglishDate) && props.setEnglishDate(englishDate);
        (nepaliDate && props?.setNepaliDate) && props.setNepaliDate(nepaliDate);
    }, [englishDate, nepaliDate])

    return (
        <>
            <iframe
                src={`https://calendar.scieverinc.com?calendarId=${props.calendarId}`}
                title="Calendar"
                width="100%"
                height={props.height}
                allowFullScreen
                style={{
                    padding: '10px',
                    border: 'none'
                }}
            />
        </>
    );
};

export default ScieverCalendar;