import { useEffect, useState } from 'react'
import { AttendanceBlock } from '../../layout/Desktop/DesktopAttendanceLayout'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { Box, Dialog, Typography } from '@mui/material'
import ScieverCalendar from '../../utils/ScieverCalendar';
import axios from 'axios';
import { getCurrentDate } from './CheckInOut';
import { failed } from '../../hooks/useToast';
// import Info from './Info';

const Holidays = () => {

    const [open, setOpen] = useState(false);

    const [upcomingHolidays, setUpcomingHolidays] = useState<[{ summary: string, start: { date: string } }]>()

    const getUpcomingHolidays = () => {
        axios.get(`https://calendar.scieverinc.com/calendar/events/e81b5c1c828bbd9f57ff94b5082cfa57220252d7b25d4e2db07b442f012efda0@group.calendar.google.com`)
            .then(res => setUpcomingHolidays(res.data.filter((holiday: { start: { date: string } }) => holiday.start.date > getCurrentDate())))
            .catch(() => failed())
    }

    useEffect(() => {
        getUpcomingHolidays()
    }, [])

    return (
        <>
            <AttendanceBlock sx={{ height: '50%' }}>
                {/* <Info section='Holidays' /> */}
                <Box display='flex' gap='5px' ml='-3px' alignItems='center' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                    <CalendarMonthIcon sx={{ width: '25px', height: '25px' }} />
                    <b>Holidays</b>
                </Box>
                <Box width='100%' alignSelf='flex-start' display='flex' flexDirection='column' gap='5px' mt='10px' fontSize='15px' overflow='hidden'>
                    Upcoming holidays:
                    {upcomingHolidays?.slice(0, 5)?.map(holiday => {
                        return (
                            <Box key={holiday.summary} display='flex' width='100%' justifyContent='space-between'>
                                <Typography>{holiday.summary}</Typography>
                                <Typography display='flex' justifyContent='end' width='50px'>{holiday.start.date.slice(5)}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </AttendanceBlock>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='md'>
                {/* <iframe
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FKathmandu&showNav=1&showPrint=0&showCalendars=1&showTabs=1&src=ZTgxYjVjMWM4MjhiYmQ5ZjU3ZmY5NGI1MDgyY2ZhNTcyMjAyNTJkN2IyNWQ0ZTJkYjA3YjQ0MmYwMTJlZmRhMEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23B39DDB"
                    style={{ border: 'solid 1px #777' }}
                    width="100%"
                    height="450px" /> */}
                <ScieverCalendar height='570px' calendarId='e81b5c1c828bbd9f57ff94b5082cfa57220252d7b25d4e2db07b442f012efda0@group.calendar.google.com' />
            </Dialog>
        </>
    )
}

export default Holidays;
