import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSocketId, setConnected, setError } from '../websocket/websocketSlice';
import { Socket } from 'socket.io-client';

export const connectWebSocket = createAsyncThunk(
  'websocket/connectWebSocket',
  async (socket: Socket | null, { dispatch }) => {
    try {
      if (socket) {
        socket.on('connect', () => {
          dispatch(setSocketId(socket.id ?? null));
          dispatch(setConnected(true));
        });

        socket.on('disconnect', () => {
          dispatch(setConnected(false));
          dispatch(setSocketId(null));
        });

        socket.on('error', (error) => {
          console.error('API error', error);
          dispatch(setError(error.message));
        });

        socket.on('validationError', (error) => {
          console.error('API validation error', error);
          dispatch(setError(error.message));
        });
      }

      return socket;

    } catch (error) {
      console.error('Caught an error in connectWebSocket:', error);
      dispatch(setError('Failed to connect to WebSocket'));
      throw error;
    }
  }
);