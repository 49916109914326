import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useContext } from 'react';
import { CountContext, DataContext } from '../../pages/TicketsPage';
import EditDueDate from '../EditDueDate';
import EditPriority from '../EditPriority';
import EditStatus from '../EditStatus';
import EditType from '../EditType';
import Filter from '../Filter';
import DueDatePicker from './DueDatePicker';
import TableIcons from './TableIcons';
import ViewAttachments from './ViewAttachments';
import DesktopTicketForum from './DesktopTicketForum';
import { ITicketData } from '../../pages/HomePage';
import isCustomer from '../../hooks/isCustomer';
import '../../index.css';
import { PageContext, PageSizeContext } from '../../routes/Main';

const TicketsTable = () => {
  const { data } = useContext(DataContext);

  const { count } = useContext(CountContext);

  const { page, setPage } = useContext(PageContext);

  const { pageSize, setPageSize } = useContext(PageSizeContext);

  // axios.get('https://sos.sis.scieverinc.com/api/tenant/current')
  //     .then((data) => {
  //       console.log(data.data);
  //     });

  // console.log(userData);

  const customer = isCustomer();

  const getTickets = (ticket: ITicketData) => {
    return ticket?.attributes;
  };

  const getUrl = (ticket: { attributes: { url: string } }) => {
    return ticket.attributes.url;
  };

  const tickets = data?.length > 0 ? data.map(getTickets) : '';

  const columns: GridColDef[] = [
    {
      field: 'sn',
      renderHeader: () => {
        return <Typography fontWeight='bold'>SN</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 50,
      flex: 0.5,
      sortable: false,
      renderCell: (params) =>
        page * pageSize + params.api.getRowIndexRelativeToVisibleRows(params.row.ticket_id) + 1,
    },
    {
      field: 'ticket_id',
      renderHeader: () => {
        return (
          <Box display='flex' alignItems='center'>
            <Typography fontWeight='bold'>Ticket ID</Typography>
            <ImportExportIcon />
          </Box>
        );
      },
      headerAlign: 'center',
      // width: 100,
      flex: 1.5,
      align: 'center',
      editable: false,
      renderCell: (rowData) => <DesktopTicketForum field='id' selectedRowId={rowData.row.ticket_id} data={data} />
    },
    {
      field: 'description',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Description</Typography>;
      },
      // width: 330,
      flex: 6,
      editable: false,
      sortable: false,
      renderCell: (rowData) => <DesktopTicketForum field='description' selectedRowId={rowData.row.ticket_id} data={data} />
    },
    {
      field: 'attachment',
      renderHeader: () => {
        return <AttachFileIcon sx={{ width: '18px', height: '18px' }} />;
      },
      headerAlign: 'center',
      // width: 170,
      flex: 1,
      editable: false,
      sortable: false,
      align: 'center',
      renderCell: (row) => {
        const url =
          row?.row?.attachment?.data?.length > 0
            ? row.row.attachment.data.map(getUrl)
            : 'undefined';

        const getImages = (ticket: { attributes: { url: string } }) => {
          return `${process.env.REACT_APP_strapiImage}${ticket.attributes.url}`;
        };

        const images =
          row?.row?.attachment?.data?.length > 0 ? row.row.attachment.data.map(getImages) : '';

        return (
          <>
            {url != 'undefined' ? (
              <ViewAttachments url={url} images={images} name={row?.row?.ticket_id} />
            ) : (
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography align='center'>-</Typography>
              </Box>
            )}

            {/* <Attachments url={url} images={images} data={row.row} table={true} /> */}

          </>
        );
      },
    },

    {
      field: 'assignees',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Assignee(s)</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 1.5,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        if (!customer) {
          return (
            row.row.assignees === null || row?.row?.assignees?.length === 0
              ?
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography align='center'>-</Typography>
              </Box>
              :
              <Typography display='flex' flexDirection='column' alignItems='center'>
                {row.row.assignees.map((assignee: string) => {
                  if (assignee?.substring(0, 8) === 'customer') {
                    return (
                      <Box key={assignee}>
                        {assignee.split(' ')[0].slice(8)}
                      </Box>
                    )
                  } else {
                    return (
                      <Box key={assignee}>
                        {assignee.split(' ')[0]}
                      </Box>
                    )
                  }
                })}
              </Typography>
          );
        } else {
          return (
            row.row.assignees === null || row?.row?.assignees?.length === 0
              ?
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography align='center'>-</Typography>
              </Box>
              : row?.row?.assignees?.filter((assignee: string) => assignee?.substring(0, 8) === 'customer')?.length === 0
                ?
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Typography align='center'>-</Typography>
                </Box>
                :
                < Typography display='flex' flexDirection='column' alignItems='center' >
                  {
                    row.row.assignees.filter((assignee: string) => assignee?.substring(0, 8) === 'customer')?.map((assignee: string) => {
                      return (
                        <Box key={assignee}>
                          {assignee.split(' ')[0].slice(8)}
                        </Box>
                      )
                    })
                  }
                </Typography >
          )
        }
      },
    },
    {
      field: 'type',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Type</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 1.5,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const type = row?.row?.type?.length > 0 ? row.row.type : 'undefined';
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        return (
          // <Button onClick={() => { handleTypeOpen(); handleTypeToChange(idToChange); }} sx={{ p: '0px', b: '0px' }}>
          //     <Pills pill={type} />
          // </Button>

          <EditType tenant={row.row.main_tenant} fullId={row.row.ticket_id} idToChange={idToChange} type={type} />
        );
      },
    },
    {
      field: 'priority',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Priority</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 110,
      flex: 1.5,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const priority = row?.row?.priority?.length > 0 ? row.row.priority : 'undefined';
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        return (
          // <Button onClick={() => { handlePriorityOpen(); handlePriorityToChange(idToChange); }} sx={{ p: '0px', b: '0px' }}>
          //     <Pills pill={priority} />
          // </Button>

          <EditPriority tenant={row.row.main_tenant} fullId={row.row.ticket_id} idToChange={idToChange} priority={priority} />
        );
      },
    },
    {
      field: 'status',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Status</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 110,
      flex: 1.5,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const status = row?.row?.status?.length > 0 ? row.row.status : 'undefined';
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        return (
          <>
            {/* {admin || superAdmin */}
            {/* ?  */}

            <EditStatus tenant={row.row.main_tenant} branch={row.row.branch} type={row.row.type} fullId={row.row.ticket_id} assignees={row.row.assignees} dueDate={row.row.due_date} idToChange={idToChange} status={status} />

            {/* : <Button disabled sx={{ p: '0px', b: '0px' }}>
                                <Pills pill={status} />
                            </Button>
                        } */}
          </>
        );
      },
    },
    {
      field: 'due_date',
      renderHeader: () => {
        return (
          <Box display='flex' alignItems='center'>
            <Typography fontWeight='bold'>Due Date</Typography>
            <ImportExportIcon />
          </Box>
        );
      },
      headerAlign: 'center',
      align: 'center',
      // width: 110,
      flex: 1.5,
      editable: false,
      renderCell: (row) => {
        if (row?.row?.due_date !== null && row?.row?.due_date !== undefined) {
          return (
            // <Typography>{new Date(row.row.due_date).toLocaleDateString('en-GB')}</Typography>
            <EditDueDate defaultDate={row.row.due_date} selectedRowId={row.row.ticket_id} />
          );
        } else {
          return <DueDatePicker selectedRowId={row.row.ticket_id} />;
        }
      },
    },
    {
      field: 'forum',
      headerName: '',
      flex: 1,
      align: 'center',
      editable: false,
      sortable: false,
      renderCell: (rowData) => {
        return <TableIcons selectedRowId={rowData.row.ticket_id} data={data} />;
      },
    },
  ];

  // const columnVisibilityModel = useMemo(() => {
  //   if (admin || superAdmin || developer) {
  //     return {
  //       assignees: true,
  //     };
  //   }
  //   return {
  //     assignees: false
  //   };
  // }, [admin, superAdmin, developer]);

  const hasDueDatePassed = (dueDate: string): boolean => {
    const currentDate = new Date();
    const dueDateObj = new Date(dueDate);
    currentDate.setDate(currentDate.getDate() - 1);

    return currentDate > dueDateObj;
  };

  return (
    <>
      <Filter />
      <Box sx={{ bgcolor: 'white', height: '91%', width: '100%' }}>
        <DataGrid
          rows={tickets}
          columns={columns}
          // columnVisibilityModel={columnVisibilityModel}
          rowHeight={50}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          paginationMode='server'
          disableColumnMenu
          sx={{
            border: 'none',
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '0.4em',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
            {
              borderColor: 'darkgray',
              borderWidth: '1px',
            },
          }}
          getRowId={(row) => row?.ticket_id}
          rowCount={count}
          disableRowSelectionOnClick
          onPaginationModelChange={(row) => {
            setPageSize(row.pageSize);
            setPage(row.page);
          }}
          getRowClassName={(row) => {
            if ((row?.row?.status === 'backlog' || row?.row?.status === 'on hold' || row?.row?.status === 'todo' || row?.row?.status === 'redo' || row?.row?.status === 'in progress') && row?.row?.due_date !== null && hasDueDatePassed(row?.row?.due_date)) {
              return 'custom-row-class'
            } else {
              return ''
            }
          }}
        />
      </Box>
    </>
  );
};

export default TicketsTable;
