import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Dialog, DialogContent, InputAdornment, TextField, Typography } from '@mui/material';
import { useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import isMobile from '../hooks/isMobile';
import { CurrentUserContext, LoadingContext } from '../App';
import { failed, success } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
import { UserDataContext } from '../routes/Main';
import isAdmin from '../hooks/isAdmin';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';
import { useLocation } from 'react-router-dom';

const EditMerged = (props:
    {
        ticket_id: string,
        id: string | number,
        current: string
    }
) => {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setDescription(props.current);
    }

    const [description, setDescription] = useState(props.current);

    const mobile = isMobile();

    const { setLoading } = useContext(LoadingContext);

    const { fetchTickets } = useContext(DataContext);

    const { userData } = useContext(UserDataContext);

    const { currentUser } = useContext(CurrentUserContext);

    const { fetchTicketsHomePage } = useContext(TicketListContext);

    const location = useLocation();

    const admin = isAdmin();
    const superAdmin = isSuperAdmin();
    const developer = isDeveloper();

    const handleEdit = async () => {
        setLoading(true);

        const ticketInfo = {
            merged_ticket: description,
        };

        await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${props.id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: ticketInfo }),
        })
            .then(() => {
                success();
                setOpen(false);
                setLoading(false);
                recordLog(`Description`, description, 'ticket', props.ticket_id, currentUser?.id);
            })
            .catch(() => {
                failed();
                setLoading(false);
            });

        const messageInfo = {
            message: description,
            sender: userData !== undefined && userData.data.name,
            ticket: props.id,
            admin: (admin || superAdmin || developer) && true,
            changed_to: 'Description'
        };

        await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: messageInfo }),
        })
            .then(() => {
                setLoading(false);
                location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
            })
            .catch(() => {
                // failed();
                setLoading(false);
            });
    }

    const [deleteOpen, setDeleteOpen] = useState(false);

    const handleDelete = async () => {

        const ticketInfo = {
            merged_ticket: null,
        };

        await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${props.id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: ticketInfo }),
        })
            .then(() => {
                success();
                setDeleteOpen(false);
                setLoading(false);
                location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
            })
            .catch(() => {
                failed();
                setLoading(false);
            });
    }

    return (
        <>
            <Box display='inline-block'>
                <EditIcon onClick={() => setOpen(true)} sx={{ height: '16px', color: 'gray', mb: '-2px', cursor: 'pointer' }} />
                <DeleteIcon onClick={() => setDeleteOpen(true)} sx={{ height: '16px', color: 'gray', ml: '-3px', mb: '-2px', cursor: 'pointer' }} />
            </Box>

            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='15px'>
                        <Box display='flex' justifyContent='space-between'>
                            <Typography fontWeight='bold'>Description:</Typography>
                            <CloseIcon onClick={handleClose} cursor='pointer' />
                        </Box>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' sx={{ mt: '-68px' }}>
                                        <SendIcon sx={{ cursor: 'pointer' }} onClick={handleEdit} />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setDescription(e.target.value)}
                            onKeyDown={(e) => {
                                if (!mobile) {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        handleEdit();
                                        e.preventDefault();
                                    }
                                }
                            }}
                            value={description}
                        />
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
                <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
                    <Box display='flex' gap='3px' pr='2px'>
                        <DeleteIcon />
                        <Typography>Are you sure?</Typography>
                    </Box>
                    <Box display='flex' gap='10px'>
                        <Button size='small' variant='contained' color='error' onClick={() => handleDelete()}>Yes</Button>
                        <Button size='small' variant='outlined' onClick={() => setDeleteOpen(false)}>No</Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default EditMerged;
