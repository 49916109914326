import { Box } from '@mui/material';

export default function MobileTicketSection({ children }: { children: React.ReactNode }) {
  return (
    <Box
      width='100%'
      bgcolor='white'
      p='10px'
      pt='0px'
      pb='15px'
      border='dashed'
      borderRadius='10px'
      borderColor='gray'
      display='flex'
      flexDirection='column'
      gap='10px'
      height='calc(100vh - 265px)'
      sx={{ overflowY: 'scroll' }}
    >
      {children}
    </Box>
  );
}
