import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Avatar,
  IconButton,
  Box,
  Typography,
  Input,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';

interface CreateGuestDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateGuestDialog: React.FC<CreateGuestDialogProps> = ({ open, onClose }) => {
  const [groupName, setGroupName] = useState('');
  const [groupAvatar, setGroupAvatar] = useState<string | null>(null);
  const [shareCode, setShareCode] = useState('');
  const [inviteLink, setInviteLink] = useState('');
  // const shareCode = '013 254 345';  // For static example
  // const inviteLink = `https://sciever.inc.com/${shareCode}`;  // For static example
  const [SnackbarOpen, setSnackbarOpen] = useState(false);

  // Generate random share code
  useEffect(() => {
    const newShareCode = generateRandomCode();
    setShareCode(newShareCode);
    setInviteLink(`https://sciever.inc.com/${newShareCode}`);
  }, []);

  const generateRandomCode = (): string => {
    const generateGroup = () =>
      Math.floor(Math.random() * 1000)
        .toString()
        .padStart(3, '0');
    return `${generateGroup()} ${generateGroup()} ${generateGroup()}`;
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setGroupAvatar(e.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true); // toast notification
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleEmail = () => {
    const subject = encodeURIComponent('Join our Guest Room');
    const body = encodeURIComponent(`Join our guest room using this link: ${inviteLink}`);
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${subject}&body=${body}`;
    window.open(gmailUrl, '_blank');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>
        <Typography variant='h6' component='span' sx={{ fontWeight: 'bold' }}>
          Create Guest Room
        </Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <IconButton component='label' sx={{ p: 0, borderRadius: '50%' }}>
            <Avatar
              sx={{
                width: 80,
                height: 80,
                mb: 1,
              }}
              src={groupAvatar || undefined}
            >
              {!groupAvatar && 'G'}
            </Avatar>
            <Input
              type='file'
              onChange={handleAvatarChange}
              sx={{ display: 'none' }}
              inputProps={{ accept: 'image/*' }}
            />
          </IconButton>
          <TextField
            placeholder='Guest Room Name'
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            variant='standard'
            sx={{ width: '50%', mb: 2 }}
            inputProps={{
              sx: {
                textAlign: 'center',
              },
            }}
          />
          <Box sx={{ width: '100%', mb: 1 }}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
              Share code:
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                value={shareCode}
                variant='outlined'
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <Button
                variant='contained'
                onClick={() => handleCopy(shareCode)}
                sx={{ ml: 1, minWidth: 'auto' }}
              >
                <ContentCopyIcon />
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: '100%', mb: 2 }}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
              Invite link:
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                value={inviteLink}
                variant='outlined'
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <Button variant='contained' onClick={handleEmail} sx={{ ml: 1, minWidth: 'auto' }}>
                <EmailIcon />
              </Button>
              <Button
                variant='contained'
                onClick={() => handleCopy(inviteLink)}
                sx={{ ml: 1, minWidth: 'auto' }}
              >
                <ContentCopyIcon />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            onClick={onClose}
            disabled={!groupName}
            sx={{
              bgcolor: groupName ? 'black' : 'grey.500',
              color: 'white',
              '&:hover': {
                bgcolor: groupName ? 'black' : 'grey.600',
              },
              width: 'auto',
              px: 4,
            }}
          >
            Create Group
            <GroupAddIcon sx={{ ml: 1, color: groupName ? 'white' : 'grey.500' }} />
          </Button>
        </Box>
      </DialogContent>
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message='Copied to clipboard'
      />
    </Dialog>
  );
};

export default CreateGuestDialog;
