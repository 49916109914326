import axios from "axios";
import { getCurrentDate, getCurrentTime } from "../components/Attendance/CheckInOut";

export const recordLog = (
    action: string,
    description: string,
    type: string,
    ticket_id: string,
    user: number
) => {

    if (user !== undefined) {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

        const data = {
            data: {
                action: action,
                description: description,
                type: type,
                ticket_id: ticket_id,
                date: getCurrentDate(),
                time: getCurrentTime(),
                attendance_user: user
            }
        }

        axios.post(`${process.env.REACT_APP_strapiUrl}/logs`, data, {
            headers: headers
        })
        // .then(res => console.log(res))
        // .catch(err => console.log(err))
    }
}