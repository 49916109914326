import React, { useState, useRef, KeyboardEvent, useContext } from 'react';
import { Box, TextField, IconButton, Button } from "@mui/material";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Picker from '@emoji-mart/react';
import { MessageData, RoomData } from '../../store/constants/constants';
import { UserDataContext } from '../../routes/Main';

interface ChatInputAreaProps {
    chat: RoomData | null;
    onSendMessage: (message: MessageData) => void;
}

const ChatInputArea: React.FC<ChatInputAreaProps> = ({ chat, onSendMessage }) => {
    const [message, setMessage] = useState<string>("");
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);
    const emojiButtonRef = useRef<HTMLButtonElement>(null);

    const handleEmojiSelect = (emoji: any) => {
        if (textFieldRef.current && cursorPosition !== null) {
            const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
            setMessage(newMessage);
            const newCursorPosition = cursorPosition + emoji.native.length;
            setCursorPosition(newCursorPosition);
            setTimeout(() => {
                textFieldRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
                textFieldRef.current?.focus();
            }, 0);
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            setSelectedFiles(prevFiles => [...prevFiles, ...filesArray]);
        }
    };

    const handleToggleEmojiPicker = () => {
        setEmojiPickerVisible(prev => !prev);
    };

  const { userData } = useContext(UserDataContext);

    const handleSend = () => {
        if (message.trim() || selectedFiles.length > 0) {
            // const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            if (message.trim()) {
                onSendMessage({
                    senderId: userData?.data?.id,
                    roomId: chat?.id ? chat.id : 1,
                    content: message,
                });
            }

            // selectedFiles.forEach((file) => {
            //     // const fileTimestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            //     onSendMessage({
            //         senderId: 1,
            //         roomId: 2,
            //         content: 'hello',
            //     });
            // });

            setMessage("");
            setSelectedFiles([]);
            setEmojiPickerVisible(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    const handleTextFieldClick = () => {
        if (textFieldRef.current) {
            setCursorPosition(textFieldRef.current.selectionStart);
        }
    };

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMessage = event.target.value;
        const cursorPos = textFieldRef.current?.selectionStart || newMessage.length;
        setMessage(newMessage);
        setCursorPosition(cursorPos);
    };

    const handleTextFieldKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    };

    const handleRemoveFile = (index: number) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <Box width='100%' display='flex' flexDirection='column' borderLeft='2px solid lightgray' alignItems='center'>
            {selectedFiles.length > 0 && (
                <Box
                    position="absolute"
                    top="-190px"
                    left="0"
                    right="0"
                    display="flex"
                    gap={2}
                    p={1}
                    // pr={10}
                    border='2px solid lightgray'
                    borderRadius={1}
                    bgcolor="background.paper"
                    zIndex={1}
                    maxWidth='800px'
                    maxHeight="200px"
                    sx={{ overflowX: 'auto' }}
                >
                    <Box display="flex" gap={2}>
                        {selectedFiles.map((file, index) => (
                            <Box key={index} display="flex" alignItems="center" gap={2} position="relative">
                                {file.type.startsWith("image/") ? (
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        width="auto"
                                        height="160"
                                        style={{ objectFit: "cover", borderRadius: "3px" }}
                                    />
                                ) : (
                                    <Box display="flex" alignItems="center">
                                        <AttachFileIcon />
                                        <Box ml={1}>{file.name}</Box>
                                    </Box>
                                )}
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: -6,
                                        right: -6,
                                        color: '#fff',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                        boxShadow: 2,
                                        borderRadius: '50%',
                                        padding: '2px',
                                    }}
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button
                            variant="contained"
                            sx={{ height: '160px', color:'black' }}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <AddIcon />
                        </Button>
                    </Box>
                </Box>
            )}
            <Box width='100%' display='flex' gap='6' justifyContent='space-evenly' alignItems='center'>
                <IconButton
                   sx={{ color: 'black' }}
                >
                <KeyboardVoiceIcon />
                </IconButton>
                <TextField
                    id="outlined"
                    size="small"
                    sx={{ width: '87%' }}
                    placeholder="Type Your Message"
                    variant="outlined"
                    value={message}
                    onChange={handleTextFieldChange}
                    onClick={handleTextFieldClick}
                    inputRef={textFieldRef}
                    onKeyPress={handleTextFieldKeyPress}
                    multiline
                    rows={1}
                />
                <Box position="relative">
                    <IconButton
                        ref={emojiButtonRef}
                        sx={{ color: 'black' }}
                        onClick={handleToggleEmojiPicker}
                    >
                        <EmojiEmotionsIcon />
                    </IconButton>
                    {emojiPickerVisible && (
                        <Box sx={{ position: 'absolute', bottom: '45px', right: '5px', zIndex: 2 }}>
                            <Picker onEmojiSelect={handleEmojiSelect} theme='light' />
                        </Box>
                    )}
                </Box>
                <IconButton
                    sx={{ color: 'black' }}
                    onClick={() => fileInputRef.current?.click()}
                >
                    <AttachFileIcon />
                </IconButton>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    multiple
                />
                <IconButton
                    sx={{ color: 'black' }}
                    onClick={handleSend}
                >
                    <SendIcon style={{ cursor: 'pointer' }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ChatInputArea;
