import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomData } from '../constants/constants';

interface RoomState {
  rooms: RoomData[];
  total: number;
  page: number;
  limit: number;
  loading: boolean;
  error: string | null;
}

const initialState: RoomState = {
  rooms: [],
  total: 0,
  page: 1,
  limit: 10,
  loading: false,
  error: null,
};

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRooms(
      state,
      action: PayloadAction<{ rooms: RoomData[]; total: number; page: number; limit: number }>,
    ) {
      state.rooms = action.payload.rooms;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setRooms, setLoading, setError } = roomSlice.actions;

export default roomSlice.reducer;
