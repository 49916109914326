import { FormControl, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { TenantContext } from "../App";
import { AllTenantsContext, UserDataContext } from "../routes/Main";
import isDeveloper from "../hooks/isDeveloper";
import { useSearchParams } from "react-router-dom";
import isAdmin from "../hooks/isAdmin";
import isSuperAdmin from "../hooks/isSuperAdmin";

const SelectTenant = () => {

    const { tenant, setTenant } = useContext(TenantContext);
    const { allTenants } = useContext(AllTenantsContext);
    const { userData } = useContext(UserDataContext);
    const developer = isDeveloper();
    const admin = isAdmin();
    const superAdmin = isSuperAdmin();

    const [searchParams, setQueryParams] = useSearchParams();

    return (
        <>
            {(superAdmin || admin || developer) &&
                <FormControl size='small'>
                    <Select
                        value={tenant}
                        onChange={(event) => {
                            setTenant(event.target.value);
                            const queryParams = Object.fromEntries(searchParams);
                            const newParams = { ...queryParams }
                            delete newParams.assignees
                            setQueryParams(newParams)
                        }}
                        sx={{ height: '30px', pb: '1px' }}
                    >
                        <MenuItem value='all'>All</MenuItem>
                        {developer ? userData?.data?.organizations?.filter((tenant: any) => tenant?.sts_link === 1)?.map((tenant: any) => {
                            return (
                                <MenuItem value={tenant?.alias} key={tenant?.alias}>
                                    {tenant?.ticket_id?.toUpperCase()}
                                </MenuItem>
                            )
                        })
                            :
                            allTenants?.data?.tenants?.filter((tenant: any) => tenant.organization.sts_link === 1).map((tenant: { organization: { ticket_id: string, alias: string }, id: string }) => {
                                return (
                                    <MenuItem value={tenant?.organization?.alias} key={tenant?.id}>
                                        {tenant?.organization?.ticket_id?.toUpperCase()}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            }
        </>
    )
}

export default SelectTenant
