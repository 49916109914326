import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ChatBoxHeading from './ChatBoxHeading';
import ChatArea from './ChatArea';
import ChatInputArea from './ChatInputArea';
import isMobile from '../../hooks/isMobile';
import { MessageData, RoomData } from '../../store/constants/constants';
import { AppDispatch } from '../../store/store';
import { useDispatch } from 'react-redux';
import { SocketContext } from '../../utils/SocketProvider';
import { sendMessageAction } from '../../store/message/messageActions';

interface ChatBoxProps {
  chat: RoomData | null;
  setSelectedChat: (chat: RoomData | null) => void;
}

const ChatBox: React.FC<ChatBoxProps> = ({ chat, setSelectedChat }) => {
  const mobileView = isMobile();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messages, setMessages] = useState<MessageData[]>(chat?.messages || []);

  useEffect(() => {
    if (chat) {
      setMessages(chat.messages || []);
    }
  }, [chat]);

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  const addMessage = (message: MessageData) => {
    if (socket) {
      dispatch(sendMessageAction({
        socket,
        message: message
      }));
    }
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const handleBackClick = () => {
    setSelectedChat(null); // Reset selected chat to null
  };

  if (!chat) {
    return <div style={{ margin: 'auto' }}>Select a chat to start messaging</div>;
  }

  return (
    <Box
      width='100%'
      bgcolor='white'
      borderRadius= {mobileView ? '0' : '20px'}
      height={mobileView ? '100vh' : '97dvh'}
      display='flex'
      position='relative'
      alignItems='center'
      flexDirection='column'
      justifyContent='space-between'
    >
      <ChatBoxHeading chat={chat} onBackClick={handleBackClick} />

      {mobileView ? (
        <Box sx={{ flex: 1, width: '100%', overflowY: 'auto' }}>
          <ChatArea chat={chat} />
        </Box>
      ) : (
      
          <Box
            sx={{
              flex: 1,
              width: '100%',
              borderTop: '2px solid lightgray',
              borderLeft: '2px solid lightgray',
              borderRadius: '3px 0 0 0',
              overflowY: 'auto',
            }}
          >
            <ChatArea chat={chat} />
          </Box>
          )}
          <ChatInputArea chat={chat} onSendMessage={addMessage} />

      
    </Box>
  );
};

export default ChatBox;
