import { useContext } from "react";
import { UserDataContext } from "../routes/Main";

export default function isAttendanceUser() {
    const { userData } = useContext(UserDataContext);

    return (
        (userData?.data?.organizations?.filter((org: { alias: string }) => org?.alias === 'sciever')?.length === 0)
            ? false
            : true
    )
}