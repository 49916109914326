import { Box, Button, Dialog, DialogContent, TextField, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useContext, useEffect, useState } from "react";
import { formattedTime } from "./CheckInOut";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import { failed, success } from "../../hooks/useToast";
import { CombinedReportContext } from "../../layout/Desktop/DesktopAttendanceLayout";
import { extractTime, getCurrentTime } from "./Hours";

export const getTodaysDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
}

const DailyAttendanceReport = (props: {
    user: number | undefined
}) => {

    const { combinedReport } = useContext(CombinedReportContext);

    const [allCheckIns, setAllCheckIns] = useState<any>()

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const [date, setDate] = useState(getTodaysDate());

    const getAllCheckIns = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/check-ins?sort=createdAt%3Adesc&populate=*&pagination[pageSize]=99&filters[date][$eq]=${date}`, {
            headers: headers
        })
            .then(res => (props?.user !== undefined && !combinedReport) ? setAllCheckIns(res?.data?.data?.filter((checkIn: { attributes: { attendance_user: { data: { id: number } } } }) => checkIn?.attributes?.attendance_user?.data?.id === props?.user)) : setAllCheckIns(res?.data?.data))
            .catch(() => failed())
    }

    useEffect(() => {
        getAllCheckIns();
    }, [props?.user, combinedReport, date])

    const [entryToEdit, setEntryToEdit] = useState<{
        attributes:
        {
            in_time: string,
            out_time: string,
            lunch_out: string,
            lunch_in: string
        },
        id: number
    }>();

    const [editOpen, setEditOpen] = useState(false);

    const handleEditClick = (entry: {
        attributes:
        {
            in_time: string,
            out_time: string,
            lunch_out: string,
            lunch_in: string
        },
        id: number
    }) => {
        setEditOpen(true)
        setEntryToEdit(entry)
    }

    const [checkIn, setCheckIn] = useState(entryToEdit?.attributes?.in_time)
    const [checkOut, setCheckOut] = useState(entryToEdit?.attributes?.out_time)
    const [lunchOut, setLunchOut] = useState(entryToEdit?.attributes?.lunch_out)
    const [lunchIn, setLunchIn] = useState(entryToEdit?.attributes?.lunch_in)

    useEffect(() => {
        setCheckIn(entryToEdit?.attributes?.in_time)
        setCheckOut(entryToEdit?.attributes?.out_time)
        setLunchOut(entryToEdit?.attributes?.lunch_out)
        setLunchIn(entryToEdit?.attributes?.lunch_in)
    }, [entryToEdit])

    const handleEdit = () => {
        const data = {
            data: {
                in_time: checkIn,
                out_time: checkOut !== '' ? checkOut : null,
                lunch_out: lunchOut !== '' ? lunchOut : null,
                lunch_in: lunchIn !== '' ? lunchIn : null
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/check-ins/${entryToEdit?.id}`, data, {
            headers: headers
        })
            .then(() => {
                getAllCheckIns();
                success();
                setEditOpen(false);
                setCheckIn(undefined);
                setCheckOut(undefined);
                setLunchOut(undefined);
                setLunchIn(undefined);
            })
            .catch(() => failed())
    }

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const columns: GridColDef[] = [
        {
            field: 'sn',
            renderHeader: () => {
                return <Typography fontWeight='bold'>SN</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            editable: false,
            renderCell: (params) => page * pageSize + params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Name</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.attendance_user?.data?.attributes?.name
        },
        {
            field: 'Check In',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Check In</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            sortable: false,
            renderCell: (rowData) => formattedTime(rowData?.row?.attributes?.in_time)
        },
        {
            field: 'Check Out',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Check Out</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.out_time ? formattedTime(rowData?.row?.attributes?.out_time) : '-'
        },
        {
            field: 'Lunch Out',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Lunch Out</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.lunch_out ? formattedTime(rowData?.row?.attributes?.lunch_out) : '-'
        },
        {
            field: 'Lunch In',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Lunch In</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.lunch_in ? formattedTime(rowData?.row?.attributes?.lunch_in) : '-'
        },
        {
            field: 'Hours',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Hours</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.in_time ? extractTime(rowData?.row?.attributes?.in_time, (rowData?.row?.attributes?.out_time !== null ? rowData?.row?.attributes?.out_time : getCurrentTime())) : '-'
        },
        {
            field: 'actions',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Actions</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 4,
            editable: false,
            renderCell: (row) => {
                return <EditIcon onClick={() => handleEditClick(row?.row)} style={{ height: '18px', cursor: 'pointer' }} />
            },
        },
    ];

    return (
        <>
            <Box position='absolute' right='25px' top='15px' width='166px'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker value={getTodaysDate() !== undefined && dayjs(getTodaysDate())} onChange={(e: any) => setDate(`${e?.$y}-${JSON.stringify(e?.$M + 1)?.padStart(2, '0')}-${JSON.stringify(e?.$D).padStart(2, '0')}`)} label="Date" format='DD/MM/YYYY' slotProps={{ textField: { size: 'small' } }} />
                </LocalizationProvider>
            </Box>

            <Box sx={{ bgcolor: 'white', height: '450px', mt: '-20px', mb: '-30px' }}>
                <DataGrid
                    rows={allCheckIns !== undefined && allCheckIns?.slice()?.sort((a: { attributes: { attendance_user: { data: { attributes: { name: string } } } } }, b: { attributes: { attendance_user: { data: { attributes: { name: string } } } } }) => a?.attributes?.attendance_user?.data?.attributes?.name?.localeCompare(b?.attributes?.attendance_user?.data?.attributes?.name))}
                    columns={columns}
                    rowHeight={40}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                    pageSizeOptions={[25, 50, 75, 100]}
                    disableColumnMenu
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '0.4em',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
                        {
                            borderColor: 'darkgray',
                            borderWidth: '1px',
                        },
                    }}
                    getRowId={(row) => {
                        return row?.id
                    }}
                    disableRowSelectionOnClick
                    onPaginationModelChange={(row) => {
                        setPage(row.page);
                        setPageSize(row.pageSize);
                    }}
                />
            </Box>

            <Dialog open={editOpen} onClose={() => {
                setEntryToEdit(undefined);
                setEditOpen(false);
                setCheckIn(undefined);
                setCheckOut(undefined);
                setLunchOut(undefined);
                setLunchIn(undefined);
            }}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                    <Box display='flex' flexDirection='column' gap='10px'>
                        <TextField size='small' value={checkIn} onChange={e => setCheckIn(e.target.value)} label='Check In' ></TextField>
                        <TextField size='small' value={checkOut} onChange={e => setCheckOut(e.target.value)} label='Check Out' ></TextField>
                        <TextField size='small' value={lunchOut} onChange={e => setLunchOut(e.target.value)} label='Lunch Out' ></TextField>
                        <TextField size='small' value={lunchIn} onChange={e => setLunchIn(e.target.value)} label='Lunch In' ></TextField>
                    </Box>

                    <Button onClick={() => handleEdit()} variant="contained" color='info' size='small' sx={{ width: '90px', mx: 'auto', mt: '5px', textTransform: 'none' }}>Save</Button>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DailyAttendanceReport
