import { Box, Button, Dialog, DialogContent, ImageList, ImageListItem, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import isMobile from '../../hooks/isMobile';
import { useState } from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { useNavigate } from 'react-router-dom';
import CreateTicket from '../CreateTicket';
import SelectTenant from '../SelectTenant';
import Bookmarks from '../Bookmarks';
import logo from '../../assets/images/sciever_logo_png.png';

const OverviewHeader = () => {
  const theme = useTheme();
  const mobile = isMobile();
  const navigate = useNavigate();

  const [addBoardOpen, setAddBoardOpen] = useState(false);

  const handleAddBoardClose = () => {
    setAddBoardOpen(false);
  }

  return (
    <>
      {mobile ? (
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            left: '15px',
            right: '15px',
            mt: '80px',
            pb: '10px',
            pt: '5px',
          }}
        >
          <Typography fontWeight='bold' noWrap flex={2}>
            Overview
          </Typography>

          <Typography flex={1} textAlign='end'>
            {new Date().toLocaleDateString('en-GB')}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box display='flex' gap='10px' alignItems='center'>
            <ImageList
              sx={{
                width: '45px',
                height: '45px',
                overflow: 'hidden',
                m: '0px'
              }}
              cols={1}
            >
              <ImageListItem>
                <img src={logo} alt='Sciever Inc. Logo' style={{ width: '45px', height: '45px' }} />
              </ImageListItem>
            </ImageList>

            <CreateTicket />

            <ToggleButtonGroup
              orientation="horizontal"
              value='module'
              size='small'
              sx={{ height: '35px' }}
            >
              <ToggleButton value="module">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value="list" onClick={() => navigate('/tickets')}>
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>

            <SelectTenant />

            <Typography variant='h6' fontWeight='bold' noWrap>
              Overview
            </Typography>

            <Bookmarks />

            {/* {displayTitle !== 'Overview' &&
              <Box color='#3751FF' onClick={() => setAddBoardOpen(true)} sx={{ cursor: 'pointer' }}>
                + Add Board
              </Box>
            } */}
          </Box>
          <Typography>{new Date().toLocaleDateString('en-GB')}</Typography>
        </Box >
      )}

      <Dialog open={addBoardOpen} onClose={() => handleAddBoardClose()}>
        <DialogContent>
          <Box display='flex' alignItems='center' gap='10px'>
            <TextField size='small' label='Board' />
            <Button
              variant='contained'
              color='info'
              size='small'
              sx={{ width: '50px', textTransform: 'none' }}>
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OverviewHeader;
