import axios from 'axios';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_strapiUrl}`,
});

httpClient.interceptors.request.use(
  async (config) => {
    Object.assign(config, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
      },
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default httpClient;
