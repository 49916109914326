import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../constants/constants';

interface UserState {
  user: UserData | null;
  users: UserData[];
}

const initialState: UserState = {
  user: null,
  users: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserData | null>) {
      state.user = action.payload;
    },
    setUsers(state, action: PayloadAction<UserData[]>) {
      state.users = action.payload;
    },
  },
});

export const {
  setUser,
  setUsers,
} = userSlice.actions;

export default userSlice.reducer;
