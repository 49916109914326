import { Box, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import axios from "axios"
import { failed, success } from "../../hooks/useToast"
import { useContext, useEffect, useState } from "react"
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { CombinedReportContext } from "../../layout/Desktop/DesktopAttendanceLayout"
import { UserDataContext } from "../../routes/Main"
import { formattedTime, getCurrentDate } from "./CheckInOut"
import { getCurrentTime } from "./Hours"

const LeaveReport = (props: {
    user: number | undefined
}) => {

    const { userData } = useContext(UserDataContext);

    const { combinedReport } = useContext(CombinedReportContext);

    const [leaves, setLeaves] = useState();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const getLeaves = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/leaves?populate=*&pagination[pageSize]=999`, {
            headers: headers
        })
            .then(res => {
                (props?.user !== undefined && !combinedReport) ? setLeaves(res?.data?.data?.filter((leave: { attributes: { attendance_user: { data: { id: number } } } }) => leave?.attributes?.attendance_user?.data?.id === props?.user)) : setLeaves(res?.data?.data)
            })
            .catch(() => failed())
    }

    const editLeave = (id: number, action: string) => {
        const data = {
            data: {
                status: action,
                checked_by: userData?.data?.name,
                checked_date: getCurrentDate(),
                checked_time: getCurrentTime()
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/leaves/${id}`, data, {
            headers: headers
        })
            .then(() => {
                success();
                getLeaves();
            })
            .catch(() => {
                failed();
            })
    }

    useEffect(() => {
        getLeaves()
    }, [props.user, combinedReport])

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const columns: GridColDef[] = [
        {
            field: 'sn',
            renderHeader: () => {
                return <Typography fontWeight='bold'>SN</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            editable: false,
            renderCell: (params) => page * pageSize + params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Name</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.attendance_user?.data?.attributes?.name
        },
        {
            field: 'from',
            renderHeader: () => {
                return <Typography fontWeight='bold'>From</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.from
        },
        {
            field: 'to',
            renderHeader: () => {
                return <Typography fontWeight='bold'>To</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.to !== null ? rowData?.row?.attributes?.to : '-'
        },
        {
            field: 'half',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Half</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.half_leave !== 'Full' ? rowData?.row?.attributes?.half_leave : '-'
        },
        {
            field: 'type',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Type</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.leave_type
        },
        {
            field: 'reason',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Reason</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.reason
        },
        {
            field: 'status',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Status</Typography>;
            },
            headerAlign: 'center',
            flex: 5,
            align: 'center',
            editable: false,
            renderCell: (row) => {
                if (row.row.attributes.status === 'Pending') {
                    if (combinedReport) {
                        return (
                            <Box display='flex' gap='5px'>
                                <DoneIcon onClick={() => editLeave(row.row.id, 'Approved')} color='success' sx={{ cursor: 'pointer' }} />
                                <CloseIcon onClick={() => editLeave(row.row.id, 'Rejected')} color='error' sx={{ cursor: 'pointer' }} />
                            </Box>
                        )
                    } else {
                        return <Box color='gray'>Pending</Box>
                    }
                } else if (row.row.attributes.status === 'Approved') {
                    return <Box color='green'>Approved</Box>
                } else {
                    return <Box color='red'>Rejected</Box>
                }
            }
        },
        {
            field: 'remarks',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Remarks</Typography>;
            },
            headerAlign: 'center',
            flex: 7,
            align: 'center',
            editable: false,
            renderCell: (row) => {
                if (row.row.attributes.status === 'Pending') {
                    return '-'
                } else {
                    return <Box>{row?.row?.attributes?.checked_by?.split(' ')[0]} ({formattedTime(row?.row?.attributes?.checked_time)}, {row?.row?.attributes?.checked_date?.split('-')[1]}/{row?.row?.attributes?.checked_date?.split('-')[2]})</Box>
                }
            }
        },
    ];

    return (
        <>
            <Box sx={{ bgcolor: 'white', height: '450px', mt: '-20px', mb: '-30px' }}>
                <DataGrid
                    rows={leaves !== undefined ? leaves : []}
                    columns={columns}
                    rowHeight={40}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                    pageSizeOptions={[25, 50, 75, 100]}
                    disableColumnMenu
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '0.4em',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
                        {
                            borderColor: 'darkgray',
                            borderWidth: '1px',
                        },
                    }}
                    getRowId={(row) => {
                        return row?.id
                    }}
                    disableRowSelectionOnClick
                    onPaginationModelChange={(row) => {
                        setPage(row.page);
                        setPageSize(row.pageSize);
                    }}
                />
            </Box>
        </>
    )
}

export default LeaveReport
