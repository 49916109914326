import { ITicketData } from '../../pages/HomePage';
import DesktopTicketForum from './DesktopTicketForum';

const TableIcons = (props: {
    selectedRowId: string,
    data: ITicketData
}) => {
    return (
        <>
            <DesktopTicketForum field={undefined} selectedRowId={props.selectedRowId} data={props.data} />
            {/* <DeleteTicket selectedRowId={props.selectedRowId} data={props.data} /> */}
        </>
    )
}

export default TableIcons
