import { useContext, useEffect, useState } from 'react';
import { Box, TextField, ListItemIcon, ListItemText, ListItemButton, Autocomplete, InputAdornment, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
// import { ChatRoomUserDataContext } from '../../App';
// import { getUserData } from '../Api';
import { CustomTabPanel } from '../../components/Attendance/Report';
import isMobile from '../../hooks/isMobile';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import { fetchUsers } from '../../store/user/userActions';
import CreateGroupDialog from './CreateGroupDialog';
import CreateGuestDialog from './CreateGuestDialog';
import { fetchRoomsByUser } from '../../store/RoomByID/roomAction';
import { UserDataContext } from '../../routes/Main';
import { RoomData, UserData } from '../../store/constants/constants';
import ChatItem from './ChatItem';

interface ChatListProps {
  onChatItemClick: (chat: RoomData) => void;
}

const ChatList: React.FC<ChatListProps> = (props: ChatListProps) => {
  const mobileView = isMobile();

  // Group add dialogue
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleOpenCreateGroupDialog = () => {
    setCreateGroupDialogOpen(true);
  };

  const handleCloseCreateGroupDialog = () => {
    setCreateGroupDialogOpen(false);
  };

  // Guest add dialogue
  const [createGuestDialogOpen, setCreateGuestDialogOpen] = useState(false);
  const handleOpenCreateGuestDialog = () => {
    setCreateGuestDialogOpen(true);
  };
  const handleCloseCreateGuestDialog = () => {
    setCreateGuestDialogOpen(false);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const { chatRoomUserData, setChatRoomUserData } = useContext(ChatRoomUserDataContext);

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  // Retrieving multiple users
  const users = useSelector((state: RootState) => state.user.users);

  useEffect(() => {
    if (socket) {
      dispatch(fetchUsers({ socket }));
    }
  }, [dispatch, socket]);

  const { userData } = useContext(UserDataContext);

  // Fetch rooms by userId (Assuming a static userId for now)
  const rooms = useSelector((state: RootState) => state.room.rooms);
  useEffect(() => {
    if (socket) {
      dispatch(fetchRoomsByUser({ socket, userId: userData.data.id })); // Replace with dynamic userId if needed
    }
  }, [dispatch, socket]);

  const flattenedRooms = rooms.flat();

  const membersRooms = flattenedRooms.filter((room) => room.roomType === 'individual');
  // const groupsRooms = flattenedRooms.filter((room) => room.roomType === 'group');

  // For avatar
  // const BASE_URL = 'https://chatroom.enitiation.com/';

  // const getAvatarUrl = (avatarPath: string | null): string => {
  //   if (!avatarPath) return ''; // Handle cases where avatar is undefined
  //   // If the avatarPath is already a full URL, return it as is
  //   if (avatarPath.startsWith('http')) {
  //     return avatarPath;
  //   }
  //   // Otherwise, prepend the base URL
  //   return `${BASE_URL}${avatarPath}`;
  // };
  
  // For displaying name of individual room
  // const currentUserId = userData.data.id;
  // const getParticipantName = (
  //   participants: RoomParticipant[],
  //   currentUserId: number,
  // ): React.ReactNode => {
  //   const otherParticipant = participants.find(
  //     (participant) => participant.user.id !== currentUserId,
  //   );
  //   return otherParticipant ? otherParticipant.user.userName : 'Unknown User';
  // };

  // testing message
//   const message1 = {
//     "roomId":1,
//     "userId":4,
// }

//   useEffect(() => {
//     if(socket) {
//       dispatch(getMessageAction({ socket , message1}));
//     }
//   }, [dispatch, socket]);

//   const messages = useSelector((state: RootState) => state.message.messages);
//   console.log('messages', messages)

  // const handleSendMessage = () => {
  //   if (socket) {
  //     dispatch(sendMessageAction({
  //       socket,
  //       message: {
  //         senderId: 3242,
  //         content,
  //         roomId,
  //       },
  //     }));
  //   }
  //   setContent('');
  // };
  // testing message

  // testing reply message
  // const handleReplyMessage = () => {
  //   if (socket) {
  //     dispatch(replyMessageAction({
  //       socket,
  //       reply: {
  //         messageId: 1,
  //         senderId: 2,
  //         content: 'hi',
  //         roomId: 1,
  //       },
  //     }));
  //   }
  // };
  // testing reply message

  // testing seen message
  // const handleMessageSeen = (messageId?: number) => {
  //   if (socket && messageId) {
  //     console.log('messageid', messageId)
  //     dispatch(messageSeenAction({ socket, seen: {messageId: messageId, userId: 2} }));
  //   }
  // };
  // testing seen message

  return (
    <>
      <Box
        maxHeight={mobileView ? '87vh' : '89vh'}
        // width={mobileView ? '90%' : '100%'}
        width='100%'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '15px',
        }}
      >

      <Autocomplete
          options={users as UserData[]}
          getOptionLabel={(user) => user.userName}
          inputValue={searchQuery}
          onInputChange={(event, newInputValue) => {
            setSearchQuery(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              variant="outlined"
              size="small"
              sx={{
                width: mobileView ? 'auto' : '100%',
                borderRadius: '15px',
                padding: mobileView ? '5px 20px' : '0',
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <Search sx={{ color: 'action.active', pointerEvents: 'none' }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, user) => (
            <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
              <Box sx={{ position: 'relative', marginRight: 2 }}>
                <Avatar
                  alt={user.userName}
                  src={`https://randomuser.me/api/portraits/lego/${(user.userId ?? Math.floor(Math.random() * 10)) % 10}.jpg`}
                  sx={{ width: 30, height: 30 }}
                />
                <CircleIcon
                  sx={{
                    color: user.isActive ? '#28a745' : '#dc3545',
                    fontSize: 10,
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    transform: 'translate(50%, 50%)',
                  }}
                />
              </Box>
              <Typography variant="body2">{user.userName}</Typography>
            </Box>
          )}
        />
        
        <Box sx={{ width: '100%' }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab
              label='Members'
              sx={{ width: '30%', color: value === 0 ? 'black' : 'lightgray' }}
              disableRipple
            />
            <Tab
              label='Groups'
              sx={{ width: '30%', color: value === 1 ? 'black' : 'lightgray' }}
              disableRipple
            />
            <Tab
              label='Guests'
              sx={{ width: '30%', color: value === 1 ? 'black' : 'lightgray' }}
              disableRipple
            />
          </Tabs>
        </Box>

        {/* member chat list */}
        <CustomTabPanel value={value} index={0}>
          <List>
            {/* {chatRoomUserData?.slice(0, 20).map((user: IChatroomUserData) => {
              return (
                <ChatItem
                  key={user.userId}
                  name={user.userName}
                  avatar={user.avatar}
                  message={user.message}
                  time={new Date(user.time).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
                  count={user.unseen_message}
                  unseen={!user.seen}
                  online={user.isActive}
                  onClick={() => props.onChatItemClick(user)}
                />
              );
            })} */}
            {/* {users?.slice(0, 20).map((user: UserData) => {
              return (
                <ChatItem
                  key={user.userId}
                  name={user.userName}
                  avatar={user.avatar || `https://randomuser.me/api/portraits/lego/${(user.userId ?? Math.floor(Math.random() * 10)) % 10}.jpg`}
                  message={user.message || "Hello there!"}
                  time={new Date(user.time || Date.now()).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
                  count={user.unseen_message || Math.floor(Math.random() * 10)}
                  unseen={!user.seen || Math.random() > 0.5}
                  online={user.isActive || Math.random() > 0.5}
                  onClick={() => props.onChatItemClick(user)}
                />
              );
            })} */}
            {membersRooms?.slice(0, 20).map((room: RoomData) => {
              return (
                <ChatItem
                  key={room.id}
                  name={room.roomParticipants.find((participant) => participant.user.userId !== userData.data.id)?.user?.userName || "Unknown User"}
                  avatar={room.roomParticipants.find((participant) => participant.user.userId !== userData.data.id)?.user?.profilePictureURL || `https://randomuser.me/api/portraits/lego/${(room.id ?? Math.floor(Math.random() * 10)) % 10}.jpg`}
                  lastmessage={room.lastMessage?.content || "no"}
                  time={new Date(room.lastMessage?.createdAt || Date.now()).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
                  count={room.unseenMessagesCount}
                  unseen={room.unseenMessagesCount > 0 ? true : false}
                  online={room.roomParticipants.find((participant) => participant.user.userId !== userData.data.id)?.user?.isActive}
                  onClick={() => props.onChatItemClick(room)}
                />
              );
            })}
            {/* {users.map((user) => (
              <li key={user.userId}>{user.userName}</li>
            ))} */}
            {/* {membersRooms.map((room) => (
              <ListItemButton
                key={room.id}
                onClick={() =>
                  props.onChatItemClick({
                    id: room.id,
                    avatar: room.avatar || '',
                    full_name: room.roomName,
                    message: '', // Or any default message if available
                    seen: false, // Set appropriately if the information is available
                    status: false, // Set appropriately if the information is available
                    time: room.updatedAt,
                    unseen_message: room.unseenMessagesCount,
                    conversation: '', // Assuming this field does not exist in RoomData
                  })
                }
              >
                <ListItemIcon>
                  <Avatar src={room.avatar || ''} />
                </ListItemIcon>
                <ListItemText primary={getParticipantName(room.roomParticipants, currentUserId)} />
              </ListItemButton>
            ))} */}
            {/* <ul>
              {messages?.map(msg => (
                <li key={msg.id}>
                  {msg.content}
                  <button onClick={() => handleMessageSeen(msg.id)}>Seen</button>
                </li>
              ))}
            </ul>
            <button onClick={handleSendMessage}>Send</button>
            <button onClick={handleReplyMessage}>Reply</button> */}
          </List>
        </CustomTabPanel>

        {/* groups chat list */}
        <CustomTabPanel value={value} index={1}>
          <List>
            <ListItemButton
              onClick={handleOpenCreateGroupDialog}
              sx={{
                borderBottom: '2px solid lightgray',
                '&:hover': {
                  backgroundColor: 'grey.300',
                },
              }}
            >
              <ListItemIcon>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary='Create Group' />
            </ListItemButton>
            {/* {groupsRooms.map((room) => (
              <ListItemButton
                key={room.id}
                onClick={() =>
                  props.onChatItemClick({
                    id: room.id,
                    avatar: getAvatarUrl(room.avatar),
                    full_name: room.roomName,
                    message: '', // Or any default message if available
                    seen: false, // Set appropriately if the information is available
                    status: false, // Set appropriately if the information is available
                    time: room.updatedAt,
                    unseen_message: room.unseenMessagesCount,
                    conversation: '', // Assuming this field does not exist in RoomData
                  })
                }
              >
                <ListItemIcon>
                  <Avatar src={getAvatarUrl(room.avatar)} />
                </ListItemIcon>
                <ListItemText primary={room.roomName} />
              </ListItemButton>
            ))} */}
          </List>
        </CustomTabPanel>

        {/* guests chat list */}
        <CustomTabPanel value={value} index={2}>
          <List>
            <ListItemButton
              onClick={handleOpenCreateGuestDialog}
              sx={{
                borderBottom: '2px solid lightgray',
                '&:hover': {
                  backgroundColor: 'grey.300', // Darker shade from theme palette
                },
              }}
            >
              <ListItemIcon>
                {/* <DescriptionIcon /> */}
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary='Create Group' />
            </ListItemButton>

            {/* <ChatItem
              name='Guests I'
              avatar='+'
              message='Good Morning to Guests'
              time='3:10 PM'
              count={2}
              unseen={false}
              online={true}
              onClick={() =>
                props.onChatItemClick({
                  userId: 3,
                  avatar: 'intern',
                  userName: 'Intern Group',
                  message: 'Good Morning to Interns',
                  seen: false,
                  isActive: true,
                  time: new Date().toString(),
                  unseen_message: 2,
                  conversation: '',
                })
              }
            />

            <ChatItem
              name='Guests II'
              avatar='intern'
              message='Welcome guests'
              time='3:10 PM'
              count={2}
              unseen={true}
              online={true}
              onClick={() =>
                props.onChatItemClick({
                  userId: 4,
                  avatar: 'intern',
                  userName: 'Intern Group',
                  message: 'Good Morning to Interns',
                  seen: false,
                  isActive: false,
                  time: new Date().toString(),
                  unseen_message: 2,
                  conversation: '',
                })
              }
            /> */}
          </List>
        </CustomTabPanel>
      </Box>
      <CreateGroupDialog open={createGroupDialogOpen} onClose={handleCloseCreateGroupDialog} />
      <CreateGuestDialog open={createGuestDialogOpen} onClose={handleCloseCreateGuestDialog} />
    </>
  );
};

ChatList.propTypes = {
  onChatItemClick: PropTypes.func.isRequired,
};

export default ChatList;
