import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Avatar, Typography, Modal, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import isMobile from '../../hooks/isMobile';
import { SocketContext } from '../../utils/SocketProvider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { getMessageAction } from '../../store/message/messageActions';
import { UserDataContext } from '../../routes/Main';
import { RoomData } from '../../store/constants/constants';

interface ChatAreaProps {
  chat: RoomData | null;
}

const ChatArea: React.FC<ChatAreaProps> = ({ chat }) => {
  const mobileView = isMobile();
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  // Scroll to bottom of chat when component first mounts or when messages change
  // useEffect(() => {
  //   scrollToBottom();
  // }, []);

  // Scroll to bottom of chat whenever 'messages' array changes
  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  // const scrollToBottom = () => {
  //   if (chatEndRef.current) {
  //     chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  // const handleFileClick = async (file: File) => {
  //   if (file.type.startsWith('image/')) {
  //     setSelectedFile(URL.createObjectURL(file));
  //   } else {
  //     const text = await file.text();
  //     setSelectedFile(text);
  //   }
  //   setOpen(true);
  // };

  const handleClose = () => {
    setSelectedFile(null);
    setOpen(false);
  };

  const isFirstMessage = (index: number) => {
    if (index === 0) return true;
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];
    return (
      currentMessage.sender !== previousMessage.sender ||
      !isSameDay(currentMessage?.createdAt, previousMessage?.createdAt)
    );
  };

  const isSameDay = (date1?: string, date2?: string) => {
    if (!date1 || !date2) return false;
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const getDateDisplay = (date?: string) => {
    if (!date) return '';

    const messageDate = new Date(date);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (isSameDay(messageDate.toISOString(), today.toISOString())) {
      return 'Today';
    } else if (isSameDay(messageDate.toISOString(), yesterday.toISOString())) {
      return 'Yesterday';
    } else {
      return messageDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  };

  const shouldShowDateHeader = (index: number) => {
    if (index === 0) return true;
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];
    return !isSameDay(currentMessage?.createdAt, previousMessage?.createdAt);
  };

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const { userData } = useContext(UserDataContext);

  const message1 = {
      "roomId": chat?.id,
      "userId": userData?.data?.id,
      "page": 1,
      "limit": 50,
  }

  const messages = useSelector((state: RootState) => state.message.messages);

  // console.log("message chat", messages)

  useEffect(() => {
    if(socket) {
      dispatch(getMessageAction({ socket , message1}));
    }
  }, [dispatch, socket, messages]);

  return (
    <Box
      sx={{
        py: 4
      }}
    >
      {messages.map((message, index) => {
        const isSender = message?.sender?.userId === userData.data.id;
        const showAvatar = !isSender && isFirstMessage(index);
        const showTimestamp = isFirstMessage(index);

        // Determine if this message needs to show date header
        const showDateHeader = shouldShowDateHeader(index);

        return (
          <Box key={index}>
            {/* Date header */}
            {showDateHeader && (
              <Box sx={{ textAlign: 'center' }}>
                <Divider variant='middle'>
                  <Typography
                    variant='body2'
                    sx={{
                      color: 'gray',
                      display: 'inline-block',
                      bgcolor: '#fff',
                      px: '5px',
                      borderRadius: '5px',
                    }}
                  >
                    {getDateDisplay(message?.createdAt)}
                  </Typography>
                </Divider>
              </Box>
            )}

            {/* Message content */}
            <Box display='flex' flexDirection={isSender ? 'row-reverse' : 'row'}>
              {showAvatar ? (
                <Box sx={{ mr: 1, padding: '4px 0', alignSelf: 'center' }}>
                  <Avatar
                    alt={chat?.roomParticipants.find((participant) => participant.user.userId !== userData.data.id)?.user?.userName}
                    src={chat?.roomParticipants.find((participant) => participant.user.userId !== userData.data.id)?.user?.profilePictureURL || `https://randomuser.me/api/portraits/lego/${(chat?.id ?? Math.floor(Math.random() * 10)) % 10}.jpg`}
                    sx={{ width: '30px', height: '30px' }}
                  />
                </Box>
              ) : (
                !isSender && <Box sx={{ width: '30px', height: '30px', mr: 1 }} />
              )}
              <Box display='flex' flexDirection='column' maxWidth={mobileView ? '65%' : '40%'}>
                {message.content && (
                  <Box
                    sx={{
                      bgcolor: isSender ? '#EAF0FF' : '#D3D3D3',
                      p: '6px 10px',
                      borderRadius: '3px',
                      mt: '4px',
                    }}
                  >
                    <Typography>{message.content}</Typography>
                  </Box>
                )}
              </Box>
              {showTimestamp && message?.createdAt && (
                <Typography
                  variant='body2'
                  sx={{
                    fontWeight: '300',
                    color: 'gray',
                    fontSize: '12px',
                    mx: 1,
                    alignSelf: 'center',
                  }}
                >
                  {new Date(message.createdAt).toLocaleTimeString('en-GB', { hour12: true })}
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
      <div ref={chatEndRef} />

      {/* Modal for displaying selected file */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='preview'
        aria-describedby='preview'
        closeAfterTransition
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'relative',
          }}
        >
          {selectedFile && selectedFile.startsWith('data:image') ? (
            <img src={selectedFile} alt='Preview' style={{ maxHeight: '90%', maxWidth: '90%' }} />
          ) : (
            <Box
              sx={{
                bgcolor: 'white',
                p: 1,
                borderRadius: 1,
                boxShadow: 24,
                maxHeight: '80vh',
                overflowY: 'auto',
              }}
            >
              <Typography>{selectedFile}</Typography>
            </Box>
          )}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChatArea;
