import { Box } from '@mui/material';
import isMobile from '../../../hooks/isMobile';
import DesktopTicketSection from './DesktopTicketSection';
import MobileTicketSection from './MobileTicketSection';
import TicketSection from './TicketSection';

interface ITicketsContainer {
  type: string;
}

const TicketsContainer = ({ type }: ITicketsContainer) => {
  const mobile = isMobile();

  return (
    <>
      {!mobile ? (
        <DesktopTicketSection>
          <TicketSection type={type} />
        </DesktopTicketSection>
      ) : (
        <Box minWidth='calc(100vw - 70px)'>
          <MobileTicketSection>
            <TicketSection type={type} />
          </MobileTicketSection>
        </Box>
      )}
    </>
  );
};

export default TicketsContainer;
