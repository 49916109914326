import { Box, Typography } from '@mui/material';
import moment from 'moment';
import isAdmin from '../hooks/isAdmin';
import isSuperAdmin from '../hooks/isSuperAdmin';
import Pills from './Pills';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ViewAttachments from './Desktop/ViewAttachments';
import { IChatData } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';

const MessageBox = (props: {
    message: IChatData
}) => {

    const admin = isAdmin();
    const superAdmin = isSuperAdmin();
    const developer = isDeveloper();

    const formatDate = (value: string, locale = 'en-GB') => {
        return new Date(value).toLocaleDateString(locale);
    }

    const adminMessage = props.message.attributes.admin;
    const message = props.message.attributes.message;
    const sender = props.message.attributes.sender;
    const createdAt = props.message.attributes.createdAt;
    const time = moment(createdAt).format("h:mm a");
    const date = formatDate(createdAt);

    const [attachments, setAttachments] = useState<any>();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/chats/${props?.message?.id}?populate=*`, {
            headers: headers
        })
            .then(res => setAttachments(res?.data?.data?.attributes?.attachments?.data))
    }, [props.message])

    const getUrl = (data: {
        attributes: {
            url: string
        }
    }) => {
        return data?.attributes?.url;
    }

    const getImages = (data: {
        attributes: {
            url: string
        }
    }) => {
        return `${process.env.REACT_APP_strapiImage}${data?.attributes?.url}`;
    }

    const url = attachments !== null && attachments?.length > 0 ? attachments?.map(getUrl) : 'undefined'
    const images = attachments !== null && attachments?.length > 0 ? attachments?.map(getImages) : 'undefined'

    return (
        <>
            <Box display='flex' p='10px' borderBottom='solid' borderColor='lightgray' position='relative' sx={{ borderWidth: '1px' }}>
                <Box display='flex' width='85%' flexDirection='column' gap='10px'>
                    <Box display='flex' gap='10px'>
                        <Typography fontWeight='bold'>
                            {((admin || superAdmin || developer) && adminMessage)
                                ? `${sender} (Admin)`
                                : ((!admin && !superAdmin && !developer) && adminMessage)
                                    ? 'Admin'
                                    : sender}
                        </Typography>
                        {props?.message?.attributes?.changed_to && <Pills pill={props?.message?.attributes?.changed_to} />}
                    </Box>
                    <Box display='flex' gap='5px'>
                        <Typography whiteSpace='pre-wrap' sx={{ wordBreak: 'break-word' }}>{message}</Typography>
                        {attachments !== null && attachments !== undefined ? <ViewAttachments url={url} images={images} name='SOS' /> : ''}
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column' textAlign='end' position='absolute' bottom='10px' right='0px'>
                    <Typography fontSize='12px'>{time}</Typography>
                    <Typography fontSize='12px'>{date}</Typography>
                </Box>
            </Box>

        </>
    )
}

export default MessageBox
