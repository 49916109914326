import { Box } from '@mui/material';

export default function DesktopTicketSection({ children }: { children: React.ReactNode }) {
  return (
    <Box
      position='relative'
      maxWidth='300px'
      minWidth='300px'
      bgcolor='white'
      p='10px'
      pt='0px'
      pb='15px'
      border='1px dashed gray'
      borderRadius='10px'
      display='flex'
      flexDirection='column'
      gap='10px'
      height='calc(100vh - 185px)'
      sx={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        '::-webkit-scrollbar': {
          width: '3px',
        },
      }}
    >
      {children}
    </Box>
  );
}
