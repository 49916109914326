import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CurrentUserContext, LoadingContext } from '../App';
import isAdmin from '../hooks/isAdmin';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { failed } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
import { UserDataContext, UserListContext } from '../routes/Main';
import Pills from './Pills';
import isMobile from '../hooks/isMobile';
import { catchedTrigger } from '../utils/novu';
import { IUserData, TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';

const EditType = (props: {
  fullId: string,
  idToChange: number | string,
  type: string,
  tenant: string
}) => {
  const admin = isAdmin();

  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const { userData } = useContext(UserDataContext);

  const { setLoading } = useContext(LoadingContext);

  const { fetchTickets } = useContext(DataContext);

  const { fetchTicketsHomePage } = useContext(TicketListContext);

  const { currentUser } = useContext(CurrentUserContext);

  const { userList } = useContext(UserListContext);

  const [open, setOpen] = useState(false);

  const [commentOpen, setCommentOpen] = useState(false);

  const [type, setType] = useState('');

  const handleTypeOpen = () => {
    setOpen(true);
  };

  const handleTypeClose = () => {
    setOpen(false);
  };

  const handleTypeComment = (pill: { name: string }) => {
    setCommentOpen(true);
    setType(pill.name);
  };

  const handleCommentClose = () => {
    setCommentOpen(false);
  };

  const typePills = [
    {
      name: 'BUG',
      hexCode: '#000000',
    },
    {
      name: 'FEATURE',
      hexCode: '#A142FF',
    },
    {
      name: 'TASK',
      hexCode: '#4A00E8',
    },
  ];


  const [relatedUsers, setRelatedUsers] = useState<any>();

  useEffect(() => {
    const users = userList?.data?.users?.users?.filter((user: IUserData) => user.main_tenant === props.tenant || user.roles[0].name === 'sa' || user.roles[0].name === 'ad')
    const notifUsers = users.map((user: IUserData) => {
      return {
        subscriberId: JSON.stringify(user.id),
        email: user.email
      }
    })
    setRelatedUsers(notifUsers);
  }, [userList, props.tenant])

  const [typeToChange, setTypeToChange] = useState<number | string>();

  const handleTypeToChange = (idToChange: number | string) => {
    setTypeToChange(idToChange);
  };

  const [comment, setComment] = useState('');

  const handleTypeChange = async () => {
    setLoading(true);

    const ticketInfo = {
      type: type.toLowerCase(),
    };

    const messageInfo = {
      message: comment,
      sender: userData !== undefined && userData.data.name,
      ticket: typeToChange,
      admin: (admin || superAdmin || developer) && true,
      changed_to: type
    };

    await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${typeToChange}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: ticketInfo }),
    })
      .then(async (response) => {
        if (response.status === 200) {
          await catchedTrigger('status-change', {
            // to: {
            //   subscriberId: '63d7751916e379fe65f29506'
            // },
            to: relatedUsers,
            payload: {
              ticket: props.fullId
            }
          })
        }
        recordLog(type, comment, 'ticket', props.fullId, currentUser?.id);
      })
      .catch(() => {
        failed();
      });

    await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: messageInfo }),
    })
      .then(() => {
        fetchTicketsHomePage();
        setLoading(false);
      })
      .catch(() => {
        // failed();
        setLoading(false);
      });

    // const addTicketResponse = await changeType.json()

    // const addChatResponse = await addComment.json()

    handleCommentClose();
    setComment('');
    fetchTickets();

    // console.log(addTicketResponse);

    // console.log(addChatResponse);
  };

  const mobile = isMobile();

  return (
    <>
      <Button
        onClick={() => {
          handleTypeOpen();
          handleTypeToChange(props.idToChange);
        }}
        sx={{ p: '0px', b: '0px' }}
      >
        <Pills pill={props.type} />
      </Button>

      <Dialog open={open} onClose={handleTypeClose} maxWidth='xl'>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Change type to:</Typography>
              <CloseIcon onClick={handleTypeClose} cursor='pointer' />
            </Box>
            <Stack direction='row' justifyContent='space-between' gap='10px'>
              {typePills.map((pill) => {
                return (
                  <Button
                    key={pill.name}
                    onClick={() => {
                      handleTypeComment(pill);
                      handleTypeClose();
                    }}
                    sx={{ p: '0px', b: '0px' }}
                  >
                    <Pills pill={pill.name} />
                  </Button>
                );
              })}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={commentOpen} onClose={handleCommentClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' gap='10px'>
                <Typography fontWeight='bold'>Comment:</Typography>
                <Pills pill={type} />
              </Box>
              <CloseIcon onClick={handleCommentClose} cursor='pointer' />
            </Box>
            <TextField
              multiline
              fullWidth
              rows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ mt: '-68px' }}>
                    <SendIcon onClick={handleTypeChange} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (!mobile) {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    handleTypeChange();
                    e.preventDefault();
                  }
                }
              }}
              value={comment}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditType;
