/* eslint-disable @typescript-eslint/no-explicit-any */

import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/system/ThemeProvider';
import { createContext, useState } from 'react';
import Main from './routes/Main';
import { theme } from './theme/theme';
import { useToast } from './hooks/useToast';
import { UserData } from './store/constants/constants';

export const RefreshContext = createContext<any>({});

export const FilterRefreshContext = createContext<any>({});

export const TicketsContext = createContext<any>({});

export const TenantContext = createContext<any>({});

export const LoadingContext = createContext<any>({});

export const FilterStatusContext = createContext<any>({});

export const CurrentUserContext = createContext<any>({});

export const ChatRoomUserDataContext = createContext<any>({});

export default function App() {
  const [refresh, setRefresh] = useState(true);
  const [filterRefresh, setFilterRefresh] = useState(true);
  const [tickets, setTickets] = useState('');
  const [tenant, setTenant] = useState('all');
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [currentUser, setCurrentUser] = useState();
  const [chatRoomUserData, setChatRoomUserData] = useState<UserData>();

  return (
    <>
      <ThemeProvider theme={theme}>
        <RefreshContext.Provider value={{ refresh, setRefresh }}>
          <FilterRefreshContext.Provider value={{ filterRefresh, setFilterRefresh }}>
            <TicketsContext.Provider value={{ tickets, setTickets }}>
              <TenantContext.Provider value={{ tenant, setTenant }}>
                <LoadingContext.Provider value={{ loading, setLoading }}>
                  <FilterStatusContext.Provider value={{ filterStatus, setFilterStatus }}>
                    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
                      <ChatRoomUserDataContext.Provider value={{ chatRoomUserData, setChatRoomUserData }}>
                        <CssBaseline />
                        <Main />
                      </ChatRoomUserDataContext.Provider>
                    </CurrentUserContext.Provider>
                  </FilterStatusContext.Provider>
                </LoadingContext.Provider>
              </TenantContext.Provider>
            </TicketsContext.Provider>
          </FilterRefreshContext.Provider>
        </RefreshContext.Provider>
      </ThemeProvider>

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      {useToast()}
    </>
  );
}
