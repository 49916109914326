import { Box, Stack } from '@mui/material';
import OverviewHeader from '../../components/Home Page/OverviewHeader';
import StatusBlock from '../../components/Home Page/StatusBlock';
import TicketsContainer from '../../components/Home Page/tickets/TicketsContainer';

const DesktopHomePageLayout = () => {
  return (
    <>
      <Box
        bgcolor='white'
        position='fixed'
        left='85px'
        right='0px'
        top='0px'
        bottom='-25px'
        p='15px 20px'
        overflow='hidden'
        // sx={{ borderTopLeftRadius: '10px' }}
      >
        <OverviewHeader />
        <StatusBlock />
        <Stack
          direction='row'
          mt='15px'
          justifyContent='space-between'
          gap='10px'
          pb='24px'
          sx={{ overflowX: 'scroll', overflowY: 'hidden' }}
        >
          <TicketsContainer type='Backlog' />
          <TicketsContainer type='On Hold' />
          <TicketsContainer type='Todo' />
          <TicketsContainer type='Redo' />
          <TicketsContainer type='In Progress' />
          <TicketsContainer type='PR' />
          <TicketsContainer type='PR Done' />
          <TicketsContainer type='Done' />
          <TicketsContainer type='UAT' />
          <TicketsContainer type='Production' />
          <TicketsContainer type='Closed' />
          <TicketsContainer type='Canceled' />
        </Stack>
      </Box>

      {/* <RecentActivities /> */}
    </>
  );
};

export default DesktopHomePageLayout;
