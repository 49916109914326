import { useState, useContext, useEffect } from 'react';
import { TextField, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl, Button, Typography } from "@mui/material";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AllTenantsContext, UserDataContext, UserListContext } from '../../routes/Main';
import isAdmin from '../../hooks/isAdmin';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { CurrentUserContext, RefreshContext, TenantContext } from '../../App';
import { failed } from '../../hooks/useToast';
import { catchedTrigger } from '../../utils/novu';
import { IUserData } from '../../pages/HomePage';
import isDeveloper from '../../hooks/isDeveloper';
import AssignDeveloper from '../AssignDeveloper';
import { recordLog } from '../../services/recordLog';

const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

const MobileCreateTicketForm = () => {

    const { userData } = useContext(UserDataContext);

    const { allTenants } = useContext(AllTenantsContext);

    const { currentUser } = useContext(CurrentUserContext);

    const { tenant, setTenant } = useContext(TenantContext);

    const { refresh, setRefresh } = useContext(RefreshContext);

    const toggleRefresh = () => {
        refresh ? setRefresh(false) : setRefresh(true)
    }

    const admin = isAdmin();

    const superAdmin = isSuperAdmin();

    const developer = isDeveloper();

    const [type, setType] = useState('task');
    const [priority, setPriority] = useState('high');
    const [environment, setEnvironment] = useState('Sandbox');
    const [description, setDescription] = useState('');
    const [assignees, setAssignees] = useState<string[] | null>(null);
    const [files, setFiles] = useState<any>([]);

    const [createTicketTenant, setCreateTicketTenant] = useState<string>();

    useEffect(() => {
        setCreateTicketTenant(tenant === 'all' ? 'enitiation' : tenant)
    }, [refresh])

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };

    const handlePriorityChange = (event: SelectChangeEvent) => {
        setPriority(event.target.value as string);
    };

    const handleEnvironmentChange = (event: SelectChangeEvent) => {
        setEnvironment(event.target.value as string);
    };

    const [ticketId, setTicketId] = useState('');

    useEffect(() => {
        if (superAdmin || admin || developer) {
            setTicketId(allTenants?.data?.tenants?.filter((tenant1: { id: string }) => tenant1?.id === createTicketTenant)[0]?.organization?.ticket_id);
        } else {
            setTicketId(allTenants?.data?.tenants?.filter((tenant1: { id: string }) => tenant1?.id === userData?.data?.organizations[0]?.alias)[0]?.organization?.ticket_id);
        }
    }, [allTenants, tenant, createTicketTenant])

    const navigate = useNavigate();

    const { userList } = useContext(UserListContext);

    const [relatedUsers, setRelatedUsers] = useState<any>();

    useEffect(() => {
        const users = userList?.data?.users?.users?.filter((user: IUserData) => user.main_tenant === ((admin || superAdmin || developer) ? createTicketTenant : userData !== undefined && userData.data.organizations[0]?.alias) || user.roles[0].name === 'sa' || user.roles[0].name === 'ad')
        const notifUsers = users?.map((user: IUserData) => {
            return {
                subscriberId: JSON.stringify(user.id),
                email: user.email
            }
        })
        setRelatedUsers(notifUsers);
    }, [userList, admin, superAdmin, developer, createTicketTenant, userData])

    const createTicket = async () => {

        const formData = new FormData()

        Array.from(files).forEach((file: any) => {
            formData.append('files', file)
            formData.append('ref', 'ticket');
            formData.append('field', "attachment");
        })

        axios.post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
            headers: headers
        })
            .then((response) => {
                const attachments: number[] = []
                response.data.map((imageId: { id: number }) => {
                    attachments.push(imageId.id)
                })
                const ticketInfo = {
                    data: {
                        description: description,
                        type: type,
                        priority: priority,
                        status: priority === 'highest' ? 'todo' : 'backlog',
                        environment: environment,
                        main_tenant: (admin || superAdmin || developer) ? createTicketTenant : userData !== undefined && userData.data.organizations[0]?.alias,
                        attachment: attachments,
                        assignees: assignees
                    }
                };
                createTicketService(ticketInfo);
            }).catch(() => {
                const ticketInfo = {
                    data: {
                        description: description,
                        type: type,
                        priority: priority,
                        status: priority === 'highest' ? 'todo' : 'backlog',
                        environment: environment,
                        main_tenant: (admin || superAdmin || developer) ? createTicketTenant : userData !== undefined && userData.data.organizations[0]?.alias,
                        assignees: assignees
                    }
                };
                createTicketService(ticketInfo);
            })

        setDescription('');
        setPriority('high');
        setType('task');
        setEnvironment('Sandbox')
        setFiles('');
        setAssignees(null);
    }

    const createTicketService = async (ticketInfo: {
        data: {
            description: string,
            type: string,
            priority: string,
            status: string,
            main_tenant: string,
            assignees: string[] | null
        }
    }) => {
        axios.post(`${process.env.REACT_APP_strapiUrl}/tickets`, ticketInfo, {
            headers: headers
        })
            .then(response => {
                // if (response.status === 200) {
                //     await catchedTrigger('create-ticket', {
                //         // to: {
                //         //   subscriberId: '63d7751916e379fe65f29506'
                //         // },
                //         to: relatedUsers,
                //         payload: {
                //             desc: response?.data?.data?.attributes?.description
                //         }
                //     })
                // }

                // const idInfo = {
                //     ticket_id: `${createTicketTenant?.slice(0, 3).toUpperCase()}-` + response.data.data.id
                // }

                const idInfo = {
                    ticket_id: `${ticketId?.slice(0, 3).toUpperCase()}-` + response.data.data.id
                }

                const message = {
                    message: response.data.data.attributes.description,
                    sender: (admin || superAdmin || developer) ? 'Admin' : userData !== undefined && userData.data.name,
                    ticket: response.data.data.id,
                    admin: admin || superAdmin || developer,
                    changed_to: 'Description'
                }
                putId(response.data.data.id, response.data.data.attributes.description, idInfo);
                firstMessage(message);
            }).catch(() => {
                // console.log(error);
                failed();
            })
    }

    const firstMessage = async (message: {
        message: string,
        sender: string,
        ticket: number,
        admin: boolean,
    }) => {
        await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: message })
        })
        // const addMessage = await add.json()
        // console.log(addMessage);
    }

    const putId = async (id: number, description: string, idInfo: object) => {
        axios.put(`${process.env.REACT_APP_strapiUrl}/tickets/${id}`, { data: idInfo }, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(async (response) => {
            if (response.status === 200) {
                await catchedTrigger('create-ticket', {
                    // to: {
                    //   subscriberId: '63d7751916e379fe65f29506'
                    // },
                    to: relatedUsers,
                    payload: {
                        ticket: response?.data?.data?.attributes?.ticket_id
                    }
                })
            }
            recordLog('Create', description, 'ticket', response?.data?.data?.attributes?.ticket_id, currentUser?.id)
        })
        // console.log(addResponse);
        toggleRefresh();
        setTenant(createTicketTenant);
        navigate('/tickets');
    }

    return (
        <>
            <Box display='flex' alignItems='center' gap='10px'>
                <ConfirmationNumberIcon /><Typography variant='h6' fontWeight='bold'>Create Ticket</Typography>

                {(superAdmin || admin || developer) &&
                    <Box>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                            <InputLabel>Select</InputLabel>
                            <Select
                                label='Select'
                                value={createTicketTenant}
                                onChange={event => {
                                    setCreateTicketTenant(event.target.value);
                                }}
                            >
                                {developer ? userData?.data?.organizations?.filter((tenant: any) => tenant?.sts_link === 1)?.map((tenant: any) => {
                                    return (
                                        <MenuItem value={tenant?.alias} key={tenant?.alias}>
                                            {tenant?.ticket_id?.toUpperCase()}
                                        </MenuItem>
                                    )
                                })
                                    :
                                    allTenants.data.tenants.filter((tenant: any) => tenant.organization.sts_link === 1).map((tenant: { organization: { alias: string, ticket_id: string }, id: string }) => {
                                        return <MenuItem value={tenant.organization.alias} key={tenant.id}>{tenant.organization.ticket_id.toUpperCase()}</MenuItem>
                                    })}
                            </Select>
                        </FormControl>
                    </Box>
                }
            </Box>

            <Box display='flex' flexDirection='column' gap='10px'>
                <TextField
                    id="outlined-multiline-static"
                    label="Description*"
                    multiline
                    fullWidth
                    rows={7}
                    onChange={e => setDescription(e.target.value)}
                    value={description}
                />

                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Type*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                        value={type}
                        onChange={handleTypeChange}
                    >
                        <MenuItem value='bug'>Bug</MenuItem>
                        <MenuItem value='task'>Task</MenuItem>
                        <MenuItem value='feature'>Feature</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Priority*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Priority"
                        value={priority}
                        onChange={handlePriorityChange}
                    >
                        <MenuItem value='highest'>Highest</MenuItem>
                        <MenuItem value='high'>High</MenuItem>
                        <MenuItem value='medium'>Medium</MenuItem>
                        <MenuItem value='low'>Low</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Environment*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Environment"
                        value={environment}
                        onChange={handleEnvironmentChange}
                    >
                        <MenuItem value='Sandbox'>Sandbox</MenuItem>
                        <MenuItem value='UAT'>UAT</MenuItem>
                        <MenuItem value='Production'>Production</MenuItem>
                    </Select>
                </FormControl>

                <AssignDeveloper idToChange='' assignees={assignees} setAssignees={setAssignees} main_tenant={createTicketTenant !== undefined ? createTicketTenant : ''} newTicket={true} />

                <input
                    type="file"
                    onChange={(e) => setFiles(e.target.files)}
                    multiple
                // accept='image/*'
                />

                {/* <AttachButton
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    sx={{ display: 'flex', justifyContent: 'space-between', pl: '13px', pr: '7px', width: '50%' }}
                >
                    <Typography>Attach images</Typography>
                    <AttachmentIcon />
                </AttachButton> */}

                <Button onClick={createTicket} variant="contained" color='info' size='small' sx={{ width: '90px', mx: 'auto', mt: '5px', textTransform: 'none' }}>Create</Button>
            </Box>
        </>
    )
}

export default MobileCreateTicketForm
