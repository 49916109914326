import { Box } from '@mui/material';
import OverviewHeader from '../../components/Home Page/OverviewHeader';
import TicketsContainer from '../../components/Home Page/tickets/TicketsContainer';
import StatusBlock from '../../components/Home Page/StatusBlock';

const MobileHomePageLayout = () => {
  // const [activeIndex, setActiveIndex] = useState(1);

  // const handleIndex = (index: number) => {
  //   setActiveIndex(index);
  // };

  // useEffect(() => {
  //   console.log({
  //     activeIndex,
  //   });
  // }, [activeIndex]);

  return (
    <>
      <Box display='flex' flexDirection='column' mx='15px' gap='20px' mb='65px'>
        {/* <OverviewHeader activeIndex={activeIndex} handleIndex={handleIndex} /> */}
        <OverviewHeader />
        <StatusBlock />
        {/* {activeIndex === 1 ? ( */}
        <Box display='flex' overflow='scroll' mx='2px' gap='10px' sx={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
          },
        }}>
          <TicketsContainer type='Backlog' />
          <TicketsContainer type='On Hold' />
          <TicketsContainer type='To Do' />
          <TicketsContainer type='Redo' />
          <TicketsContainer type='In Progress' />
          <TicketsContainer type='PR' />
          <TicketsContainer type='PR Done' />
          <TicketsContainer type='Done' />
          <TicketsContainer type='UAT' />
          <TicketsContainer type='Production' />
          <TicketsContainer type='Closed' />
          <TicketsContainer type='Canceled' />
        </Box>
        {/* ) : (
          <MobileStatusPage />
        )} */}
      </Box>
    </>
  );
};

export default MobileHomePageLayout;
