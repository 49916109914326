import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ErrorIcon from '@mui/icons-material/Error';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { CurrentUserContext, LoadingContext } from '../App';
import isAdmin from '../hooks/isAdmin';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { dynamicFailed, failed } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
import { UserDataContext, UserListContext } from '../routes/Main';
import Pills from './Pills';
import isMobile from '../hooks/isMobile';
import { catchedTrigger } from '../utils/novu';
import { ITicketData, IUserData, TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { recordLog } from '../services/recordLog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import { addMessageService } from '../services/ticketService';
import { useLocation } from 'react-router-dom';
import { githubActions } from '../services/githubActions';
import GitHubIcon from '@mui/icons-material/GitHub';

const EditStatus = (props: {
  tenant: string,
  fullId: string,
  idToChange: number | string,
  status: string,
  assignees: string[] | null,
  dueDate: string,
  branch: string,
  type: string,
}) => {

  const admin = isAdmin();

  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const { userData } = useContext(UserDataContext);
  const { userList } = useContext(UserListContext);
  const { setLoading } = useContext(LoadingContext);
  const { fetchTickets } = useContext(DataContext);
  const { fetchTicketsHomePage } = useContext(TicketListContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [open, setOpen] = useState(false);

  const [commentOpen, setCommentOpen] = useState(false);

  const [status, setStatus] = useState('');

  const handleStatusOpen = () => {
    setOpen(true);
  };

  const handleStatusClose = () => {
    setOpen(false);
  };

  const [dueDateOpen, setDueDateOpen] = useState(false);

  const handleStatusComment = (pill: { name: string }) => {
    setCommentOpen(true);
    setStatus(pill.name);
  };

  const handleCommentClose = () => {
    setCommentOpen(false);
    setComment('');
  };

  const statusPills = [
    {
      name: 'BACKLOG',
      hexCode: '#515B52',
    },
    {
      name: 'ON HOLD',
      hexCode: '#747c75',
    },
    {
      name: 'TODO',
      hexCode: '#93A795',
    },
    {
      name: 'REDO',
      hexCode: '#E30000',
    },
    {
      name: 'IN PROGRESS',
      hexCode: '#FF7A00',
    },
    {
      name: 'PR',
      hexCode: '#1468B6',
    },
    {
      name: 'PR DONE',
      hexCode: '#0085FF',
    },
    {
      name: 'DONE',
      hexCode: '#0ED9CD',
    },
    {
      name: 'UAT',
      hexCode: '#00E309',
    },
    {
      name: 'PRODUCTION',
      hexCode: '#03C03C',
    },
    {
      name: 'CLOSED',
      hexCode: '#00800D',
    },
    {
      name: 'CANCELED',
      hexCode: '#000000',
    },
  ];

  const [statusToChange, setStatusToChange] = useState<number | string>();

  const handleStatusToChange = (idToChange: number | string) => {
    setStatusToChange(idToChange);
  };

  const [comment, setComment] = useState('');

  const [relatedUsers, setRelatedUsers] = useState<any>();

  const [date, setDate] = useState<any>()
  const [dueDate, setDueDate] = useState<any>();

  const [branch, setBranch] = useState<any>();

  const [files, setFiles] = useState<any>([]);

  const location = useLocation();

  const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

  const [wipAlert, setWipAlert] = useState(false);

  const wipCheck = (pill: { name: string }) => {
    axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=99&filters[status][$eq]=in progress`, {
      headers: headers
    })
      .then(res => {
        if (res?.data?.data?.filter((ticket: ITicketData) => ticket?.attributes?.assignees?.includes(userData?.data?.name))?.length === 0) {
          handleStatusComment(pill);
          handleStatusClose();
        } else {
          setWipAlert(true);
          handleStatusClose();
        }
      })
      .catch(() => failed())
  }

  useEffect(() => {
    const users = userList?.data?.users?.users?.filter((user: IUserData) => user.main_tenant === props.tenant || user.roles[0].name === 'sa' || user.roles[0].name === 'ad')
    const notifUsers = users.map((user: IUserData) => {
      return {
        subscriberId: JSON.stringify(user.id),
        email: user.email
      }
    })
    setRelatedUsers(notifUsers);
  }, [userList, props.tenant])

  const [githubRes, setGithubRes] = useState<string>();

  useEffect(() => {
    if (githubRes !== undefined) {
      if (githubRes === 'Validation Failed') {
        dynamicFailed('Branch not found!');
      }
      else {
        dynamicFailed(githubRes)
      }
    }
  }, [githubRes])

  const [cicdData, setCicdData] = useState<[{ attributes: { enabled: boolean, sandbox_branch: string, uat_branch: string, production_branch: string, repo: string } }]>();

  const getCicdData = (tenant: string) => {
    axios.get(`${process.env.REACT_APP_strapiUrl}/cicds?filters[tenant][$eq]=${tenant}&populate=*`, {
      headers: headers
    })
      .then(res => setCicdData(res?.data?.data))
      .catch(() => failed())
  }

  useEffect(() => {
    if (status === 'UAT' || status === 'DONE' || status === 'PRODUCTION' || status === 'IN PROGRESS') {
      getCicdData(props?.tenant)
    }
  }, [status, props?.tenant])

  useEffect(() => {
    if (status === 'IN PROGRESS') {
      setBranch(props?.branch)
    }
  }, [status, props?.branch])

  const extractRepoName = (url: string) => {
    if (!url) return '';
    const match = url.match(
      /^https?:\/\/(www\.)?github.com\/(?<owner>[\w.-]+)\/(?<name>[\w.-]+)/
    );
    if (!match || !(match.groups?.owner && match.groups?.name)) return '';
    return match.groups.name;
  }

  // const checkBranch = (repoName: string, branchName: string) => {

  //   const config = {
  //     headers: {
  //       Accept: 'application/vnd.github+json',
  //       Authorization: `Bearer ${process.env.REACT_APP_githubToken}`,
  //       'X-GitHub-Api-Version': '2022-11-28'
  //     }
  //   };

  //   axios.get(`https://api.github.com/repos/sciever-inc/${repoName}/branches`, config)
  //     .then(res => console.log(res?.data?.filter((branch: { name: string }) => branch?.name === branchName)))
  // }

  const [branchConfirmationOpen, setBranchConfirmationOpen] = useState(false);

  const branchConfirmation = () => {
    if (cicdData !== undefined && status === 'IN PROGRESS' && (developer || superAdmin) && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled && (branch === null || branch == '')) {
      setBranchConfirmationOpen(true);
    } else {
      handleStatusChange();
    }
  }

  const handleStatusChange = async () => {

    setLoading(true);

    if (status === 'DONE' && props?.branch !== null) {
      if (cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled) {
        // checkBranch(extractRepoName(cicdData[0]?.attributes?.repo), props?.branch)
        githubActions(setGithubRes, extractRepoName(cicdData[0]?.attributes?.repo), cicdData[0]?.attributes?.sandbox_branch, props?.branch);
      }
    }

    if (status === 'UAT') {
      if (cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled) {
        githubActions(setGithubRes, extractRepoName(cicdData[0]?.attributes?.repo), cicdData[0]?.attributes?.uat_branch, props?.branch);
      }
    }

    if (status === 'PRODUCTION') {
      if (cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled) {
        githubActions(setGithubRes, extractRepoName(cicdData[0]?.attributes?.repo), cicdData[0]?.attributes?.production_branch, props?.branch);
      }
    }

    const ticketInfo = (cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled && (props?.branch === null || props?.branch === '')) ?
      (props?.assignees === null && props?.dueDate === null && status === 'IN PROGRESS' && (developer || superAdmin)) ?
        {
          status: status.toLowerCase(),
          assignees: [userData?.data?.name],
          due_date: dueDate,
          branch: (branch !== null && branch !== '') ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}` : null
        }
        : (props?.assignees === null && props?.dueDate !== null && status === 'IN PROGRESS' && (developer || superAdmin)) ?
          {
            status: status.toLowerCase(),
            assignees: [userData?.data?.name],
            branch: (branch !== null && branch !== '') ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}` : null
          }
          : (props?.dueDate === null && props?.assignees !== null && status === 'IN PROGRESS' && (developer || superAdmin)) ?
            {
              status: status.toLowerCase(),
              due_date: dueDate,
              branch: (branch !== null && branch !== '') ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}` : null
            }
            : (props?.dueDate !== null && props?.assignees !== null && status === 'IN PROGRESS' && (developer || superAdmin)) ?
              {
                status: status.toLowerCase(),
                branch: (branch !== null && branch !== '') ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}` : null
              }
              :
              {
                status: status.toLowerCase()
              }
      :
      (props?.assignees === null && props?.dueDate === null && status === 'IN PROGRESS' && (developer || superAdmin)) ?
        {
          status: status.toLowerCase(),
          assignees: [userData?.data?.name],
          due_date: dueDate
        }
        : (props?.assignees === null && props?.dueDate !== null && status === 'IN PROGRESS' && (developer || superAdmin)) ?
          {
            status: status.toLowerCase(),
            assignees: [userData?.data?.name],
          }
          : (props?.dueDate === null && props?.assignees !== null && status === 'IN PROGRESS' && (developer || superAdmin)) ?
            {
              status: status.toLowerCase(),
              due_date: dueDate
            }
            :
            {
              status: status.toLowerCase(),
            }

    const formData = new FormData()

    Array.from(files).forEach((file: any) => {
      formData.append('files', file)
      formData.append('ref', 'chat');
      formData.append('field', "attachments");
    })

    await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${statusToChange}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: ticketInfo }),
    })
      .then(async (response) => {
        recordLog(status, comment, 'ticket', props.fullId, currentUser?.id);
        if (response.status === 200) {
          await catchedTrigger('status-change', {
            // to: [
            //   { subscriberId: JSON.stringify(userData?.data?.id), email: userData?.data?.email },
            //   { subscriberId: '63d7751916e379fe65f29506', email: 'ankit@scieverinc.com' }
            // ],
            to: relatedUsers,
            payload: {
              ticket: props.fullId
            }
          })
        }
      })
      .catch(() => {
        failed()
      });


    // const messageInfo = {
    //   message: comment,
    //   sender: userData !== undefined && userData.data.name,
    //   ticket: statusToChange,
    //   admin: (admin || superAdmin || developer) && true,
    //   changed_to: status
    // };

    axios.post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
      headers: headers
    })
      .then(async (response) => {
        const attachments: any = []
        response.data.map((imageId: { id: number }) => {
          attachments.push(imageId.id)
        })
        const messageInfo = {
          message: comment,
          sender: userData !== undefined && userData.data.name,
          ticket: statusToChange,
          admin: admin || superAdmin || developer,
          attachments: attachments,
          changed_to: status
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false))
        if (response2.data.data) {
          location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
          setComment('');
          setLoading(false);
          setFiles([]);
        }
      }).catch(async () => {
        const messageInfo = {
          message: comment,
          sender: userData !== undefined && userData.data.name,
          ticket: statusToChange,
          admin: admin || superAdmin || developer,
          changed_to: status
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false))
        if (response2.data.data) {
          location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
          setComment('');
          setLoading(false);
          setFiles([]);
        }
      })

    // await fetch(`${strapiUrl}/chats`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ data: messageInfo }),
    // })
    //   .then(() => {
    //     fetchTicketsHomePage();
    //     setLoading(false)
    //   })
    //   .catch(() => {
    //     // failed()
    //     setLoading(false);
    //   });

    // const addTicketResponse = await changeStatus.json()

    // const addChatResponse = await addComment.json()

    handleCommentClose();
    setComment('');
    fetchTickets();
    // console.log(addTicketResponse);

    // console.log(addChatResponse);
  };

  const mobile = isMobile();

  const handleDate = () => {
    const dt = new Date(date);

    const year = dt.toLocaleString("default", { year: "numeric" });
    const month = dt.toLocaleString("default", { month: "2-digit" });
    const day = dt.toLocaleString("default", { day: "2-digit" });

    const formattedDate = year + "-" + month + "-" + day;

    if (formattedDate !== 'Invalid Date-Invalid Date-Invalid Date') {
      setDueDateOpen(false)
      setDueDate(formattedDate)
    }
  }

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleGithubClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGithubClose = () => {
    setAnchorEl(null);
  };

  const githubOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={() => {
          handleStatusOpen();
          handleStatusToChange(props.idToChange);
        }}
        sx={{ p: '0px', b: '0px' }}
      >
        <Pills pill={props.status} />
      </Button>

      <Dialog open={open} onClose={handleStatusClose} maxWidth='xl'>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Change status to:</Typography>
              <CloseIcon onClick={handleStatusClose} cursor='pointer' />
            </Box>
            <Stack direction='row' justifyContent='space-between' gap='10px'>
              {statusPills.map((pill) => {
                return (
                  <Button
                    key={pill.name}
                    onClick={() => {
                      if (pill.name === 'IN PROGRESS') {
                        wipCheck(pill);
                      } else if (
                        // pill.name !== 'PR' &&
                        pill.name !== 'PR DONE' &&
                        pill.name !== props.status.toUpperCase()
                      ) {
                        handleStatusComment(pill);
                        handleStatusClose();
                      } else {
                        return null;
                      }
                    }}
                    sx={{
                      p: '0px',
                      b: '0px',
                      cursor:
                        // pill?.name !== 'PR' &&
                        pill?.name !== 'PR DONE' &&
                          pill?.name !== props?.status?.toUpperCase()
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                  >
                    <Pills pill={pill.name} />
                  </Button>
                );
              })}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={commentOpen} onClose={handleCommentClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' gap='10px'>
                <Typography fontWeight='bold'>Comment:</Typography>
                <Pills pill={status} />
              </Box>
              <CloseIcon onClick={handleCommentClose} cursor='pointer' />
            </Box>
            <TextField
              multiline
              fullWidth
              rows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ display: 'flex', gap: '5px', mt: '-68px' }}>
                    {((developer || superAdmin) && status === 'IN PROGRESS' && cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled && props?.branch === null) && <GitHubIcon onClick={(e: any) => handleGithubClick(e)} color={(branch !== null && branch !== '') ? 'success' : 'secondary'} sx={{ cursor: 'pointer' }} />}
                    {((developer || superAdmin) && status === 'IN PROGRESS' && props?.dueDate === null) && <CalendarMonthIcon onClick={() => setDueDateOpen(true)} color={dueDate && 'success'} sx={{ cursor: 'pointer' }} />}
                    <input
                      ref={uploadInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => setFiles(e.target.files)}
                      multiple
                    // accept='image/*'
                    />
                    <Box display='flex'>
                      <AttachFileIcon onClick={() => uploadInputRef.current && uploadInputRef.current.click()} sx={{ cursor: 'pointer' }} />
                      {files?.length !== undefined && files?.length > 0 && <Box>{files?.length}</Box>}
                    </Box>
                    <SendIcon onClick={() => {
                      if (comment === '') {
                        failed();
                        setCommentOpen(false);
                      } else {
                        if ((developer || superAdmin) && status === 'IN PROGRESS' && props?.dueDate === null && dueDate === undefined) {
                          failed();
                          setCommentOpen(false);
                        } else {
                          branchConfirmation();
                        }
                      }
                    }} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (!mobile) {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    if (comment === '') {
                      failed();
                      setCommentOpen(false);
                    } else {
                      if ((developer || superAdmin) && status === 'IN PROGRESS' && props?.dueDate === null && dueDate === undefined) {
                        failed();
                        setCommentOpen(false);
                      } else {
                        branchConfirmation();
                        e.preventDefault();
                      }
                    }
                  }
                }
              }}
              value={comment}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={dueDateOpen} onClose={() => {
        setDueDateOpen(false)
        setDate(undefined)
        setDueDate(undefined)
      }} maxWidth='sm'>
        <Box display='flex' flexDirection='column' gap='10px' p='15px'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography>Enter Date:</Typography>
            <CloseIcon onClick={() => {
              setDueDateOpen(false)
              setDate(undefined)
              setDueDate(undefined)
            }} cursor='pointer' />
          </Box>

          <Box display='flex' alignItems='center' justifyContent='space-between' gap='5px'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker onChange={(x) => setDate(x)} disableOpenPicker format='DD/MM/YYYY' slotProps={{ textField: { size: 'small' } }} />
            </LocalizationProvider>

            <SendIcon cursor='pointer' onClick={() => {
              handleDate()
            }
            } />
          </Box>
        </Box>
      </Dialog >

      <Dialog open={wipAlert} onClose={() => setWipAlert(false)} maxWidth='lg'>
        <DialogContent>
          <Typography display='flex' gap='5px'>
            <ErrorIcon /> There is already a ticket in <Pills pill='in progress' />. Change the status before moving another ticket to <Pills pill='in progress' />.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog open={branchConfirmationOpen} onClose={() => setBranchConfirmationOpen(false)} maxWidth='lg'>
        <DialogContent>
          <Box display='flex' flexDirection='column' alignItems='center' gap='15px'>
            <Typography display='flex' gap='5px'>
              <ErrorIcon /> Are you sure you want to continue without git branch?
            </Typography>
            <Box display='flex' gap='10px'>
              <Button size='small' variant='contained' color='info' onClick={() => {
                setBranchConfirmationOpen(false);
                handleStatusChange()
              }}>Yes</Button>
              <Button size='small' variant='outlined' onClick={() => setBranchConfirmationOpen(false)}>No</Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog >

      <Popover
        open={githubOpen}
        anchorEl={anchorEl}
        onClose={handleGithubClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TextField
          label='Branch'
          defaultValue={branch}
          size='small'
          sx={{ m: 2 }}
          onChange={(e) => setBranch(e.target.value)}
        />
      </Popover>
    </>
  );
};

export default EditStatus;
