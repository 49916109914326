import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendMessage, getMessage, replyMessage, deleteMessage, getSeenMessage } from './messageSlice';
import { MessageData } from '../constants/constants';
import { Socket } from 'socket.io-client';

interface SendMessageArgs {
  socket: Socket;
  message: {
    senderId: number;
    content: string;
    roomId: number;
  };
}

interface ReplyMessageArgs {
  socket: Socket;
  reply: {
    messageId: number;
    senderId: number;
    content: string;
    roomId: number;
  };
}

interface DeleteMessageArgs {
  socket: Socket;
  messageId: number;
}

interface MessageSeenArgs {
  socket: Socket;
  seen: {
    messageId: number;
    userId: number;
  }
}

interface GetMessageArgs {
  message1: {
    roomId?: number;
    userId: number;
    total?: number;
    page?: number;
    limit?: number;
  }
  socket: Socket;
}

export const sendMessageAction = createAsyncThunk(
  'message/sendMessage',
  async ({ socket, message }: SendMessageArgs, { dispatch }) => {
    try {
      socket.emit('textMessage', message);
      dispatch(sendMessage(message));
    } catch (error) {
      console.error('Error in sending message:', error);
      throw error;
    }
  }
);

export const replyMessageAction = createAsyncThunk(
  'message/replyMessage',
  async ({ socket, reply }: ReplyMessageArgs, { dispatch }) => {
    try {
      socket.emit('replyMessage', reply);
      dispatch(replyMessage(reply));
    } catch (error) {
      console.error('Error in replying to message:', error);
      throw error;
    }
  }
);

export const deleteMessageAction = createAsyncThunk(
  'message/deleteMessage',
  async ({ socket, messageId }: DeleteMessageArgs, { dispatch }) => {
    try {
      socket.emit('deleteMessage', messageId);
      dispatch(deleteMessage(messageId));
    } catch (error) {
      console.error('Error in deleting message:', error);
      throw error;
    }
  }
);

export const messageSeenAction = createAsyncThunk(
  'message/getSeenMessage',
  async ({ socket, seen }: MessageSeenArgs, { dispatch }) => {
    try {
      socket.emit('getSeenMessage', seen);
      dispatch(getSeenMessage(seen.messageId));
    } catch (error) {
      console.error('Error in marking message as seen:', error);
      throw error;
    }
  }
);

export const getMessageAction = createAsyncThunk(
  'message/getMessage',
  async ({ socket, message1 }: GetMessageArgs, { dispatch }) => {
    try {
      socket.emit('getMessage', message1);
      socket.on('message', (data: { data: MessageData[]; total: number; page: number; limit: number }) => {
        dispatch(getMessage(data));
      });
    } catch (error) {
      console.error('Error in fetching messages:', error);
      throw error;
    }
  }
);
