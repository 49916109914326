import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogContent, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { a11yProps, CustomTabPanel } from './Report';
import axios from 'axios';
import { failed, success } from '../../hooks/useToast';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Policy = () => {

    const superAdmin = isSuperAdmin();

    const [open, setOpen] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const [editMode, setEditMode] = useState(false);

    const [editedValue, setEditedValue] = useState<string>();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        setEditMode(false);
        setEditedValue(undefined);
    };

    const [info, setInfo] = useState<any>(null);

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const fetchData = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/infos`, {
            headers: headers
        })
            .then(res => {
                setInfo(res?.data?.data[0]?.attributes)
            })
            .catch(() => failed())
    }

    const [generalHours, setGeneralHours] = useState<number>();
    const [editedHours, setEditedHours] = useState<number>();



    useEffect(() => {
        setEditedHours(generalHours)
    }, [generalHours])

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        setGeneralHours(info?.general_hours)
    }, [info])

    const [editHoursOpen, setEditHoursOpen] = useState(false)

    const editGeneralHours = () => {
        const data = {
            data: {
                general_hours: editedHours
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/infos/1`, data, {
            headers: headers
        })
            .then(() => {
                success();
                fetchData();
                setEditHoursOpen(false);
                setEditedHours(undefined);
            })
            .catch(() => failed())
    }

    const handleAttendanceEdit = () => {
        const data = {
            data: {
                attendance: editedValue
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/infos/1`, data, {
            headers: headers
        })
            .then(() => {
                success();
                fetchData();
                setEditMode(false);
                setEditedValue(undefined);
            })
            .catch(() => failed())
    }

    const handleLeaveEdit = () => {
        const data = {
            data: {
                leave: editedValue
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/infos/1`, data, {
            headers: headers
        })
            .then(() => {
                success();
                fetchData();
                setEditMode(false);
                setEditedValue(undefined);
            })
            .catch(() => failed())
    }

    // const handleHolidaysEdit = () => {
    //     const data = {
    //         data: {
    //             holidays: editedValue
    //         }
    //     }

    //     axios.put(`${strapiUrl}/infos/1`, data, {
    //         headers: headers
    //     })
    //         .then(() => {
    //             success();
    //             fetchData();
    //             setEditMode(false);
    //             setEditedValue(undefined);
    //         })
    //         .catch(() => failed())
    // }

    const [leaveTypesOpen, setLeaveTypesOpen] = useState(false);

    const [leaveTypes, setLeaveTypes] = useState<[
        {
            attributes: {
                name: string,
                code: string,
                days: number
            },
            id: number
        }
    ]>();

    const [newLeaveType, setNewLeaveType] = useState<string>();

    const [newLeaveCode, setNewLeaveCode] = useState<string>();

    const [leaveDays, setLeaveDays] = useState<number>();


    const getLeaveTypes = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/leave-types`, {
            headers: headers
        })
            .then(res => {
                setLeaveTypes(res?.data?.data)
            })
            .catch(() => failed())
    }

    useEffect(() => {
        getLeaveTypes();
    }, [])

    const addNewLeaveType = () => {
        const data = {
            data: {
                name: newLeaveType,
                code: newLeaveCode,
                days: leaveDays ? leaveDays : null
            }
        }

        axios.post(`${process.env.REACT_APP_strapiUrl}/leave-types`, data, {
            headers: headers
        })
            .then(() => {
                getLeaveTypes();
                setNewLeaveType(undefined);
                setNewLeaveCode(undefined);
                setLeaveTypesOpen(false);
                setLeaveDays(undefined);
                success();
            })
            .catch(() => {
                failed();
            })
    }

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [idToDelete, setIdToDelete] = useState<number>();

    const deleteType = () => {
        axios.delete(`${process.env.REACT_APP_strapiUrl}/leave-types/${idToDelete}`, {
            headers: headers
        })
            .then(() => {
                success();
                getLeaveTypes();
                setDeleteConfirm(false);
                setIdToDelete(undefined)
            })
            .catch(() => {
                setDeleteConfirm(false);
                setIdToDelete(undefined);
                failed();
            })
    }

    const [editTypeOpen, setEditTypeOpen] = useState(false)
    const [editedGeneralType, setEditedGeneralType] = useState<string>()
    const [editedGeneralDays, setEditedGeneralDays] = useState<string>()
    const [leaveTypeToEdit, setLeaveTypeToEdit] = useState<number>()

    const editLeaveType = () => {
        const data = {
            data: {
                name: editedGeneralType,
                days: parseInt(editedGeneralDays !== undefined ? editedGeneralDays : '')
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/leave-types/${leaveTypeToEdit}`, data, {
            headers: headers
        })
            .then(() => {
                success();
                getLeaveTypes()
                setLeaveTypesOpen(false);
                setEditTypeOpen(false);
                setEditedGeneralDays(undefined);
                setEditedGeneralType(undefined);
                setLeaveTypeToEdit(undefined);
            })
            .catch(() => {
                failed();
            })
    }

    const [expanded, setExpanded] = useState<string | false>('panel2');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            <VerifiedUserIcon onClick={() => setOpen(true)} sx={{ height: '22px', cursor: 'pointer' }} />

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='lg'>
                <Box p='15px' display='flex' flexDirection='column' gap='15px'>
                    <Box display='flex' alignItems='center' gap='10px'>
                        <VerifiedUserIcon sx={{ height: '22px' }} />
                        <Typography variant='h6'>Policy</Typography>
                    </Box>
                    <Box>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Attendance" {...a11yProps(0)} />
                                <Tab label="Leave" {...a11yProps(1)} />
                                <Tab label="Holidays" {...a11yProps(2)} />
                                {/* <Tab label="Ticket Activity" {...a11yProps(3)} /> */}
                                {/* <Tab label="Hours" {...a11yProps(2)} /> */}
                            </Tabs>

                            <CustomTabPanel value={tabValue} index={0}>
                                <Box display='flex' gap='5px' mb='10px' alignItems='center'>
                                    <Typography>General Working Hours:</Typography>
                                    {editHoursOpen ?
                                        <Box display='flex' alignItems='center' gap='5px'>
                                            <TextField defaultValue={generalHours} onChange={e => setEditedHours(parseInt(e.target.value))} size='small' sx={{ width: '50px' }} />
                                            <DoneIcon onClick={() => editGeneralHours()} sx={{ height: '20px', color: 'gray', cursor: 'pointer' }} />
                                            <CloseIcon
                                                onClick={() => {
                                                    setEditedHours(undefined);
                                                    setEditHoursOpen(false)
                                                }}
                                                sx={{ height: '20px', color: 'gray', cursor: 'pointer' }} />
                                        </Box>
                                        :
                                        <Box display='flex' alignItems='center'>
                                            <Typography>{generalHours}</Typography>
                                            {superAdmin && <EditIcon onClick={() => setEditHoursOpen(true)} sx={{ height: '16px', color: 'gray', mb: '-2px', ml: '3px', cursor: 'pointer' }} />}
                                        </Box>
                                    }
                                </Box>
                                {!editMode ?
                                    <Box width='700px' height='300px'>
                                        {(info?.attendance !== null && info?.attendance !== '') ? info?.attendance : 'No info!'}
                                        {superAdmin && <EditIcon onClick={() => setEditMode(true)} sx={{ height: '16px', color: 'gray', mb: '-2px', ml: '3px', cursor: 'pointer' }} />}
                                    </Box>
                                    :
                                    <Box width='700px' height='300px' display='flex' flexDirection='column' justifyContent='space-between' gap='10px'>
                                        <TextField defaultValue={info?.attendance} multiline rows={10} onChange={(e) => setEditedValue(e.target.value)} />
                                        <Box display='flex' justifyContent='center' gap='15px'>
                                            <Button onClick={() => handleAttendanceEdit()} variant="contained" color='info' size='small' sx={{ width: '90px', mt: '5px', textTransform: 'none' }}>Save</Button>
                                            <Button onClick={() => {
                                                setEditedValue(undefined);
                                                setEditMode(false);
                                            }}
                                                variant="contained" color='error' size='small' sx={{ width: '90px', mt: '5px', textTransform: 'none' }}>Cancel</Button>
                                        </Box>
                                    </Box>
                                }
                            </CustomTabPanel>

                            <CustomTabPanel value={tabValue} index={1}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disableGutters={true} >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Leave Types</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box width='400px' mb='15px'>
                                            <Box display='flex' flexDirection='column' gap='10px'>
                                                {leaveTypes?.map((type) => {
                                                    return (
                                                        <Box display='flex' alignItems='center' key={type?.id}>
                                                            <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
                                                                <Box display='flex' alignItems='center' gap='10px'>
                                                                    <Box width='8px' height='8px' borderRadius='4px' bgcolor='black' />
                                                                    <Typography>{type?.attributes?.name} ({type?.attributes?.code}) {type?.attributes?.days !== null && `[${type?.attributes?.days} days]`}</Typography>
                                                                </Box>
                                                                {(superAdmin && !leaveTypesOpen) &&
                                                                    <Box mb='-5px'>
                                                                        <EditIcon onClick={() => {
                                                                            setLeaveTypesOpen(true)
                                                                            setEditTypeOpen(true)
                                                                            setEditedGeneralType(type?.attributes?.name)
                                                                            setEditedGeneralDays(JSON.stringify(type?.attributes?.days))
                                                                            setLeaveTypeToEdit(type?.id)
                                                                        }} sx={{ height: '20px', color: 'gray', cursor: 'pointer', mr: '3px' }} />
                                                                        <DeleteIcon onClick={() => {
                                                                            setIdToDelete(type?.id)
                                                                            setDeleteConfirm(true)
                                                                        }} sx={{ color: 'gray', height: '20px', cursor: 'pointer' }} />
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                            {superAdmin &&
                                                (!leaveTypesOpen ?
                                                    <Box display='flex' alignItems='center' width='140px' gap='5px' mt='5px' color='gray' onClick={() => setLeaveTypesOpen(true)} sx={{ cursor: 'pointer' }}>
                                                        <Box sx={{ fontSize: '30px', mt: '-5px' }}>+</Box>
                                                        <Box>Add Leave Type</Box>
                                                    </Box>
                                                    :
                                                    <Box display='flex' alignItems='center' gap='15px' mt='10px'>
                                                        {editTypeOpen ?
                                                            <>
                                                                <Box display='flex' gap='5px'>
                                                                    <TextField value={editedGeneralType} onChange={(e) => setEditedGeneralType(e.target.value)} label='Leave Type*' size='small' sx={{ width: '175px' }} />
                                                                    <TextField value={editedGeneralDays} onChange={(e) => setEditedGeneralDays(e.target.value)} label='Days' size='small' sx={{ width: '75px' }} />
                                                                </Box>
                                                                <Box display='flex' gap='5px'>
                                                                    <DoneIcon onClick={() => {
                                                                        editLeaveType()
                                                                    }} sx={{ cursor: 'pointer' }} />
                                                                    <CloseIcon onClick={() => {
                                                                        setEditedGeneralDays(undefined);
                                                                        setEditedGeneralType(undefined);
                                                                        setLeaveTypeToEdit(undefined);
                                                                        setLeaveTypesOpen(false);
                                                                        setEditTypeOpen(false);
                                                                    }} sx={{ cursor: 'pointer' }} />
                                                                </Box>
                                                            </>
                                                            :
                                                            <>
                                                                <Box display='flex' gap='5px'>
                                                                    <TextField value={newLeaveType} onChange={(e) => setNewLeaveType(e.target.value)} label='Leave Type*' size='small' sx={{ width: '175px' }} />
                                                                    <TextField value={newLeaveCode} onChange={(e) => setNewLeaveCode(e.target.value)} label='Code*' size='small' sx={{ width: '75px' }} />
                                                                    <TextField value={leaveDays || ''} onChange={(e) => setLeaveDays(parseInt(e.target.value))} label='Days' size='small' sx={{ width: '75px' }} />
                                                                </Box>
                                                                <Box display='flex' gap='5px'>
                                                                    <DoneIcon onClick={() => {
                                                                        addNewLeaveType()
                                                                    }} sx={{ cursor: 'pointer' }} />
                                                                    <CloseIcon onClick={() => {
                                                                        setNewLeaveType(undefined);
                                                                        setNewLeaveCode(undefined);
                                                                        setLeaveDays(undefined);
                                                                        setLeaveTypesOpen(false);
                                                                    }} sx={{ cursor: 'pointer' }} />
                                                                </Box>
                                                            </>
                                                        }
                                                    </Box>)
                                            }
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disableGutters={true} >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Information</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {!editMode ?
                                            <Box width='700px' height='250px'>
                                                {(info?.leave !== null && info?.leave !== '') ? info?.leave : 'No info!'}
                                                {superAdmin && <EditIcon onClick={() => setEditMode(true)} sx={{ height: '16px', color: 'gray', mb: '-2px', ml: '3px', cursor: 'pointer' }} />}
                                            </Box>
                                            :
                                            <Box width='700px' height='250px' display='flex' flexDirection='column' justifyContent='space-between' gap='10px'>
                                                <TextField defaultValue={info?.leave} multiline rows={7} onChange={(e) => setEditedValue(e.target.value)} />
                                                <Box display='flex' justifyContent='center' gap='15px'>
                                                    <Button onClick={() => handleLeaveEdit()} variant="contained" color='info' size='small' sx={{ width: '90px', mt: '5px', textTransform: 'none' }}>Save</Button>
                                                    <Button onClick={() => {
                                                        setEditedValue(undefined);
                                                        setEditMode(false);
                                                    }}
                                                        variant="contained" color='error' size='small' sx={{ width: '90px', mt: '5px', textTransform: 'none' }}>Cancel</Button>
                                                </Box>
                                            </Box>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </CustomTabPanel>

                            <CustomTabPanel value={tabValue} index={2}>
                                {/* {!editMode ?
                                    <Box width='700px' height='300px'>
                                        {(info?.holidays !== null && info?.holidays !== '') ? info?.holidays : 'No info!'}
                                        {superAdmin && <EditIcon onClick={() => setEditMode(true)} sx={{ height: '16px', color: 'gray', mb: '-2px', ml: '3px', cursor: 'pointer' }} />}
                                    </Box>
                                    :
                                    <Box width='700px' height='300px' display='flex' flexDirection='column' justifyContent='space-between' gap='10px'>
                                        <TextField defaultValue={info?.holidays} multiline rows={10} onChange={(e) => setEditedValue(e.target.value)} />
                                        <Box display='flex' justifyContent='center' gap='15px'>
                                            <Button onClick={() => handleHolidaysEdit()} variant="contained" color='info' size='small' sx={{ width: '90px', mt: '5px', textTransform: 'none' }}>Save</Button>
                                            <Button onClick={() => {
                                                setEditedValue(undefined);
                                                setEditMode(false);
                                            }}
                                                variant="contained" color='error' size='small' sx={{ width: '90px', mt: '5px', textTransform: 'none' }}>Cancel</Button>
                                        </Box>
                                    </Box>
                                } */}

                                <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FKathmandu&showNav=1&showPrint=0&showCalendars=1&showTabs=1&src=ZTgxYjVjMWM4MjhiYmQ5ZjU3ZmY5NGI1MDgyY2ZhNTcyMjAyNTJkN2IyNWQ0ZTJkYjA3YjQ0MmYwMTJlZmRhMEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23B39DDB" style={{ border: 'solid 1px #777' }} width="600px" height="350px" />

                            </CustomTabPanel>

                        </Box>
                    </Box>
                </Box>
            </Dialog>

            <Dialog open={deleteConfirm} onClose={() => {
                setDeleteConfirm(false)
                setIdToDelete(undefined)
            }}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                    Are you sure?
                    <Box display='flex' gap='10px'>
                        <Button size='small' variant='contained' color='error' onClick={() => {
                            deleteType();
                        }}>Yes</Button>
                        <Button size='small' variant='outlined' onClick={() => {
                            setDeleteConfirm(false)
                            setIdToDelete(undefined)
                        }}>No</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Policy
