import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageData } from '../constants/constants';

interface MessageState {
  messages: MessageData[];
  total: number;
  page: number;
  limit: number;
}

const initialState: MessageState = {
  messages: [],
  total: 0,
  page: 1,
  limit: 10,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    sendMessage(state, action: PayloadAction<MessageData>) {
      state.messages.push(action.payload);
    },
    getMessage(state, action: PayloadAction<{ data: MessageData[]; total: number; page: number; limit: number }>) {
      state.messages = action.payload.data;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
    },
    replyMessage(state, action: PayloadAction<MessageData>) {
      const reply = action.payload;
      const message = state.messages.find(msg => msg.id === reply.messageId);
      if (message) {
        message.replies = [...(message.replies || []), reply];
      }
    },
    deleteMessage(state, action: PayloadAction<number>) {
      const message = state.messages.find(msg => msg.id === action.payload);
      console.log(message)
    },
    getSeenMessage(state, action: PayloadAction<number>) {
      const message = state.messages.find(msg => msg.id === action.payload);
      console.log(message)
    },
  },
});

export const {
  sendMessage,
  getMessage,
  replyMessage,
  deleteMessage,
  getSeenMessage,
} = messageSlice.actions;

export default messageSlice.reducer;