import { MeiliSearch } from 'meilisearch';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingContext, TenantContext, TicketsContext } from '../App';
import isAdmin from '../hooks/isAdmin';
import isMobile from '../hooks/isMobile';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { PageContext, PageSizeContext, UserDataContext } from '../routes/Main';
import { getTicketsService, getTicketsTenantSpecificService } from '../services/ticketService';
import DesktopTicketsPage from './Desktop/DesktopTicketsPage';
import { ITicketData } from './HomePage';
import MobileTicketsPage from './Mobile/MobileTicketsPage';
import isDeveloper from '../hooks/isDeveloper';

export const DataContext = createContext<any>({});

export const CountContext = createContext<any>({});

const TicketsPage = () => {
  const mobile = isMobile();
  const admin = isAdmin();
  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const { tickets } = useContext(TicketsContext);
  const { userData } = useContext(UserDataContext);
  const { tenant } = useContext(TenantContext);
  const { setLoading } = useContext(LoadingContext);
  const { page } = useContext(PageContext);
  const { pageSize } = useContext(PageSizeContext);

  const [count, setCount] = useState<number>();

  const [filters, setFilters] = useState<ITicketData[]>([]);
  const [searchparams] = useSearchParams();

  const client = new MeiliSearch({
    host: 'http://localhost:7700/',
  });

  const getTickets = async (status: string) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(userData.data.organizations[0]?.alias ?? false, page, pageSize, status);
    if (res?.data) {
      setCount(res?.data?.meta?.pagination?.total)
      setFilters(res?.data?.data);
      setLoading(false);
    }
  };

  const getTenantTickets = async (status: string) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = developer ? await getTicketsTenantSpecificService(tenant === 'all' ? userData?.data?.organizations : tenant, page, pageSize, status) : await getTicketsTenantSpecificService(tenant === 'all' ? null : tenant, page, pageSize, status)
    if (res?.data) {
      setCount(res?.data?.meta?.pagination?.total)
      setFilters(res?.data?.data);
      setLoading(false);
    }
  };

  const fetchTickets = () => {
    const status = searchparams.get('status') ?? '';

    if (!admin && !superAdmin && !developer) {
      getTickets(status);
      return;
    }
    getTenantTickets(status);
  };

  useEffect(() => {
    fetchTickets();
  }, [admin, superAdmin, developer, searchparams.get('status'), tenant, page, pageSize]);

  useEffect(() => {
    const query = searchparams.get('data');

    if (query && query !== null) {
      client.index('ticket').updateFilterableAttributes(['main_tenant']);
      client
        .index('ticket')
        .search(query)
        .then((results) => {
          const finalResult: any = [];
          results.hits.map((hit) => {
            hit.attachment != null
              ? (hit.attachment = {
                data: hit.attachment.map((item: any) => {
                  return { attributes: item };
                }),
              })
              : '';
            hit.chats != null
              ? (hit.chats = {
                data: hit.chats.map((item: any) => {
                  return { attributes: item };
                }),
              })
              : '';
            const finalResults = {
              attributes: hit,
            };
            finalResult.push(finalResults);
          });
          setFilters(finalResult);
          setLoading(false);
        });
    }
  }, [searchparams.get('data')]);

  const data = useMemo(() => {
    const type = searchparams.get('type');
    const priority = searchparams.get('priority');
    const assignees = searchparams.get('assignees');

    if (type && type !== '' && priority && priority !== '' && assignees && assignees !== '') {
      return filters?.filter(
        (el) =>
          el?.attributes?.type === decodeURI(type) &&
          el?.attributes?.priority === decodeURI(priority) &&
          el?.attributes?.assignees?.includes(decodeURI(assignees)),
      );
    }

    if (type && type !== '') {
      return filters?.filter((el) => el?.attributes?.type === decodeURI(type));
    }

    if (priority && priority !== '') {
      // console.log(filters?.filter((el) => el?.attributes?.priority === decodeURI(priority)));
      return filters?.filter((el) => el?.attributes?.priority === decodeURI(priority));
    }

    if (assignees && assignees !== '') {
      // console.log(filters?.map((el) => el?.attributes?.assignees?.filter(item => item?.name === decodeURI(assignees))).filter(element => element !== undefined));
      // return filters?.map((el) => el?.attributes?.assignees?.filter(item => item?.name === decodeURI(assignees)).filter(element => element !== undefined));
      return filters?.filter((el) => {
        return el?.attributes?.assignees !== null && el?.attributes?.assignees?.includes(decodeURI(assignees))
      });
    }

    return filters;
  }, [tickets, searchparams, filters, tenant]);

  return (
    <>
      <DataContext.Provider value={{ data, fetchTickets }}>
        <CountContext.Provider value={{ count, setCount }}>
          {mobile ? <MobileTicketsPage /> : <DesktopTicketsPage />}
        </CountContext.Provider>
      </DataContext.Provider>
    </>
  );
};

export default TicketsPage;
