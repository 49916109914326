import { Box, Dialog, TextField, Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { failed, success } from "../hooks/useToast";
import isSuperAdmin from "../hooks/isSuperAdmin";
import isDeveloper from "../hooks/isDeveloper";
import GitHubIcon from '@mui/icons-material/GitHub';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DataContext } from "../pages/TicketsPage";
import { TicketListContext } from "../pages/HomePage";

const EditBranch = (props: {
    branch: string,
    tenant: string,
    id: number | string,
    fullId: string,
    type: string
}) => {

    const superAdmin = isSuperAdmin();
    const developer = isDeveloper();

    const { fetchTickets } = useContext(DataContext);

    const { fetchTicketsHomePage } = useContext(TicketListContext);

    const [envBranch, setEnvBranch] = useState<string>('');

    const pullText = `git pull ${envBranch}`
    const checkOutText = `git checkout -b ${props?.branch}`

    const [copiedState, setCopiedState] = useState(false);

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const [cicdEnabled, setCicdEnabled] = useState(false);

    const getEnvBranch = (tenant: string) => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/cicds?filters[tenant][$eq]=${tenant}&populate=*`, {
            headers: headers
        })
            .then(res => {
                setEnvBranch(res?.data?.data[0]?.attributes?.sandbox_branch);
                if (res?.data?.data[0]?.attributes?.enabled === true) {
                    setCicdEnabled(true)
                }
            })
            .catch(() => failed())
    }

    useEffect(() => {
        getEnvBranch(props?.tenant);
    }, [props?.tenant])

    const [branchOpen, setBranchOpen] = useState(false);

    const [newBranch, setNewBranch] = useState<string>()

    const addBranch = () => {
        const data = {
            data: {
                branch: `${props?.type}/${props?.fullId?.toLowerCase()}-${newBranch}`
            }
        }

        if (newBranch !== undefined && newBranch !== '')
            axios.put(`${process.env.REACT_APP_strapiUrl}/tickets/${props?.id}`, data, {
                headers: headers
            })
                .then(() => {
                    setBranchOpen(false);
                    setNewBranch(undefined);
                    success();
                    location.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets()
                })
                .catch(() => {
                    setBranchOpen(false);
                    setNewBranch(undefined);
                    failed();
                })
    }

    return (
        <>
            {((developer || superAdmin) && cicdEnabled) ?
                props?.branch ?
                    <Box display='flex' flexDirection='column' gap='0px'>
                        <Typography display='flex' alignItems='center'>
                            {pullText}
                            <Tooltip title={copiedState ? 'Copied' : 'Copy'} onMouseLeave={() => setCopiedState(false)}>
                                <ContentCopyIcon sx={{ height: '15px', cursor: 'pointer' }} onClick={() => {
                                    navigator.clipboard.writeText(pullText);
                                    setCopiedState(true)
                                }} />
                            </Tooltip>
                        </Typography>
                        <Typography display='flex' alignItems='center'>
                            {checkOutText}
                            <Tooltip title={copiedState ? 'Copied' : 'Copy'} onMouseLeave={() => setCopiedState(false)}>
                                <ContentCopyIcon sx={{ height: '15px', cursor: 'pointer' }} onClick={() => {
                                    navigator.clipboard.writeText(checkOutText);
                                    setCopiedState(true)
                                }} />
                            </Tooltip>
                        </Typography>
                    </Box>
                    :
                    <Tooltip title='Branch'>
                        <GitHubIcon sx={{ cursor: 'pointer' }} onClick={() => setBranchOpen(true)} />
                    </Tooltip>
                :
                null
            }

            <Dialog open={branchOpen} onClose={() => {
                setBranchOpen(false);
                setNewBranch(undefined);
            }}>
                <Box p='15px'>
                    <Box display='flex' flexDirection='column' gap='10px'>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Box display='flex' gap='5px' fontSize='20px' alignItems='center'>
                                <GitHubIcon />Branch
                            </Box>

                            <Box display='flex' gap='5px'>
                                <DoneIcon onClick={() => addBranch()} sx={{ cursor: 'pointer' }} />
                                <CloseIcon onClick={() => {
                                    setBranchOpen(false);
                                    setNewBranch(undefined);
                                }} cursor='pointer' />
                            </Box>
                        </Box>
                        <TextField
                            size='small'
                            onChange={e => setNewBranch(e.target.value)}
                        />
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default EditBranch;
