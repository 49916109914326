import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TicketsContext } from '../../../App';
import { ITicketData, TicketListContext } from '../../../pages/HomePage';
import OverviewTicket from '../OverviewTicket';
import isMobile from '../../../hooks/isMobile';
// import axios from 'axios';
// import { failed } from '../../../hooks/useToast';
// import InfiniteScroll from "react-infinite-scroll-component";

interface ITicketSection {
  type: string;
}

export default function TicketSection({ type }: ITicketSection) {
  const { setTickets } = useContext(TicketsContext);
  const { ticketList } = useContext(TicketListContext);

  const navigate = useNavigate();

  const mobile = isMobile();

  // const [index, setIndex] = useState(1);
  // const [hasMore, setHasMore] = useState(true);

  const handleViewAll = () => {
    setTickets(type);
    navigate(`/tickets?status=${type?.toLowerCase()}`);
  };

  const tickets: ITicketData[] = useMemo(() => {
    return ticketList?.filter(
      (el: ITicketData) => el?.attributes?.status === (type?.toLowerCase()),
    );
  }, [ticketList, type]);

  const [data, setData] = useState(tickets);

  // const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

  useEffect(() => {
    setData(tickets)
  }, [tickets])

  // const fetchMoreData = () => {
  //   console.log('running')
  //   axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=${index}&pagination[pageSize]=10&filters[status][$eq]=${type.toLowerCase()}&populate=*&sort=updatedAt%3Adesc`, {
  //     headers: headers
  //   })
  //     .then(res => {
  //       setData((prevItems) => [...prevItems, ...res.data.data]);
  //       res.data.data.length > 0 ? setHasMore(true) : setHasMore(false);
  //     })
  //     .catch(() => failed())
  //   setIndex((prevIndex) => prevIndex + 1)
  // }

  return (
    <>
      <Box position='sticky' top='0px' display='flex' justifyContent='space-between' width={mobile ? '100%' : '271px'} px='5px' pt='10px' pb='10px' bgcolor='white'>
        <Typography>{type.toUpperCase()} ({data?.length})</Typography>
        {data?.length > 0 && (
          <Typography color='#3751FF' onClick={handleViewAll} sx={{ cursor: 'pointer' }}>
            View all
          </Typography>
        )}
      </Box>

      {/* <InfiniteScroll
        dataLength={data?.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      > */}
        <Box mt='-10px' display='flex' flexDirection='column' gap='10px'>
          {data?.length > 0 &&
            data.map((individualData) => (
              <OverviewTicket data={individualData} key={individualData.attributes.ticket_id} />
            ))}
        </Box>
      {/* </InfiniteScroll> */}
    </>
  );
}
