import { Box } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import OpenWithIcon from '@mui/icons-material/OpenWith';

const DownloadFiles = (props: any) => {

    // const handleDownload = async () => {
    //     try {
    //         const response = await fetch(props.file);
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);

    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = props.name;

    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);

    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         failed();
    //     }
    // }

    const handleDownload = () => {
        window.open(props.file, "_blank", "noreferrer");
    }

    return (
        <>
            <Box p='3px 3px 0px 3px' bgcolor='rgb(0,0,0,0.7)' sx={{ cursor: 'pointer' }} onClick={() => handleDownload()}>
                {props.icon === 'expand'
                    ?
                    <OpenWithIcon sx={{ color: 'white' }} />
                    :
                    < DownloadIcon sx={{ color: 'white' }} />
                }
            </Box>

            {/* <Box display='flex' alignItems='center' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                <AttachFileIcon sx={{ width: '17px', height: '17px', color: '#3751FF' }} />
                <Box color='#3751FF'>
                    {props?.data?.attachment?.data?.length}
                </Box>
            </Box>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <Box padding='20px' display='flex' flexDirection='column' gap='15px'>
                    {props?.data?.attachment?.data?.map((file: any) => {
                        return (
                            <Box display='flex' width='500px' justifyContent='space-between' key={file.id}>
                                {file.attributes.name}
                                <DownloadIcon onClick={() => handleDownload(file?.attributes?.url, file?.attributes?.name)} sx={{ cursor: 'pointer' }} />
                            </Box>
                        )
                    })}
                </Box>
            </Dialog> */}
        </>
    );
}

export default DownloadFiles;
