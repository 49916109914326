import { AttendanceBlock, CombinedReportContext } from '../../layout/Desktop/DesktopAttendanceLayout'
import AssessmentIcon from '@mui/icons-material/Assessment'
// import Info from './Info';
import { useContext, useState } from 'react';
import { Box, Dialog, DialogContent, Tab, Tabs, Typography } from '@mui/material';
import Hours from './Hours';
import MonthlyAttendanceReport from './MonthlyAttendanceReport';
import LeaveReport from './LeaveReport';
import TicketActivity from './TicketActivity';
import { CurrentUserContext } from '../../App';
import OverallActivity from './OverallActivity';

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{overflowY: 'scroll'}}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function Report() {

    const { currentUser } = useContext(CurrentUserContext);

    const { combinedReport } = useContext(CombinedReportContext);

    const [open, setOpen] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <AttendanceBlock>
                {/* <Info section='Report' /> */}
                <Box display='flex' gap='5px' ml='-3px' alignItems='center' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                    <AssessmentIcon sx={{ width: '25px', height: '25px' }} />
                    <b>Report</b>
                </Box>
                {!combinedReport &&
                    <Hours />
                }
            </AttendanceBlock>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
                <DialogContent>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Attendance" {...a11yProps(0)} />
                            <Tab label="Leave" {...a11yProps(1)} />
                            <Tab label="Ticket" {...a11yProps(2)} />
                            <Tab label="Overall" {...a11yProps(3)} />
                            {/* <Tab label="Hours" {...a11yProps(2)} /> */}
                        </Tabs>

                        <CustomTabPanel value={tabValue} index={0}>
                            <MonthlyAttendanceReport user={currentUser?.id} />
                        </CustomTabPanel>

                        <CustomTabPanel value={tabValue} index={1}>
                            <LeaveReport user={currentUser?.id} />
                        </CustomTabPanel>

                        <CustomTabPanel value={tabValue} index={2}>
                            <TicketActivity />
                        </CustomTabPanel>

                        <CustomTabPanel value={tabValue} index={3}>
                            <OverallActivity />
                        </CustomTabPanel>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Report
