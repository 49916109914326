import React from 'react';
import Sidebar from '../../components/Desktop/Sidebar';
import { Box } from '@mui/material';
// import OverviewHeader from '../../components/Home Page/OverviewHeader';

const DesktopMainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      {/* <DesktopHeader /> */}
      <Box display="flex" flexDirection="row" role="list">
        <Sidebar />
        {children}
      </Box>
    </>
  );
};

export default DesktopMainLayout;
