import { useContext, useEffect, useState } from 'react'
import { AttendanceBlock, CombinedReportContext } from '../../layout/Desktop/DesktopAttendanceLayout'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tab, Tabs, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Info from './Info';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';
import { failed, success } from '../../hooks/useToast';
import { CustomTabPanel, a11yProps } from './Report';
import LeaveReport from './LeaveReport';
import { CurrentUserContext } from '../../App';

function Leave() {

    const { combinedReport } = useContext(CombinedReportContext);

    const { currentUser } = useContext(CurrentUserContext);

    const [open, setOpen] = useState(false);

    const [leaveTypes, setLeaveTypes] = useState<[
        {
            attributes: {
                name: string,
                code: string,
                days: number
            },
            id: number
        }
    ]>();

    const [pendingLeaves, setPendingLeaves] = useState<[
        {
            attributes: {
                attendance_user: {
                    data: { attributes: { name: string } }
                },
                from: string,
                to: string
            },
            id: number
        }
    ]>();

    const [otherLeaves, setOtherLeaves] = useState<[
        {
            attributes: {
                attendance_user: {
                    data: { attributes: { name: string } }
                },
                from: string,
                to: string,
                status: string
            },
            id: number
        }
    ]>();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const getPendingLeaves = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/leaves?filters[status][$eq]=Pending&populate=*`, {
            headers: headers
        })
            .then(res => {
                setPendingLeaves(res?.data?.data)
            })
            .catch(() => failed())
    }

    const getOtherLeaves = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/leaves?filters[status][$eq]=Approved&filters[status][$eq]=Rejected&populate=*&pagination[pageSize]=999`, {
            headers: headers
        })
            .then(res => {
                setOtherLeaves(res?.data?.data)
            })
            .catch(() => failed())
    }

    useEffect(() => {
        getPendingLeaves();
        getOtherLeaves();
    }, [])


    const getLeaveTypes = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/leave-types`, {
            headers: headers
        })
            .then(res => {
                setLeaveTypes(res?.data?.data)
            })
            .catch(() => failed())
    }

    useEffect(() => {
        getLeaveTypes();
    }, [])

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const [fromDate, setFromDate] = useState<string>();
    const [toDate, setToDate] = useState<string>();
    const [halfLeave, setHalfLeave] = useState('Full');
    const [leaveType, setLeaveType] = useState<string>();
    const [leaveCode, setLeaveCode] = useState<string>();
    const [reason, setReason] = useState<string>();

    const requestLeave = () => {
        const data = {
            data: {
                attendance_user: JSON.parse(currentUser?.id),
                from: fromDate,
                to: toDate,
                half_leave: halfLeave,
                leave_type: leaveType,
                leave_code: leaveCode,
                reason: reason,
                status: 'Pending'
            }
        }

        axios.post(`${process.env.REACT_APP_strapiUrl}/leaves`, data, {
            headers: headers
        })
            .then(() => {
                success();
                setFromDate(undefined);
                setToDate(undefined);
                setHalfLeave('Full');
                setLeaveType(undefined);
                setLeaveCode(undefined);
                setReason(undefined);
                setOpen(false);
            })
            .catch(() => {
                failed();
            })
    }

    const [leaves, setLeaves] = useState<[{ attributes: { leave_code: string, status: string } }]>()

    const getLeaves = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/leaves?populate=*&pagination[pageSize]=999`, {
            headers: headers
        })
            .then(res => {
                setLeaves(res?.data?.data?.filter((leave: { attributes: { attendance_user: { data: { id: number } } } }) => leave?.attributes?.attendance_user?.data?.id === currentUser?.id))
            })
            .catch(() => failed())
    }

    useEffect(() => {
        getLeaves()
    }, [currentUser])

    const [editedLeaves, setEditedLeaves] = useState<string[] | null>([])

    const getEditedLeaves = () => {
        currentUser !== undefined &&
            axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users/${currentUser?.id}`, {
                headers: headers
            })
                .then(res => {
                    setEditedLeaves(res?.data?.data?.attributes?.edited_leaves)
                })
    }

    useEffect(() => {
        getEditedLeaves()
    }, [currentUser])

    return (
        <>
            <AttendanceBlock sx={{ height: '50%' }}>
                {/* <Info section='Leave' /> */}
                <Box display='flex' gap='5px' ml='-3px' alignItems='center' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                    <PersonOffIcon sx={{ width: '25px', height: '25px' }} />
                    <b>Leave</b>
                    {(combinedReport && (pendingLeaves !== undefined && pendingLeaves?.length > 0)) &&
                        <Box position='absolute' bgcolor='red' width='16px' height='16px' borderRadius='8px' top='10px' right='85px' color='white' fontWeight='bold' fontSize='10px' display='flex' justifyContent='center' alignItems='center'>{pendingLeaves?.length}</Box>
                    }
                </Box>

                {combinedReport &&
                    <>
                        <Box display='flex' flexDirection='column' gap='10px' mt='10px' width='100%' justifyContent='space-between' sx={{ overflowY: 'scroll' }}>
                            {pendingLeaves?.map(leave => {
                                return (
                                    <Box key={leave?.id} display='flex' justifyContent='space-between'>
                                        <Typography flex='2'>{leave?.attributes?.attendance_user?.data?.attributes?.name?.split(' ')[0]}</Typography>
                                        <Typography flex='3' display='flex' justifyContent='end'>{leave?.attributes?.from?.split('-')[1]}/{leave?.attributes?.from?.split('-')[2]}{leave?.attributes?.to !== null ? ` - ${leave?.attributes?.to?.split('-')[1]}/${leave?.attributes?.to?.split('-')[2]}` : ''}</Typography>
                                        <Box flex='1' display='flex' justifyContent='end' mr='3px'>
                                            <MoreHorizIcon sx={{ color: 'gray' }} />
                                        </Box>
                                    </Box>
                                )
                            })}

                            {otherLeaves?.map(leave => {
                                return (
                                    <Box key={leave?.id} display='flex' justifyContent='space-between'>
                                        <Typography flex='2'>{leave?.attributes?.attendance_user?.data?.attributes?.name?.split(' ')[0]}</Typography>
                                        <Typography flex='3' display='flex' justifyContent='end'>{leave?.attributes?.from?.split('-')[1]}/{leave?.attributes?.from?.split('-')[2]}{leave?.attributes?.to !== null ? ` - ${leave?.attributes?.to?.split('-')[1]}/${leave?.attributes?.to?.split('-')[2]}` : ''}</Typography>
                                        <Box flex='1' display='flex' justifyContent='end' mr='3px'>
                                            {leave?.attributes?.status === 'Approved' ?
                                                <DoneIcon color='success' />
                                                :
                                                <CloseIcon color='error' />
                                            }
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </>
                }
            </AttendanceBlock >

            <Dialog maxWidth='lg'
                fullWidth={combinedReport && tabValue === 0}
                open={open}
                onClose={() => {
                    setOpen(false);
                    setFromDate(undefined);
                    setToDate(undefined);
                    setHalfLeave('Full');
                    setLeaveType(undefined);
                    setReason(undefined);
                }}>
                <DialogContent>
                    {combinedReport ?
                        <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleTabChange}>
                                    <Tab label="Requests" {...a11yProps(0)} />
                                </Tabs>
                            </Box>

                            <CustomTabPanel value={tabValue} index={0}>
                                <LeaveReport user={undefined} />
                            </CustomTabPanel>
                        </>
                        :
                        <>
                            <Box display='flex' justifyContent='space-between' alignItems='center' mb='15px'>
                                <Box display='flex' alignItems='center' gap='8px'>
                                    <PersonOffIcon />
                                    <Typography variant='h6' fontWeight='bold'>Leave Request</Typography>
                                </Box>
                                <DialogActions>
                                    {/* <CloseIcon onClick={() => setOpen(false)} cursor='pointer' /> */}
                                </DialogActions>
                            </Box>
                            <Box display='flex' flexDirection='column' gap='15px'>
                                <Box display='flex' gap='10px'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker onChange={(e: any) => setFromDate(`${e?.$y}-${JSON.stringify(e?.$M + 1)?.padStart(2, '0')}-${JSON.stringify(e?.$D).padStart(2, '0')}`)} label="From*" />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker onChange={(e: any) => setToDate(`${e?.$y}-${JSON.stringify(e?.$M + 1)?.padStart(2, '0')}-${JSON.stringify(e?.$D).padStart(2, '0')}`)} label="To" />
                                    </LocalizationProvider>
                                </Box>

                                <FormControl>
                                    <RadioGroup onChange={e => setHalfLeave(e.target.value)}>
                                        <Box display='flex' gap='25px'>
                                            <FormControlLabel value="First" control={<Radio size='small' />} label="1st Half" />
                                            <FormControlLabel value="Second" control={<Radio size='small' />} label="2nd Half" />
                                        </Box>
                                    </RadioGroup>
                                </FormControl>

                                <FormControl>
                                    <Box display='flex' alignItems='center' gap='5px'>
                                        <FormLabel sx={{ color: 'black' }}>Type*</FormLabel>
                                    </Box>
                                    <RadioGroup onChange={e => {
                                        setLeaveType(e?.target?.value?.split('|')[0]);
                                        setLeaveCode(e?.target?.value?.split('|')[1]);
                                    }}>
                                        <Box display='flex' gap='35px' alignItems='center'>
                                            <Box display='flex' flexDirection='column'>
                                                {leaveTypes?.map((type) => {
                                                    const leavesTaken: number = leaves !== undefined ? leaves?.filter(leave => (leave?.attributes?.leave_code === type?.attributes?.code) && (leave?.attributes?.status === 'Approved'))?.length : 0
                                                    const newTotalLeaves: number = (editedLeaves?.filter(leave => leave.split(' ')[0] === type?.attributes?.code)[0]) !== undefined ? parseInt(editedLeaves?.filter(leave => leave.split(' ')[0] === type?.attributes?.code)[0]?.replace(/\D/g, "")) : 0
                                                    const totalLeaves: number = (editedLeaves?.filter(leave => leave.split(' ')[0] === type?.attributes?.code)?.length === 1) ? newTotalLeaves : type?.attributes?.days
                                                    const remainingLeaves: number = totalLeaves - leavesTaken
                                                    return (
                                                        <Box key={type?.id} display='flex' alignItems='center' mb='-5px'>
                                                            <FormControlLabel disabled={type?.attributes?.days !== null && remainingLeaves === 0} value={`${type?.attributes?.name}|${type?.attributes?.code}`} control={<Radio size='small' />} label={`${type?.attributes?.name} ${type?.attributes?.days !== null ? `[${remainingLeaves}/${totalLeaves} days]` : ''}`} />
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </RadioGroup>
                                </FormControl>

                                <TextField
                                    label="Reason*"
                                    fullWidth
                                    size='small'
                                    onChange={e => setReason(e.target.value)}
                                />
                            </Box>

                            <Box display='flex' mt='20px' justifyContent='center'>
                                <Button onClick={() => requestLeave()} size='small' variant='contained' color='info'>Request</Button>
                            </Box>
                        </>
                    }
                </DialogContent>
            </Dialog >
        </>
    )
}

export default Leave
