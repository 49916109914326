import Badge from '@mui/material/Badge';
import { Box, ListItemButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// import zIndex from '@mui/material/styles/zIndex';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      zIndex: '1',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}));

const ChatItem = (props: {
  name: string;
  lastmessage: string;
  time: string;
  count?: number;
  unseen: boolean;
  online?: boolean;
  avatar: string;
  onClick: () => void;
}) => {
  return (
    <ListItemButton
      onClick={props.onClick}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: "'Exo 2', sans-serif",
        borderBottom: '2px solid lightgray',
        '&:hover': {
          backgroundColor: 'grey.300', // Darker shade from theme palette
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledBadge
          sx={{
            '& .MuiBadge-badge': {
              color: props.online ? '#28a745' : '#000',
              backgroundColor: props.online ? '#28a745' : '#000',
            },
          }}
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant='dot'
        >
          <Avatar alt='Sharp' src={props.avatar} />
        </StyledBadge>
        <Box sx={{ marginLeft: '8px' }}>
          <Typography variant='body1' sx={{ fontWeight: props.unseen ? 'bold' : 'normal' }}>
            {props.name}
          </Typography>
          <Typography
            variant='body2'
            sx={{
              fontWeight: props.unseen ? 'bold' : 'normal',
              width: '180px',
              height: '20px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {props.lastmessage}
          </Typography>
        </Box>
      </Box>
      <Box
        alignItems='end'
        gap='4px'
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        {props.unseen ? (
          <Box
            width='16px'
            height='16px'
            borderRadius='8px'
            bgcolor='lightgray'
            display='flex'
            alignItems='center'
            sx={{ fontSize: '10px', fontFamily: "'Exo 2', sans-serif", fontWeight: 'bold' }}
            justifyContent='center'
          >
            {props.count}
          </Box>
        ) : (
          <Box height='16px' />
        )}
        <Typography
          variant='body1'
          sx={{ fontSize: '12px', fontWeight: props.unseen ? 'bold' : 'normal' }}
        >
          {props.time}
        </Typography>
      </Box>
    </ListItemButton>
  );
};

ChatItem.propTypes = {
  name: PropTypes.string.isRequired,
  lastmessage: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  count: PropTypes.number,
  unseen: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChatItem;
