import { useContext } from "react";
import { UserDataContext } from "../routes/Main";

export default function isDeveloper() {
    const { userData } = useContext(UserDataContext);
    return (
        (userData?.data?.roles[0]?.name === 'dev' || userData?.data?.roles[0]?.name === 'int' || userData?.data?.roles[0]?.name === 'off')
            ? true
            : false
    )
}
