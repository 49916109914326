import httpClient from './httpClient';

export const getTicketsService = async (mainTenant: string, page: number, pageSize: number, status?: string) => {
  let url = `/tickets?pagination[page]=${page+1}&pagination[pageSize]=${pageSize}&populate=*&sort=updatedAt%3Adesc&filters[main_tenant][$eq]=${mainTenant}`;

  if (status) {
    url = `${url}&filters[status][$eq]=${status}`;
  }
  return await httpClient.get(url);
};

export const getTicketsTenantSpecificService = async (tenant: string | [{ alias: string }], page: number, pageSize: number, status?: string) => {
  let url = `/tickets?pagination[page]=${page+1}&pagination[pageSize]=${pageSize}&populate=*&sort=updatedAt%3Adesc`;
  if (tenant) {
    if (typeof (tenant) === 'object') {
      url = `${url}${tenant.map(value => `&filters[main_tenant][$eq]=${value?.alias}`)}`.replace(/,/g, '');
    } else {
      url = `${url}&filters[main_tenant][$eq]=${tenant}`;
    }
  }

  if (status) {
    url = `${url}&filters[status][$eq]=${status}`;
  }

  return await httpClient.get(url);
};

export const addMessageService = async (payload: object) => {
  return await httpClient.post('/chats', payload);
};
