import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography, styled } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { AttendanceUsersContext } from '../../pages/AttendancePage';
import { CustomTabPanel, a11yProps } from './Report';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { failed, success } from '../../hooks/useToast';

export const EditField = styled(TextField)({
    width: '300px',
})

export interface IUserDetails {
    attributes: {
        name: string,
        official_email: string,
        title: string,
        contact: string,
        bank_acc_name: string,
        bank_acc_num: number,
        pan: string,
        daily_hours: number,
        monthly_hours: number,
        paid_leaves: number,
        join_date: string,
        address: string,
        emergency_name: string,
        emergency_num: string,
        bank_name: string,
        bank_branch: string,
        role: string,
        position: string,
        gender: string,
        termination_date: string,
        edited_hours: number
    }
}

const EditUser = (props: {
    table: boolean,
    id: number
}) => {

    const { setAttendanceUsers } = useContext(AttendanceUsersContext);

    const superAdmin = isSuperAdmin();

    const [open, setOpen] = useState(false);

    const [userDetails, setUserDetails] = useState<IUserDetails>();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const [name, setName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [title, setTitle] = useState<string>()
    const [contact, setContact] = useState<string>()
    const [joinDate, setJoinDate] = useState<string>()
    const [terminationDate, setTerminationDate] = useState<string>()
    const [bankAccName, setBankAccName] = useState<string>()
    const [bankAccNo, setBankAccNo] = useState<number>()
    const [pan, setPan] = useState<string>()
    const [dailyHours, setDailyHours] = useState<number>()
    const [monthlyHours, setMonthlyHours] = useState<number>()
    const [paidLeaves, setPaidLeaves] = useState<number>()
    const [address, setAddress] = useState<string>()
    const [emergencyName, setEmergencyName] = useState<string>()
    const [emergencyNum, setEmergencyNum] = useState<string>()
    const [bankName, setBankName] = useState<string>()
    const [bankBranch, setBankBranch] = useState<string>()
    const [role, setRole] = useState<string>()
    const [position, setPosition] = useState<string>()
    const [gender, setGender] = useState<string>()
    const [editedHours, setEditedHours] = useState<number>()

    const getUserDetails = (id: number) => {
        if (id !== undefined) {
            axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users/${id}`, {
                headers: headers
            })
                .then(res => {
                    setUserDetails(res?.data?.data)
                })
                .catch(() => failed())
        }
    }

    const getAttendanceUsers = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users?pagination[pageSize]=99`, {
            headers: headers
        })
            .then(res => {
                setAttendanceUsers(res?.data?.data)
            })
            .catch(() => failed())
    }

    const [generalHours, setGeneralHours] = useState<number>()

    const getGeneralHours = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/infos`, {
            headers: headers
        })
            .then(res => {

                setGeneralHours(res?.data?.data[0]?.attributes?.general_hours)
            })
            .catch(() => failed())
    }

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleClick = (id: number) => {
        setOpen(true);
        getUserDetails(id);
    }

    useEffect(() => {
        getUserDetails(props?.id)
    }, [props?.id])

    useEffect(() => {
        setName(userDetails?.attributes?.name)
        setEmail(userDetails?.attributes?.official_email)
        setTitle(userDetails?.attributes?.title)
        setContact(userDetails?.attributes?.contact)
        setJoinDate(userDetails?.attributes?.join_date)
        setBankAccName(userDetails?.attributes?.bank_acc_name)
        setBankAccNo(userDetails?.attributes?.bank_acc_num)
        setPan(userDetails?.attributes?.pan)
        setDailyHours(userDetails?.attributes?.daily_hours)
        setMonthlyHours(userDetails?.attributes?.monthly_hours)
        setPaidLeaves(userDetails?.attributes?.paid_leaves)
        setAddress(userDetails?.attributes?.address)
        setEmergencyName(userDetails?.attributes?.emergency_name)
        setEmergencyNum(userDetails?.attributes?.emergency_num)
        setBankName(userDetails?.attributes?.bank_name)
        setBankBranch(userDetails?.attributes?.bank_branch)
        setRole(userDetails?.attributes?.role)
        setPosition(userDetails?.attributes?.position)
        setTerminationDate(userDetails?.attributes?.termination_date)
        setGender(userDetails?.attributes?.gender)
        setEditedHours(userDetails?.attributes?.edited_hours)
    }, [userDetails])

    const [needToEditDays, setNeedToEditDays] = useState(false);

    const handleSave = (id: number) => {
        setOpen(false);

        const data = {
            data: {
                name: name ? name : null,
                official_email: email ? email : null,
                title: title ? title : null,
                contact: contact ? contact : null,
                bank_acc_name: bankAccName ? bankAccName : null,
                bank_acc_num: bankAccNo ? bankAccNo : null,
                pan: pan ? pan : null,
                daily_hours: dailyHours ? dailyHours : null,
                monthly_hours: monthlyHours ? monthlyHours : null,
                paid_leaves: paidLeaves ? paidLeaves : null,
                join_date: joinDate ? joinDate : null,
                address: address ? address : null,
                emergency_name: emergencyName ? emergencyName : null,
                emergency_num: emergencyNum ? emergencyNum : null,
                bank_name: bankName ? bankName : null,
                bank_branch: bankBranch ? bankBranch : null,
                role: role ? role : null,
                position: position ? position : null,
                termimation_date: terminationDate ? terminationDate : null,
                gender: gender ? gender : null,
                edited_hours: editedHours ? editedHours : null
            }
        }
        axios.put(`${process.env.REACT_APP_strapiUrl}/attendance-users/${id}`, data, {
            headers: headers
        })
            .then(() => {
                getUserDetails(id);
                !needToEditDays && success();
                getAttendanceUsers();
                needToEditDays && editDays();
            })
            .catch(() => failed())
    }

    const [leaveTypes, setLeaveTypes] = useState<[
        {
            attributes: {
                name: string,
                code: string,
                days: number
            },
            id: number
        }
    ]>();

    const getLeaveTypes = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/leave-types`, {
            headers: headers
        })
            .then(res => {
                setLeaveTypes(res?.data?.data)
            })
            .catch(() => failed())
    }

    useEffect(() => {
        getLeaveTypes();
        getGeneralHours();
    }, [])

    const [editedLeaves, setEditedLeaves] = useState<string[] | null>([])
    const [editedDays, setEditedDays] = useState<string[]>([])

    const getEditedLeaves = () => {
        props.id !== undefined &&
            axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users/${props?.id}`, {
                headers: headers
            })
                .then(res => {
                    setEditedLeaves(res?.data?.data?.attributes?.edited_leaves)
                })
    }

    useEffect(() => {
        getEditedLeaves()
    }, [props?.id])

    useEffect(() => {
        editedLeaves !== null && setEditedDays(editedLeaves)
    }, [editedLeaves])

    const removeDuplicates = (arr: string[]) => {
        const uniqueItems = [];
        const firstTwoWordsSet = new Set();

        for (const item of arr) {
            const [firstWord] = item.split(' ', 1);

            if (!firstTwoWordsSet.has(firstWord)) {
                uniqueItems.push(item);
                firstTwoWordsSet.add(firstWord);
            } else {
                // Remove the first occurrence of the matching item
                const index = arr.findIndex(element => {
                    const [firstWordExisting] = element.split(' ', 1);
                    return firstWordExisting === firstWord;
                });
                if (index !== -1) {
                    arr.splice(index, 1);
                }
            }
        }

        return uniqueItems;
    };

    const dataToPost = removeDuplicates(editedDays)

    const editDays = () => {
        const data = {
            data: {
                edited_leaves: dataToPost
            }
        }

        axios.put(`${process.env.REACT_APP_strapiUrl}/attendance-users/${props?.id}`, data, {
            headers: headers
        })
            .then(() => {
                success();
                setEditedDays([]);
                getEditedLeaves();
                setNeedToEditDays(false);
            })
            .catch(() => {
                failed();
            })
    }

    return (
        <>
            {props.table
                ?
                <EditIcon onClick={() => handleClick(props.id)} style={{ height: '18px', cursor: 'pointer' }} />
                :
                <Box display='flex' gap='5px' ml='-3px' alignItems='center' onClick={() => handleClick(props.id)} sx={{ cursor: 'pointer' }}>
                    <PersonIcon sx={{ width: '25px', height: '25px' }} />
                    <b>Details</b>
                </Box>
            }

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='lg'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Personal" {...a11yProps(0)} />
                        <Tab label="Official" {...a11yProps(1)} />
                        <Tab label="Contract" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={tabValue} index={0}>
                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <EditField label='Name' size='small' value={name || ''} onChange={e => superAdmin && setName(e.target.value)} />
                        <EditField label='E-mail' size='small' value={email || ''} onChange={e => superAdmin && setEmail(e.target.value)} />
                        <EditField label='Contact No.' size='small' value={contact || ''} onChange={e => superAdmin && setContact(e.target.value)} />
                        <EditField label='Address' size='small' value={address || ''} onChange={e => superAdmin && setAddress(e.target.value)} />
                        {/* <EditField label='Gender' size='small' value={gender || ''} onChange={e => superAdmin && setGender(e.target.value)} /> */}
                        <FormControl size='small'>
                            <InputLabel>Gender</InputLabel>
                            <Select
                                value={gender || ''}
                                onChange={e => superAdmin && setGender(e.target.value)}
                                label='Gender'
                            >
                                <MenuItem value='male'>Male</MenuItem>
                                <MenuItem value='female'>Female</MenuItem>
                            </Select>
                        </FormControl>
                        <EditField label='Emergency Contact Name' size='small' value={emergencyName || ''} onChange={e => superAdmin && setEmergencyName(e.target.value)} />
                        <EditField label='Emergency Contact No.' size='small' value={emergencyNum || ''} onChange={e => superAdmin && setEmergencyNum(e.target.value)} />
                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={tabValue} index={1}>
                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <EditField label='PAN No.' size='small' value={pan || ''} onChange={e => superAdmin && setPan(e.target.value)} />
                        <EditField label='Bank Name' size='small' value={bankName || ''} onChange={e => superAdmin && setBankName(e.target.value)} />
                        {/* <EditField label='Bank Branch' size='small' value={bankBranch || ''} onChange={e => superAdmin && setBankBranch(e.target.value)} /> */}
                        {/* <EditField label='Bank Account Name' size='small' value={bankAccName || ''} onChange={e => superAdmin && setBankAccName(e.target.value)} /> */}
                        <EditField label='Bank Account No.' size='small' value={bankAccNo || ''} onChange={e => superAdmin && setBankAccNo(parseInt(e.target.value))} />
                        <EditField label='Join Date' size='small' value={joinDate || ''} onChange={e => superAdmin && setJoinDate(e.target.value)} />
                        <EditField label='Termination Date' size='small' value={terminationDate || ''} onChange={e => superAdmin && setTerminationDate(e.target.value)} />
                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={tabValue} index={2}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disableGutters={true} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Title</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display='flex' flexDirection='column' gap='10px'>
                                <EditField label='Job Title' size='small' value={title || ''} onChange={e => superAdmin && setTitle(e.target.value)} />
                                <EditField label='Position' size='small' value={position || ''} onChange={e => superAdmin && setPosition(e.target.value)} />
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disableGutters={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>
                                Hours
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {/* <EditField label='Daily Hours' size='small' value={dailyHours || ''} onChange={e => superAdmin && setDailyHours(parseInt(e.target.value))} /> */}
                            {/* <EditField label='Monthly Hours' size='small' value={monthlyHours || ''} onChange={e => superAdmin && setMonthlyHours(parseInt(e.target.value))} /> */}
                            <EditField label='Working Hours' size='small' defaultValue={editedHours !== null ? editedHours : generalHours} onChange={e => superAdmin && setEditedHours(parseInt(e.target.value))} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disableGutters={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Leave
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {/* <EditField label='Paid Leaves (Yearly)' size='small' value={paidLeaves || ''} onChange={e => superAdmin && setPaidLeaves(parseInt(e.target.value))} /> */}
                            {leaveTypes?.map(type => {
                                const newTotalLeaves: number = (editedLeaves?.filter(leave => leave.split(' ')[0] === type?.attributes?.code)[0]) !== undefined ? parseInt(editedLeaves?.filter(leave => leave.split(' ')[0] === type?.attributes?.code)[0]?.replace(/\D/g, "")) : 0
                                const totalLeaves: number = (editedLeaves?.filter(leave => leave.split(' ')[0] === type?.attributes?.code)?.length === 1) ? newTotalLeaves : type?.attributes?.days
                                return (
                                    <EditField
                                        disabled={!superAdmin}
                                        sx={{
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                WebkitTextFillColor: '#000000',
                                            },
                                            '& .MuiInputLabel-root.Mui-disabled': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                        }}
                                        key={type?.id}
                                        label={type?.attributes?.name}
                                        size='small'
                                        defaultValue={totalLeaves}
                                        onChange={e => {
                                            superAdmin && setEditedDays([...editedDays, `${type?.attributes?.code} [${e.target.value} days]`])
                                            superAdmin && setNeedToEditDays(true)
                                        }} />
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                    {/* <EditField label='Join Date' size='small' value={joinDate || ''} onChange={e =>superAdmin&& setJoinDate(e.target.value)} /> */}
                </CustomTabPanel>

                {/* <Box display='flex' gap='25px'>
                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <Typography variant='h6'>Personal Details</Typography>
                        <EditField label='Name' size='small' value={name || ''} onChange={e => superAdmin && setName(e.target.value)} />
                        <EditField label='E-mail' size='small' value={email || ''} onChange={e => superAdmin && setEmail(e.target.value)} />
                        <EditField label='Contact No.' size='small' value={contact || ''} onChange={e => superAdmin && setContact(e.target.value)} />
                        <EditField label='Address' size='small' value={address || ''} onChange={e => superAdmin && setAddress(e.target.value)} />
                        <EditField label='Emergency Contact Name' size='small' value={emergencyName || ''} onChange={e => superAdmin && setEmergencyName(e.target.value)} />
                        <EditField label='Emergency Contact No.' size='small' value={emergencyNum || ''} onChange={e => superAdmin && setEmergencyNum(e.target.value)} />
                    </Box>

                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <Typography variant='h6'>Official Details</Typography>
                        <EditField label='PAN No.' size='small' value={pan || ''} onChange={e => superAdmin && setPan(e.target.value)} />
                        <EditField label='Bank Name' size='small' value={bankName || ''} onChange={e => superAdmin && setBankName(e.target.value)} />
                        <EditField label='Bank Branch' size='small' value={bankBranch || ''} onChange={e => superAdmin && setBankBranch(e.target.value)} />
                        <EditField label='Bank Account Name' size='small' value={bankAccName || ''} onChange={e => superAdmin && setBankAccName(e.target.value)} />
                        <EditField label='Bank Account No.' size='small' value={bankAccNo || ''} onChange={e => superAdmin && setBankAccNo(parseInt(e.target.value))} />
                    </Box>

                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <Typography variant='h6'>Contract Details</Typography>
                        <EditField label='Role' size='small' value={role || ''} onChange={e => superAdmin && setRole(e.target.value)} />
                        <EditField label='Position' size='small' value={position || ''} onChange={e => superAdmin && setPosition(e.target.value)} />
                        <EditField label='Working Hours (Weekly)' size='small' value={workingHours || ''} onChange={e => superAdmin && setWorkingHours(parseInt(e.target.value))} />
                        <EditField label='Paid Leaves (Yearly)' size='small' value={paidLeaves || ''} onChange={e => superAdmin && setPaidLeaves(parseInt(e.target.value))} />
                        <EditField label='Join Date' size='small' value={joinDate || ''} onChange={e =>superAdmin&& setJoinDate(e.target.value)} />
                    </Box>
                </Box> */}
                {superAdmin &&
                    <Box display='flex' justifyContent='space-around'>
                        <Button onClick={() => handleSave(props?.id)}>Save</Button>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                    </Box>
                }
            </Dialog >
        </>
    )
}

export default EditUser
