import { Box, IconButton, styled, ListItemButton, Dialog, Typography, Button, Badge } from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ForumIcon from '@mui/icons-material/Forum';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import { NavLink, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { NovuProvider, useNotifications } from '@novu/notification-center';
import { UserDataContext } from '../../routes/Main';
import isAttendanceUser from '../../hooks/isAttendanceUser';

const NavItem = styled(Box)({
    flexGrow: 1,
    textAlign: 'center',
    justifyContent: 'center'
});

const IconSize = {
    width: '30px',
    height: '30px'
};

const BottomNavbar = () => {

    const navigate = useNavigate();

    const attendanceUser = isAttendanceUser();

    const { userData } = useContext(UserDataContext);

    const [open, setOpen] = useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        navigate(window.location.href = process.env.REACT_APP_sisUrl !== undefined ? process.env.REACT_APP_sisUrl : '');
    }

    // development:
    // const novuAppId = process.env.REACT_APP_novuAppId?.slice(1, -1);

    // production:
    // const novuAppId = process.env.REACT_APP_novuAppId;

    const NotificationIcon = () => {
        const { unseenCount } = useNotifications();
        return (
            <NavItem>
                <NavLink to='/notifications'>
                    <IconButton color='primary' sx={{ px: '0px', py: '0px' }}>
                        <ListItemButton selected={location.pathname === '/notifications' ? true : false} sx={{ py: '10px' }}>
                            <Badge badgeContent={unseenCount !== undefined && unseenCount} color='error'>
                                <NotificationsIcon sx={IconSize} />
                            </Badge>
                        </ListItemButton>
                    </IconButton>
                </NavLink>
            </NavItem>
        )
    }

    return (

        <Box alignContent='center' justifyItems='center' display='flex' flexDirection='row' role='navigation' position='fixed' bgcolor='white' sx={{ top: 'auto', left: '0px', bottom: '0px', width: '100%' }}>

            {attendanceUser &&
                <NavItem>
                    <NavLink to='/attendance'>
                        <IconButton color='primary' sx={{ px: '0px', py: '0px' }}>
                            <ListItemButton selected={location.pathname === '/attendance' ? true : false} sx={{ py: '10px', mx: '0px' }}>
                                <GroupsIcon sx={IconSize} />
                            </ListItemButton>
                        </IconButton>
                    </NavLink>
                </NavItem>
            }

            <NavItem>
                <NavLink to='/overview'>
                    <IconButton color='primary' sx={{ px: '0px', py: '0px' }}>
                        <ListItemButton selected={(location.pathname === '/overview' || location.pathname === '/tickets') ? true : false} sx={{ py: '10px', mx: '0px' }}>
                            <ConfirmationNumberIcon sx={IconSize} />
                        </ListItemButton>
                    </IconButton>
                </NavLink>
            </NavItem>

            <NavItem>
                {/* <NavLink to='/meeting'> */}
                <IconButton color='primary' sx={{ px: '0px', py: '0px' }}>
                    <ListItemButton selected={location.pathname === '/meeting' ? true : false} sx={{ py: '10px' }}>
                        <CalendarMonthIcon sx={IconSize} />
                    </ListItemButton>
                </IconButton>
                {/* </NavLink> */}
            </NavItem>

            <NavItem>
                {/* <NavLink to='/chatroom'> */}
                <IconButton color='primary' sx={{ px: '0px', py: '0px' }}>
                    <ListItemButton selected={location.pathname === '/chatroom' ? true : false} sx={{ py: '10px' }}>
                        <ForumIcon sx={{ height: '27px', width: '30px', mt: '2px' }} />
                    </ListItemButton>
                </IconButton>
                {/* </NavLink> */}
            </NavItem>
            
            <NovuProvider
                backendUrl={process.env.REACT_APP_novuBackendUrl}
                subscriberId={JSON.stringify(userData?.data?.id)}
                applicationIdentifier={process.env.REACT_APP_novuAppId !== undefined ? process.env.REACT_APP_novuAppId : ''}
                initialFetchingStrategy={{ fetchNotifications: true }}
            >
                <NotificationIcon />
            </NovuProvider>

            <NavItem>
                {/* <NavLink to='/chatroom'> */}
                <IconButton color='primary' sx={{ px: '0px', py: '0px' }}>
                    <ListItemButton sx={{ py: '10px' }}>
                        <AccountBoxIcon sx={IconSize} />
                    </ListItemButton>
                </IconButton>
                {/* </NavLink> */}
            </NavItem>

            {/* <NavItem onClick={handleClickOpen}>
                <IconButton color='primary' sx={{ px: '0px', py: '0px' }}>
                    <ListItemButton sx={{ py: '10px' }}>
                        <LogoutIcon sx={IconSize} />
                    </ListItemButton>
                </IconButton>
            </NavItem> */}

            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
                    <Box display='flex' gap='3px' pr='2px'>
                        <LogoutIcon />
                        <Typography>Are you sure?</Typography>
                    </Box>
                    <Box display='flex' gap='10px'>
                        <Button size='small' variant='contained' color='error' onClick={handleLogout}>Yes</Button>
                        <Button size='small' variant='outlined' onClick={handleClose}>No</Button>
                    </Box>
                </Box>
            </Dialog >
        </Box >
    )
}

export default BottomNavbar
