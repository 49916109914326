import { Box } from '@mui/material'
import MobileCreateTicketForm from '../../components/Mobile/MobileCreateTicketForm';

const MobileCreateTicketLayout = () => {
    return (
        <Box display='flex' flexDirection='column' position='fixed' top='80px' left='15px' right='15px' bottom='65px' bgcolor='white' gap='10px' px='10px' pt='12px' pb='12px' borderRadius='10px'>
            <MobileCreateTicketForm />
        </Box>
    )
}

export default MobileCreateTicketLayout
