import { createContext, useContext, useEffect, useState } from 'react';
import { LoadingContext, TenantContext } from '../App';
import isAdmin from '../hooks/isAdmin';
import isMobile from '../hooks/isMobile';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { UserDataContext } from '../routes/Main';
import { getTicketsService, getTicketsTenantSpecificService } from '../services/ticketService';
import DesktopHomePage from './Desktop/DesktopHomePage';
import MobileHomePage from './Mobile/MobileHomePage';
import isDeveloper from '../hooks/isDeveloper';
import { useNavigate } from 'react-router-dom';

export interface IChatData {
  id: number | string;
  attributes: {
    admin: boolean;
    createdAt: string;
    changed_to: string;
    message: string;
    publishedAt: string;
    sender: string;
    updatedAt: string;
    attachments: {
      data: {
        attributes: {
          url: string;
        };
      }[];
    }
  };
}

export interface ITicketData {
  id: number | string;
  attributes: {
    assignee: string;
    attachment: {
      data: {
        attributes: {
          url: string;
        };
      }[];
    };
    chats: {
      data: IChatData[];
    };
    createdAt: string;
    creator: string | number;
    description: string;
    due_date: string;
    estimate: string;
    main_tenant: string;
    priority: string;
    environment: string;
    publishedAt: string;
    status: string;
    ticket_id: string;
    type: string;
    updatedAt: string;
    assignees: string[] | null;
    merged_ticket: string;
    branch: string;
  };
}

export interface IUserData {
  name: string,
  email: string,
  id: number,
  main_tenant: string,
  roles: [{
    name: string
  }],
  organizations: any
}

// export const TicketListContext = createContext<{
//   ticketList: ITicketData[];
// }>({
//   ticketList: [],
// });

export const TicketListContext = createContext<any>({});

const HomePage = () => {
  const mobile = isMobile();
  const admin = isAdmin();
  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const [tickets, setTickets] = useState([]);

  const { userData } = useContext(UserDataContext);
  const { tenant } = useContext(TenantContext);
  const { setLoading } = useContext(LoadingContext);

  const getTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(userData.data.organizations[0]?.alias ?? false, 0, 25);
    if (res?.data) {
      setTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const getTenantTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = developer ? await getTicketsTenantSpecificService(tenant === 'all' ? userData?.data?.organizations : tenant, 0, 25, status) : await getTicketsTenantSpecificService(tenant === 'all' ? null : tenant, 0, 25)
    if (res?.data) {
      setTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const fetchTicketsHomePage = () => {
    if (!admin && !superAdmin && !developer) {
      getTickets();
      return;
    }
    getTenantTickets();
  };

  useEffect(() => {
    fetchTicketsHomePage();
  }, [userData, admin, superAdmin, tenant]);


  const attendanceUserOnly = (userData?.data?.roles[0]?.name !== 'sa' && userData?.data?.organizations?.length === 1 && userData?.data?.organizations[0]?.alias === 'sciever') ? true : false;

  const navigate = useNavigate();

  useEffect(() => {
    if (attendanceUserOnly) {
      navigate('/attendance')
    }
  }, [attendanceUserOnly])

  return (
    <TicketListContext.Provider
      value={{
        ticketList: tickets,
        fetchTicketsHomePage
      }}
    >
      {mobile ? <MobileHomePage /> : <DesktopHomePage />}
    </TicketListContext.Provider>
  );
};

export default HomePage;
