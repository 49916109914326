import { useContext } from "react";
import { UserDataContext } from "../routes/Main";

export default function isSuperAdmin() {
    const { userData } = useContext(UserDataContext);
    return (
        userData?.data?.roles[0]?.name === 'sa'
            ? true
            : false
    )
}
