import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react";
import { getTodaysDate } from "./DailyAttendanceReport";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { formattedTime } from "./CheckInOut";
import axios from "axios";
import { failed } from "../../hooks/useToast";
import { CombinedReportContext } from "../../layout/Desktop/DesktopAttendanceLayout";
import { AttendanceUsersContext } from "../../pages/AttendancePage";
import { CurrentUserContext } from "../../App";
import Pills from "../Pills";
import TicketDetails from "../TicketDetails";
import '../../index.css';
import { UserDataContext } from "../../routes/Main";

const OverallActivity = () => {

    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

    const { userData } = useContext(UserDataContext);

    const { combinedReport } = useContext(CombinedReportContext);

    const { attendanceUsers } = useContext(AttendanceUsersContext);

    const [date, setDate] = useState(getTodaysDate());

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const [log, setLog] = useState();

    const [selectedUser, setSelectedUser] = useState<number | string>(currentUser?.id);

    const getLog = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/logs?filters[date][$eq]=${date}&populate=*&pagination[pageSize]=9999`, {
            headers: headers
        })
            .then((res) => setLog(res?.data?.data?.filter((data: { attributes: { attendance_user: { data: { id: number } } } }) => data?.attributes?.attendance_user?.data?.id === selectedUser)))
            .catch(() => failed())
    }

    useEffect(() => {
        getLog();
    }, [date, selectedUser])

    const sortedUsers = attendanceUsers?.slice()?.sort((a: { attributes: { name: string } }, b: { attributes: { name: string } }) => a.attributes.name.localeCompare(b.attributes.name));

    const [ticketOpen, setTicketOpen] = useState(false);
    const [ticketId, setTicketId] = useState<string>();

    const columns: GridColDef[] = [
        {
            field: 'sn',
            renderHeader: () => {
                return <Typography fontWeight='bold'>SN</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            editable: false,
            renderCell: (params) => page * pageSize + params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'action',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Action</Typography>;
            },
            headerAlign: 'center',
            flex: 3,
            align: 'center',
            sortable: false,
            editable: false,
            renderCell: (rowData) => <Pills pill={rowData?.row?.attributes?.action} />
        },
        {
            field: 'description',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Description</Typography>;
            },
            headerAlign: 'left',
            flex: 7,
            align: 'left',
            sortable: false,
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.description !== null ? rowData?.row?.attributes?.description : '-'
        },
        {
            field: 'ticket_id',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Ticket ID</Typography>;
            },
            headerAlign: 'center',
            flex: 3,
            align: 'center',
            sortable: false,
            editable: false,
            renderCell: (rowData) => rowData?.row?.attributes?.ticket_id
        },
        {
            field: 'Time',
            renderHeader: () => {
                return <Typography fontWeight='bold'>Time</Typography>;
            },
            headerAlign: 'center',
            align: 'center',
            flex: 3,
            editable: false,
            sortable: false,
            renderCell: (rowData) => formattedTime(rowData?.row?.attributes?.time)
        },
    ]

    const getCurrentAttendanceUser = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/attendance-users?pagination[pageSize]=99`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_token}`,
            }
        })
            .then(res => {
                const filter = res?.data?.data?.filter((user: { attributes: { sis_id: string } }) => user?.attributes?.sis_id == userData?.data?.id);
                setCurrentUser(filter[0]);
                return null;
            })
    }

    const handleRowClick = (rowData: GridRowParams<any>) => {
        if (rowData?.row?.attributes?.type === 'ticket') {
            getCurrentAttendanceUser();
            setTicketOpen(true);
            setTicketId(rowData?.row?.attributes?.ticket_id)
        }
    };

    return (
        <>
            <Box display='flex' gap='20px' alignItems='center' position='absolute' right='40px' top='15px'>
                {combinedReport &&
                    <FormControl size='small' variant='standard'>
                        <InputLabel>Employee</InputLabel>
                        <Select
                            value={selectedUser}
                            onChange={e => setSelectedUser(e.target.value)}
                            label='Employee'
                            sx={{
                                width: '110px',
                                mb: '5px'
                            }}
                        >
                            {sortedUsers?.map((user: { attributes: { name: string }, id: number }) => {
                                return <MenuItem key={user?.id} value={user?.id}>{user?.attributes?.name?.split(' ')[0]}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                }

                <Box width='166px'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker value={getTodaysDate() !== undefined && dayjs(getTodaysDate())} onChange={(e: any) => setDate(`${e?.$y}-${JSON.stringify(e?.$M + 1)?.padStart(2, '0')}-${JSON.stringify(e?.$D).padStart(2, '0')}`)} label="Date" format='DD/MM/YYYY' slotProps={{ textField: { size: 'small' } }} />
                    </LocalizationProvider>
                </Box>
            </Box>

            <Box sx={{ bgcolor: 'white', height: '450px', mt: '-20px', mb: '-30px' }}>
                <DataGrid
                    rows={log !== undefined ? log : []}
                    columns={columns}
                    rowHeight={40}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                    pageSizeOptions={[25, 50, 75, 100]}
                    disableColumnMenu
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '0.4em',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
                        {
                            borderColor: 'darkgray',
                            borderWidth: '1px',
                        },
                    }}
                    getRowId={(row) => {
                        return row?.id
                    }}
                    disableRowSelectionOnClick
                    onPaginationModelChange={(row) => {
                        setPage(row.page);
                        setPageSize(row.pageSize);
                    }}
                    getRowClassName={(rowData) => rowData?.row?.attributes?.type === 'ticket' ? 'custom-row-class-2' : ''}
                    onRowClick={(rowData) => handleRowClick(rowData)}
                />
            </Box>

            <TicketDetails open={ticketOpen} setOpen={setTicketOpen} ticketId={ticketId ? ticketId : ''} />
        </>
    )
}

export default OverallActivity
