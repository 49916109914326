import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Box, Dialog, DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import isMobile from '../../hooks/isMobile';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { ITicketData } from '../../pages/HomePage';
import DueDatePicker from '../Desktop/DueDatePicker';
import ViewAttachments from '../Desktop/ViewAttachments';
import EditDueDate from '../EditDueDate';
import EditPriority from '../EditPriority';
import EditStatus from '../EditStatus';
import EditType from '../EditType';
import OverviewPills from '../OverviewPills';
import TicketForum from '../TicketForum';
import DeleteTicket from '../DeleteTicket';
import AssignDeveloper from '../AssignDeveloper';
import EditDescription from '../EditDescription';
import EditEnvironment from '../EditEnvironment';
import Pills from '../Pills';
import EditMerged from '../EditMerged';
import EditBranch from '../EditBranch';
import isDeveloper from '../../hooks/isDeveloper';
import Estimate from '../Estimate';

const OverviewTicket = ({ data }: { data: ITicketData }) => {

  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const iconSize = {
    width: '20px',
    height: '20px',
  };

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMobileOpen = () => {
    setMobileOpen(true);
  };

  const handleMobileClose = () => {
    setMobileOpen(false);
  };

  const [imageOpen, setImageOpen] = useState(false);
  const [imageToOpen] = useState('');

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const getImages = (selectedTicket: { attributes: { url: string } }) => {
    return `${process.env.REACT_APP_strapiImage}${selectedTicket.attributes.url}`;
  };

  const getUrl = (selectedTicket: { attributes: { url: string } }) => {
    return selectedTicket.attributes.url;
  };

  const images =
    data?.attributes?.attachment?.data?.length > 0
      ? data?.attributes.attachment.data.map(getImages)
      : '';

  const url =
    data?.attributes?.attachment?.data?.length > 0
      ? data.attributes.attachment.data.map(getUrl)
      : 'undefined';

  const mobile = isMobile();

  const id = data?.attributes?.ticket_id?.slice(4);
  const idToChange = parseInt(id);

  // console.log(data?.attributes?.attachment?.data);
  // const [download, setDownload] = useState(false);

  // useEffect(() => {
  //   data?.attributes?.attachment?.data?.map((file: any) => {
  //     if (file.attributes.ext !== '.png' && file.attributes.ext !== '.jpg') {
  //       setDownload(true)
  //     }
  //   })
  // }, [data])

  const hasDueDatePassed = (dueDate: string): boolean => {
    const currentDate = new Date();
    const dueDateObj = new Date(dueDate);
    currentDate.setDate(currentDate.getDate() - 1);

    return currentDate > dueDateObj;
  };

  return (
    <>
      <Box
        onClick={() => {
          !mobile ? handleClickOpen() : handleMobileOpen();
        }}
        display='flex'
        flexDirection='column'
        width='100%'
        p='10px'
        gap='5px'
        bgcolor={((data.attributes.status === 'backlog' || data.attributes.status === 'on hold' || data.attributes.status === 'todo' || data.attributes.status === 'redo' || data.attributes.status === 'in progress') && data.attributes.due_date !== null && hasDueDatePassed(data.attributes.due_date)) ? '#FF7F7F' : theme.palette.background.default}
        sx={{
          border: 'solid',
          borderRadius: '10px',
          borderWidth: '2px',
          borderColor: 'rgba(128,128,128,0.2)',
          cursor: 'pointer',
        }}
      >
        <Box display='flex'>
          <Typography width='28%'>{data.attributes.ticket_id}</Typography>
          <Typography width='72%' fontWeight='bold' noWrap>
            {data.attributes.description}
          </Typography>
        </Box>
        <Box display='flex' gap='5px' alignItems='center'>
          <OverviewPills pill={data.attributes.type} />
          <OverviewPills pill={data.attributes.priority} />
          <Typography ml='auto' fontSize='14px' noWrap>
            {data.attributes.due_date != null
              ? new Date(data.attributes.due_date).toLocaleDateString('en-GB')
              : ''}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' flexDirection='row' gap='2px'>
            <ChatOutlinedIcon sx={iconSize} />
            <Typography fontSize='small'>
              {data.attributes.chats.data != null ? data.attributes.chats.data.length : '0'}
            </Typography>
            <AttachFileIcon sx={iconSize} />
            <Typography fontSize='small'>
              {data.attributes.attachment.data != null ? data.attributes.attachment.data.length : '0'}
            </Typography>
          </Box>
          {((superAdmin || developer) && data?.attributes?.assignees !== null && data?.attributes?.assignees?.length !== 0) &&
            <Typography fontSize='14px'>{data?.attributes?.assignees[0]?.substring(0, 8) === 'customer' ? data?.attributes?.assignees[0].split(' ')[0].slice(8) : data?.attributes?.assignees[0].split(' ')[0]}</Typography>
          }
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth='lg'>
        <Box display='flex' gap='10px' p='15px'>
          <Box display='flex' flexDirection='column' justifyContent='space-between' gap='15px' m='20px 5px 20px 5px'>
            <ArrowBackIcon onClick={handleClose} cursor='pointer' />
            <DeleteTicket tenant={data.attributes.main_tenant} data={data} selectedRowId={data.attributes.ticket_id} />
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            width='300px'
            p='15px'
            border='solid'
            borderColor='lightgray'
            borderRadius='5px'
            gap='15px'
            sx={{ borderWidth: '1px' }}
          >
            <Box height='250px' sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
              <Box display='flex' flexDirection='column' gap='25px'>
                <Typography fontWeight='bold' whiteSpace='pre-wrap'>
                  <i>{data.attributes.ticket_id}</i>     {data.attributes.description}   {superAdmin && <EditDescription ticket_id={data?.attributes?.ticket_id} id={data.id} current={data.attributes.description} />}
                </Typography>

                {data.attributes.merged_ticket &&
                  <Typography fontWeight='bold' whiteSpace='pre-wrap'>
                    <Box display='inline-block'><Pills pill='merged' /></Box>   {data.attributes.merged_ticket}   {superAdmin && <EditMerged ticket_id={data?.attributes?.ticket_id} id={data.id} current={data.attributes.merged_ticket} />}
                  </Typography>
                }
              </Box>
              {/* <Typography>{data.attributes.creator}</Typography> */}
            </Box>
            <Box display='flex' flexDirection='column' gap='10px'>
              <Box display='flex' justifyContent='space-between' alignItems='end'>
                <Box display='flex' flexDirection='column' gap='7px' width='100%'>
                  {/* <Box display='flex' gap='25px'>
                  <Typography width='85px'>Type</Typography>
                  <EditType idToChange={idToChange} type={data.attributes.type} />
                </Box>

                <Box display='flex' gap='25px'>
                  <Typography width='85px'>Priority</Typography>
                  <EditPriority idToChange={idToChange} priority={data.attributes.priority} />
                </Box>

                <Box display='flex' gap='25px'>
                  <Typography width='85px'>Status</Typography>
                  <EditStatus idToChange={idToChange} status={data.attributes.status} />
                </Box> */}

                  <EditBranch fullId={data.attributes.ticket_id} type={data.attributes.type} id={data.id} branch={data.attributes.branch} tenant={data.attributes.main_tenant} />

                  <Box display='flex' gap='25px'>
                    <Typography width='85px'>Due Date</Typography>
                    {data.attributes.due_date !== null ? (
                      <EditDueDate
                        defaultDate={data.attributes.due_date}
                        selectedRowId={data.attributes.ticket_id}
                      />
                    ) : (
                      <DueDatePicker selectedRowId={data.attributes.ticket_id} />
                    )}
                  </Box>

                  <Box display='flex' gap='25px'>
                    <Typography width='85px'>Estimate</Typography>
                    <Estimate estimate={data.attributes.estimate} id={data.id} />
                  </Box>

                  <Box display='flex' gap='25px'>
                    <Typography width='85px'>Created On</Typography>
                    <Typography>
                      {new Date(data.attributes.createdAt).toLocaleDateString('en-GB')}
                    </Typography>
                  </Box>

                  {/* 
                  {admin || superAdmin ? (
                    <> */}
                  <Box display='flex' justifyContent='space-between' alignItems='end'>
                    <Box flex='1' display='flex' justifyContent='start'>
                      <AssignDeveloper newTicket={false} setAssignees={undefined} idToChange={data.id} assignees={data.attributes.assignees} main_tenant={data.attributes.main_tenant} />
                    </Box>
                    <Box flex='1' display='flex' justifyContent='center'>
                      <EditEnvironment environment={data?.attributes?.environment ? data?.attributes?.environment.toUpperCase() : '-'} tenant={data.attributes.main_tenant} fullId={data.attributes.ticket_id} idToChange={idToChange} />
                    </Box>
                    {/* <Typography width='85px'>{data?.attributes?.environment ? data?.attributes?.environment.toUpperCase() : '-'}</Typography> */}
                    <Box flex='1' display='flex' justifyContent='end'>
                      {url != 'undefined' ? <ViewAttachments url={url} images={images} name={data?.attributes?.ticket_id} /> : ''}
                    </Box>
                  </Box>

                  {/* <Box display='flex' gap='25px'>
                    <Box display='flex' flexDirection='column'>
                      <Typography width='85px'>Estimate</Typography>
                      <Typography mt='-5px' fontSize='12px'>(w/d/h/m)</Typography>
                    </Box>
                    {data.attributes.estimate !== null ? <Typography>{data.attributes.estimate}</Typography> : <EstimatePicker ticketId={data.attributes.ticket_id} />}
                  </Box> */}
                  {/* </> */}
                  {/* ) : (
                    ''
                  )} */}
                </Box>

                {/* <Box>
                  <Attachments data={data?.attributes} url={url} images={images} />
                </Box> */}
              </Box>

              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Box flex='1' display='flex' justifyContent='start'>
                  <EditType tenant={data.attributes.main_tenant} fullId={data.attributes.ticket_id} idToChange={idToChange} type={data.attributes.type} />
                </Box>
                <Box flex='1' display='flex' justifyContent='center'>
                  <EditPriority tenant={data.attributes.main_tenant} fullId={data.attributes.ticket_id} idToChange={idToChange} priority={data.attributes.priority} />
                </Box>
                <Box flex='1' display='flex' justifyContent='end'>
                  <EditStatus tenant={data.attributes.main_tenant} type={data.attributes.type} branch={data.attributes.branch} fullId={data.attributes.ticket_id} assignees={data.attributes.assignees} dueDate={data.attributes.due_date} idToChange={idToChange} status={data.attributes.status} />
                </Box>
              </Box>
            </Box>
          </Box>

          <TicketForum selectedRowId={data.attributes.ticket_id} data={data} />
        </Box>
      </Dialog>

      <Dialog open={imageOpen} onClose={handleImageClose} maxWidth='md'>
        <DialogContent sx={{ padding: '0px' }}>
          <img src={`${process.env.REACT_APP_strapiImage}${imageToOpen}`} alt='' width='100%' height='100%' />
        </DialogContent>
      </Dialog>

      <Dialog open={mobileOpen} onClose={handleMobileClose}>
        <Box m='15px' display='flex'>
          <ArrowBackIcon onClick={handleMobileClose} />
        </Box>
        <Box
          m='15px'
          mt='0px'
          border='solid'
          borderColor='lightgray'
          borderRadius='10px'
          sx={{ borderWidth: '1px' }}
        >
          <TicketForum data={data} selectedRowId={data.attributes.ticket_id} />
        </Box>
      </Dialog>
    </>
  );
};

export default OverviewTicket;
