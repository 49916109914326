import { useContext, useEffect, useState } from "react";
import isAdmin from "../hooks/isAdmin";
import isDeveloper from "../hooks/isDeveloper";
import isSuperAdmin from "../hooks/isSuperAdmin";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import axios from "axios";
import { failed, success } from "../hooks/useToast";
import { TenantContext } from "../App";
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { CustomTabPanel, a11yProps } from "./Attendance/Report";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

const Bookmarks = () => {

    const developer = isDeveloper();
    const admin = isAdmin();
    const superAdmin = isSuperAdmin();

    const { tenant } = useContext(TenantContext);

    const [bookmarkOpen, setBookmarkOpen] = useState(false);

    const [bookmarkLinks, setBookmarkLinks] = useState<[{
        attributes: { tenant: string, link: string, title: string },
        id: number
    }]>();

    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

    const getBookmarkLink = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/tenant-files?filters[tenant][$eq]=${tenant}&populate=*`, {
            headers: headers
        })
            .then(res => setBookmarkLinks(res?.data?.data))
            .catch(() => failed())
    }

    useEffect(() => {
        getBookmarkLink()
    }, [tenant])

    const [editBookmarkOpen, setEditBookmarkOpen] = useState(false);
    const [idToEdit, setIdToEdit] = useState<number>();
    const [editedTitle, setEditedTitle] = useState<string>();
    const [editedLink, setEditedLink] = useState<string>();

    const editLink = () => {
        let data = undefined
        if (editedTitle === undefined && editedLink === undefined) {
            setEditBookmarkOpen(false);
        } else {
            if (editedTitle !== undefined && editedLink === undefined) {
                data = {
                    data: {
                        title: editedTitle !== '' ? editedTitle : null
                    }
                }
            } else if (editedTitle === undefined && editedLink !== undefined) {
                data = {
                    data: {
                        link: editedLink !== '' ? editedLink : null
                    }
                }
            } else {
                data = {
                    data: {
                        title: editedTitle !== '' ? editedTitle : null,
                        link: editedLink !== '' ? editedLink : null
                    }
                }
            }

            axios.put(`${process.env.REACT_APP_strapiUrl}/tenant-files/${idToEdit}`, data, {
                headers: headers
            })
                .then(() => {
                    success();
                    getBookmarkLink();
                    setEditedLink(undefined);
                    setEditedTitle(undefined);
                    setEditBookmarkOpen(false);
                })
                .catch(() => failed())
        }
    }

    const [addLinkOpen, setAddLinkOpen] = useState(false);
    const [newTitle, setNewTitle] = useState<string>();
    const [newLink, setNewLink] = useState<string>();

    const addLink = () => {
        const data = {
            data: {
                title: newTitle !== '' ? newTitle : null,
                link: newLink !== '' ? newLink : null,
                tenant: tenant
            }
        }

        axios.post(`${process.env.REACT_APP_strapiUrl}/tenant-files`, data, {
            headers: headers
        })
            .then(() => {
                success();
                getBookmarkLink();
                setNewLink(undefined);
                setNewTitle(undefined);
                setAddLinkOpen(false);
            })
            .catch(() => failed())
    }

    const [cicdEnabled, setCicdEnabled] = useState(false)
    const [cicdData, setCicdData] = useState<[{ attributes: { enabled: boolean, sandbox_branch: string, uat_branch: string, production_branch: string, repo: string }, id: number }]>();
    const [repoName, setRepoName] = useState<string>();
    const [sandboxBranch, setSandboxBranch] = useState<string | null>();
    const [uatBranch, setUatBranch] = useState<string | null>();
    const [productionBranch, setProductionBranch] = useState<string | null>();
    // const [currentBranch, setCurrentBranch] = useState<string | null>();

    const getCicdData = () => {
        axios.get(`${process.env.REACT_APP_strapiUrl}/cicds?filters[tenant][$eq]=${tenant}&populate=*`, {
            headers: headers
        })
            .then(res => setCicdData(res?.data?.data))
            .catch(() => failed())
    }

    useEffect(() => {
        getCicdData();
    }, [tenant])

    useEffect(() => {
        if (cicdData !== undefined && cicdData?.length > 0) {
            setCicdEnabled(cicdData[0]?.attributes?.enabled);
            setRepoName(cicdData[0]?.attributes?.repo);
            setProductionBranch(cicdData[0]?.attributes?.production_branch);
            setUatBranch(cicdData[0]?.attributes?.uat_branch);
            setSandboxBranch(cicdData[0]?.attributes?.sandbox_branch);
            // setCurrentBranch(cicdData[0]?.attributes?.current_branch);
        } else {
            setCicdEnabled(false);
            setRepoName(undefined);
            setProductionBranch(undefined);
            setUatBranch(undefined);
            setSandboxBranch(undefined);
            // setCurrentBranch(undefined);
        }
    }, [cicdData])

    const handleCicdChange = () => {

        const data = {
            data: {
                enabled: cicdEnabled,
                repo: repoName,
                tenant: tenant,
                uat_branch: uatBranch,
                production_branch: productionBranch,
                sandbox_branch: sandboxBranch,
                // current_branch: currentBranch
            }
        }

        if (cicdData !== undefined && cicdData?.length > 0) {
            axios.put(`${process.env.REACT_APP_strapiUrl}/cicds/${cicdData[0]?.id}`, data, {
                headers: headers
            })
                .then(() => {
                    setBookmarkOpen(false);
                    success();
                    getCicdData();
                })
                .catch(() => {
                    failed();
                })
        } else {
            axios.post(`${process.env.REACT_APP_strapiUrl}/cicds`, data, {
                headers: headers
            })
                .then(() => {
                    setBookmarkOpen(false);
                    success();
                    getCicdData();
                })
                .catch(() => {
                    failed();
                })
        }
    }

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const extractRepoName = (url: string) => {
        if (!url) return '';
        const match = url.match(
            /^https?:\/\/(www\.)?github.com\/(?<owner>[\w.-]+)\/(?<name>[\w.-]+)/
        );
        if (!match || !(match.groups?.owner && match.groups?.name)) return '';
        return match.groups.name;
    }

    const [branches, setBranches] = useState<[{ name: string }]>();

    useEffect(() => {
        const config = {
            headers: {
                Accept: 'application/vnd.github+json',
                Authorization: `Bearer ${process.env.REACT_APP_githubToken}`,
                'X-GitHub-Api-Version': '2022-11-28'
            }
        };

        axios.get(
            `https://api.github.com/repos/sciever-inc/${repoName && extractRepoName(repoName)}/branches`, config
        )
            .then(res => setBranches(res?.data))
            .catch(() => {
                setBranches(undefined);
                // setCurrentBranch(null);
                setSandboxBranch(null);
                setUatBranch(null);
                setProductionBranch(null);
            })
    }, [repoName])

    return (
        <>
            {(superAdmin || admin || developer) &&
                tenant !== 'all' &&
                <BookmarkIcon onClick={() => {
                    setBookmarkOpen(true)
                }} sx={{ cursor: 'pointer' }} />
            }

            <Dialog open={bookmarkOpen} maxWidth='sm' fullWidth onClose={() => {
                setBookmarkOpen(false);
                setEditedLink(undefined);
                setEditedTitle(undefined);
                setEditBookmarkOpen(false);
                setNewTitle(undefined);
                setNewLink(undefined);
                setAddLinkOpen(false);
                getCicdData();
            }}>
                <DialogTitle display='flex' alignItems='center' gap='5px' mb='-5px'>
                    <BookmarkIcon />
                    Bookmarks
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Links" {...a11yProps(0)} />
                            <Tab label="CI/CD" {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={tabValue} index={0} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            {bookmarkLinks?.map(link => {
                                if (editBookmarkOpen) {
                                    if (link?.id === idToEdit) {
                                        return (
                                            <Box display='flex' gap='10px' mt='5px' key={link?.id} width='100%'>
                                                <TextField
                                                    size='small'
                                                    label='Title*'
                                                    defaultValue={link?.attributes?.title}
                                                    onChange={e => setEditedTitle(e.target.value)}
                                                    sx={{ flex: '1' }} />

                                                <TextField
                                                    size='small'
                                                    label='Link*'
                                                    defaultValue={link?.attributes?.link}
                                                    onChange={e => setEditedLink(e.target.value)}
                                                    sx={{ flex: '1' }} />

                                                <Box display='flex' alignItems='center'>
                                                    <DoneIcon onClick={() => {
                                                        editLink();
                                                    }} sx={{ height: '20px', color: 'gray', cursor: 'pointer' }} />
                                                    <CloseIcon onClick={() => {
                                                        setEditBookmarkOpen(false);
                                                        setEditedLink(undefined);
                                                        setEditedTitle(undefined);
                                                    }} sx={{ height: '20px', color: 'gray', cursor: 'pointer' }} />
                                                </Box>
                                            </Box>
                                        )
                                    } else {
                                        return (
                                            <Box key={link?.id} display='flex' alignItems='center' gap='5px'>
                                                <Typography maxWidth='350px' overflow='hidden'>{link?.attributes?.title}</Typography>
                                                <Typography>-</Typography>
                                                <a href={link?.attributes?.link} target='_blank' rel="noreferrer" style={{ flex: '1', overflow: 'hidden', whiteSpace: 'nowrap' }} >{link?.attributes?.link}</a>
                                            </Box>
                                        )
                                    }
                                } else {
                                    return (
                                        <Box key={link?.id} display='flex' alignItems='center' justifyContent='space-between' gap='5px'>
                                            <Typography maxWidth='350px' overflow='hidden'>{link?.attributes?.title}</Typography>
                                            <Typography>-</Typography>
                                            <a href={link?.attributes?.link} target='_blank' rel="noreferrer" style={{ flex: '1', overflow: 'hidden', whiteSpace: 'nowrap' }} >{link?.attributes?.link}</a>
                                            {((superAdmin || developer) && !addLinkOpen) && <EditIcon onClick={() => {
                                                setIdToEdit(link?.id)
                                                setEditBookmarkOpen(true);
                                            }} sx={{ height: '15px', color: 'gray', cursor: 'pointer' }} />}
                                        </Box>
                                    )
                                }
                            })}

                            {((superAdmin || developer) && !editBookmarkOpen) && (
                                addLinkOpen ?
                                    <Box display='flex' gap='10px' mt='5px' width='100%'>
                                        <TextField
                                            size='small'
                                            label='Title*'
                                            onChange={e => setNewTitle(e.target.value)}
                                            sx={{ flex: '1' }} />

                                        <TextField
                                            size='small'
                                            label='Link*'
                                            onChange={e => setNewLink(e.target.value)}
                                            sx={{ flex: '1' }} />

                                        <Box display='flex' alignItems='center'>
                                            <DoneIcon onClick={() => {
                                                addLink();
                                            }} sx={{ height: '20px', color: 'gray', cursor: 'pointer' }} />
                                            <CloseIcon onClick={() => {
                                                setAddLinkOpen(false);
                                                setNewLink(undefined);
                                                setNewTitle(undefined);
                                            }} sx={{ height: '20px', color: 'gray', cursor: 'pointer' }} />
                                        </Box>
                                    </Box>
                                    :
                                    <Box onClick={() => setAddLinkOpen(true)} display='flex' alignItems='center' width='90px' gap='5px' color='gray' sx={{ cursor: 'pointer' }}>
                                        <Box sx={{ fontSize: '30px', mt: '-5px' }}>+</Box>
                                        <Box>Add Link</Box>
                                    </Box>
                            )}
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={1} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <FormControlLabel control={<Checkbox size='small' checked={cicdEnabled} onChange={e => setCicdEnabled(e.target.checked)} />} label='Enable CI/CD' />
                            {cicdEnabled &&
                                <Box display='flex' flexDirection='column' gap='10px'>
                                    <TextField
                                        size='small'
                                        label='Repo Link*'
                                        defaultValue={repoName}
                                        onChange={e => setRepoName(e.target.value)}
                                        sx={{ flex: '1' }} />

                                    <FormControl sx={{ flex: '1' }} size='small'>
                                        <InputLabel>Sandbox Branch*</InputLabel>
                                        <Select
                                            value={sandboxBranch}
                                            label='Sandbox Branch*'
                                            onChange={e => setSandboxBranch(e.target.value)}
                                        >
                                            {branches?.map(branch => {
                                                return (
                                                    <MenuItem value={branch?.name} key={branch?.name}>{branch?.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ flex: '1' }} size='small'>
                                        <InputLabel>UAT Branch*</InputLabel>
                                        <Select
                                            value={uatBranch}
                                            label='UAT Branch*'
                                            onChange={e => setUatBranch(e.target.value)}
                                        >
                                            {branches?.map(branch => {
                                                return (
                                                    <MenuItem value={branch?.name} key={branch?.name}>{branch?.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ flex: '1' }} size='small'>
                                        <InputLabel>Production Branch*</InputLabel>
                                        <Select
                                            value={productionBranch}
                                            label='Production Branch*'
                                            onChange={e => setProductionBranch(e.target.value)}
                                        >
                                            {branches?.map(branch => {
                                                return (
                                                    <MenuItem value={branch?.name} key={branch?.name}>{branch?.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                            <Button onClick={() => handleCicdChange()} variant="contained" color='info' size='small' sx={{ width: '90px', mx: 'auto', mt: '10px', textTransform: 'none' }}>Save</Button>
                        </Box>
                    </CustomTabPanel>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Bookmarks
