import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { CustomTabPanel, a11yProps } from "./Report";
import DailyAttendanceReport from "./DailyAttendanceReport";
import MonthlyAttendanceReport from "./MonthlyAttendanceReport";

const AttendanceReport = () => {

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Daily" {...a11yProps(0)} />
                    <Tab label="Monthly" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={tabValue} index={0}>
                <DailyAttendanceReport user={undefined} />
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={1}>
                <MonthlyAttendanceReport user={undefined} />
            </CustomTabPanel>
        </>
    )
}

export default AttendanceReport
