import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WebSocketState {
  socketId: string | null;
  isConnected: boolean;
  error: string | null;
}

const initialState: WebSocketState = {
  socketId: null,
  isConnected: false,
  error: null,
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setSocketId(state, action: PayloadAction<string | null>) {
      state.socketId = action.payload;
    },
    setConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const {
  setSocketId,
  setConnected,
  setError,
} = websocketSlice.actions;

export default websocketSlice.reducer;
