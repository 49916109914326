// eslint-disable-next-line
// @ts-nocheck

/**
 * Generic function that accepts any number of parameters.
 */
type GenericFunction = (...args: any[]) => any;
type GenericAsyncFunction = (...args: any[]) => Promise<any>;

/**
 * Can be used to wrap a function within a function with the
 * same signature.
 *
 * @param F - Function that should be wrapped.
 */
type TryCatchWrapper<F extends GenericFunction> = (
    ...args: Parameters<F>
) => ReturnType<F>;
type TryCatchAsyncWrapper<F extends GenericAsyncFunction> = (
    ...args: Parameters<F>
) => Promise<Awaited<ReturnType<F>>>;

/**
 * Wraps a function within a try/catch block to catch any
 * unhandled error.
 *
 * @param func - Function that should be wrapped.
 */

export function tryCatchWrapper<F extends GenericFunction>(
    scope: object,
    func: F,
    handleError?: (e: Error) => void,
): TryCatchWrapper<F> {
    return (...args) => {
        try {
            console.log('Calling', { args });
            const returnValue = func.apply(scope, [...args]);
            console.log({ returnValue });

            return returnValue;
        } catch (error) {
            console.log('Caught');

            if (handleError) {
                console.log('Handling');
                handleError(error);
            } else {
                console.log('Handling Default');
                console.error({ error });
            }
            // Do whatever you want.
        }
    };
}

/**
 * Wraps a function within a try/catch block to catch any
 * unhandled error.
 *
 * @param func - Function that should be wrapped.
 */
export function tryCatchWrapperAsync<F extends GenericAsyncFunction>(
    scope: object,
    func: F,
    handleError?: (e: Error) => void,
): TryCatchAsyncWrapper<F> {
    return async (...args: Parameters<F>): Promise<Awaited<ReturnType<F>>> => {
        try {
            const returnValue = await func.apply(scope, [...args]);
            return returnValue;
        } catch (error) {
            if (handleError) {
                handleError(error);
            } else {
                console.error(error?.message || 'Some error Occurred');
            }
            // Do whatever you want.
            return;
        }
    };
}
